import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import { RoleAccessKeys } from '../../../../_reactivtrak/src/common/enums';
import {
    createDashboard,
    getCoachingLastRefreshInfo,
    getLastUpdateTimestamp,
    shouldAutoShowImpactEventsModal,
    getImpactEvents,
    getCoreCategories
} from '../../../../_app/insights/insightsServiceFunctions';
import menuItems from '../../../../_app/navigation/navigation-files/availableMenus.json';
import insightsSubscriptionModalTemplate from '../../../../app/views/reports/insights/insightsSubscriptionsModal.html?raw';
import { getInsightsSettingsStore } from '../../../../_reactivtrak/src/common/stores/insightsSettingsStore/insightsSettingsStore';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';

angular.module('app').controller('insightsReportCtrl', InsightsReportCtrl);

InsightsReportCtrl.$inject = [
    '$scope',
    '$state',
    '$sce',
    'notificationService',
    'authorizationService',
    'envConfig',
    'localStorageService',
    '$rootScope',
    'atHelperFunctions',
    'envService',
    'customUibModal',
    'messagesService',
    'routesService'
];

function InsightsReportCtrl(
    $scope,
    $state,
    $sce,
    notificationService,
    authorizationService,
    envConfig,
    localStorageService,
    $rootScope,
    atHelperFunctions,
    envService,
    customUibModal,
    messagesService,
    routesService
) {
    const insightsAvailable = authorizationService.hasFeature(FeatureFlag.InsightsAvailable);

    // Set options needed to show onboarding messaging
    const { onboardingInProgress } = getInsightsSettingsStore();
    $scope.showDownloadAgent =
        authorizationService.hasFeature(FeatureFlag.InsightsEnabled) &&
        authorizationService.hasRouteByName('app.agentactivation');
    $scope.dataHasSynced = !onboardingInProgress && !$scope.showDownloadAgent;

    $scope.setAppFullHeightHere();

    function getStateName() {
        const routes = Object.values(RoleAccessKeys)
            .filter(function (role) {
                return /Insights-.+/.test(role);
            })
            .map(function (roleAccessKey) {
                return Object.values(routesService.getRoutes()).find(function (route) {
                    return route.access?.roleAccessKey === roleAccessKey;
                });
            });
        const stateName = routes.find(function (route) {
            return authorizationService.hasRouteByName(route.name);
        })?.name;

        // Edge case if all insight is disabled for landing page.
        return stateName || 'app.help.latestUpdates';
    }

    function getCurrentStateParams(stateName) {
        const currentItem = Object.values(menuItems).filter(function (item) {
            if (item.action.options) {
                return item.action.options.ref === stateName;
            }
        })[0];

        return currentItem;
    }
    // Redirecting old insights path to newer individual routes.
    if ($state.current.name === 'app.reports.insights' && !$state.params.pageId) {
        // Set default state for app.report.insights if no pageId
        const stateName = getStateName();
        const currentStateParams = getCurrentStateParams(stateName);

        if (currentStateParams.action.options.modelId && currentStateParams.action.options.pageId) {
            $state.params.modelId = currentStateParams.action.options.modelId;
            $state.params.pageId = currentStateParams.action.options.pageId;
        }

        console.warn(
            'ActivTrak Warn: Redirecting to ' +
                stateName +
                ' from app.reports.insights due to missing pageId and old path'
        );
        $state.go(stateName, $state.params);
    } else if ($state.current.name === 'app.reports.insights' && $state.params.pageId) {
        const stateName =
            'app.reports.insights' +
            atHelperFunctions
                .capitalizeFirstLetterEachWord($state.params.pageId.replaceAll('_', ' '))
                .replaceAll(' ', '');

        console.warn('ActivTrak Warn: Redirecting to ' + stateName + ' from app.reports.insights due to old path');
        $state.go(stateName, $state.params);
    }

    if ($state.params.pageId === 'impact_analysis' && $state.params.subPageId === 'events') {
        $scope.isImpactEventsPage = true;
        return;
    }

    if ($state.params.pageId === 'activity_alignment' && $state.params.subPageId === 'configuration') {
        $scope.isActivityAlignmentConfigurationPage = true;
        return;
    }

    const { overLimitOrOverStorage, username } = getAccountSettings();
    $scope.overLimitOrOverStorage = overLimitOrOverStorage;

    let errorSubscription, embedUrlSubscription, navigationSubscription, loadedSubscription, runCompleteSubscription;

    function destroy() {
        errorSubscription.unsubscribe();
        embedUrlSubscription.unsubscribe();
        navigationSubscription.unsubscribe();
        loadedSubscription.unsubscribe();
        runCompleteSubscription.unsubscribe();
        $scope.dashboard.destroy();
        $scope.dashboard = null;
    }

    function initialize() {
        if ($scope.dashboard) {
            destroy();
        }

        if ($state.params.pageId === 'activity_alignment') {
            getCoreCategories(envConfig.apiUrl())
                .then(function (data) {
                    $scope.coreCategories = data;
                    //If no configuration(s) exists, send to configuration
                    if (!$scope.coreCategories || $scope.coreCategories.length === 0) {
                        $state.go('app.reports.insightsActivityAlignment', {
                            pageId: 'activity_alignment',
                            subPageId: 'configuration'
                        });
                    }
                })
                .catch((e) => {
                    // Non-starter: if user has no groups configured, display message and direct user to create groups
                    if (
                        e.response.data &&
                        e.response.data.error &&
                        e.response.data.error.includes('No groups found for')
                    ) {
                        $scope.noGroupsFoundForUser = true;
                        $scope.noGroupsFoundForUserMessage = e.response.data.error;
                    } else {
                        notificationService.showNotification('Error fetching core categories', 'danger');
                        console.log('Error fetching core categories [ang]', e);
                    }
                })
                .finally(() => {
                    $scope.hideLoading = true;
                });
        }

        $scope.dashboard = createDashboard(envConfig.apiUrl(), {
            modelId: $state.params.modelId,
            pageId: $state.params.pageId,
            subPageId: $state.params.subPageId,
            range: $state.params.insightsRange,
            username: username,
            useTempFilters: $state.params.tempFilters,
            embedDomain: window.location.origin,
            services: {
                notificationService,
                localStorageService,
                authorizationService
            },
            isProduction: envService.get() === 'prod',
            lookerUrl: envService.lookerUrl(),
            messagesService: messagesService
        });

        $scope.schedulingEnabled = authorizationService.hasFeature(FeatureFlag.InsightsScheduling);

        if (insightsAvailable) {
            if ($state.params.pageId === 'coaching_opportunities') {
                getCoachingLastRefreshInfo(envConfig.apiUrl()).then(function (result) {
                    $scope.timeinfo = result;
                    atHelperFunctions.safeApply($rootScope);
                });
            } else {
                getLastUpdateTimestamp(envConfig.apiUrl(), notificationService).then(function (result) {
                    $scope.timeinfo = result;
                    atHelperFunctions.safeApply($rootScope);
                });
            }
        }

        //dynamic title
        $state.current.data.pageTitle = 'Insights - ' + $scope.dashboard.name;

        errorSubscription = $scope.dashboard.error.subscribe(function (error) {
            if (error) {
                const ids = $scope.dashboard.createStateParams($scope.dashboard.id);
                const menuItem = $rootScope.sidebar.getMenuItem('app.reports.insights', ids);
                let params = $rootScope.sidebar.fallbackDashboardParams();
                const insightsEnabled = authorizationService.hasFeature(FeatureFlag.InsightsEnabled);

                if (menuItem && insightsEnabled) {
                    const subNavigation = menuItem.getSubNavigation();
                    if (subNavigation) {
                        params = {
                            modelId: ids.modelId,
                            pageId: ids.pageId,
                            subPageId: subNavigation ? subNavigation[0].subPageId : null
                        };
                    }
                }

                $state.go('app.reports.insights', params);
            }
        });

        embedUrlSubscription = $scope.dashboard.embedUrl.subscribe(function (value) {
            if (value) {
                const pathArray = value.split('/');
                const url = pathArray[0] + '//' + pathArray[2];
                $scope.dashboard.lookerUrl = url;
                $scope.embeddedUrl = $sce.trustAsResourceUrl(value);
                atHelperFunctions.safeApply($rootScope);
            }
        });

        navigationSubscription = $scope.dashboard.nextId.subscribe(function (value) {
            if ($scope.dashboard.shouldNavigate()) {
                const nextStateParams = $scope.dashboard.createStateParams(value);
                Object.assign(nextStateParams, { tempFilters: true });
                $state.go('app.reports.insights', nextStateParams);
            }
        });

        loadedSubscription = $scope.dashboard.loaded.subscribe(function () {
            atHelperFunctions.safeApply($rootScope);
        });

        runCompleteSubscription = $scope.dashboard.pageChange.subscribe(function (value) {
            if (value) {
                $scope.dashboard.grantSchedulerRole(envConfig.apiUrl());
            }
        });
    }

    const menuSubscription = $rootScope.sidebar.menu.subscribe(function (menuItems) {
        if (menuItems) {
            // Redirect to insights home if no model id or page id
            if (!$state.params.modelId || !$state.params.pageId) {
                const fallbackRoute = $state.current.name || 'app.reports.insights';
                $state.go(fallbackRoute, $rootScope.sidebar.fallbackDashboardParams());
            } else {
                initialize();
            }
        }
    });

    $scope.openSubscriptions = function () {
        if ($scope.dashboard.pageId === 'personal_insights') {
            if (!$scope.dashboard.embeddedFilters['User']) {
                return;
            }
            const name = $scope.dashboard.pageId.replaceAll('_', ' ');
            $scope.dashboard.name =
                atHelperFunctions.capitalizeFirstLetterEachWord(name) +
                ' - ' +
                $scope.dashboard.embeddedFilters['User'];
        }
        // eslint-disable-next-line
        const modal = customUibModal.open({
            animation: false,
            template: insightsSubscriptionModalTemplate,
            controller: 'insightsSubscriptionModal',
            windowClass: 'centered-modal at-insights-subscription-modal ',
            resolve: {
                modalData: {
                    dashboardID: $scope.dashboard.id,
                    dashboard: $scope.dashboard
                }
            }
        });
    };

    if ($state.params.pageId === 'impact_analysis') {
        handleImpactAnalysis();
    }

    $scope.$on('$destroy', function () {
        menuSubscription.unsubscribe();
        if ($scope.dashboard) {
            destroy();
        }
    });

    /*
     * pageId 'impact_analysis' has 4 sub pages: 'summary', 'trends', 'technology_usage', and 'events'.
     * subPageId's 'summary', 'trends', and 'technology_usage', like all other insights pages, contain looker content.
     * subPageId 'events' is the exception and contains our custom, non looker content.
     *
     * Upon clicking on the 'Impact' left nav, the default landing is the 'summary' subPageId.
     * If the user is an admin or configurator, and the account does not have any non default events,
     * we send the user to the 'events' subPageId and auto show an events creation modal once per
     * browser session until the account has a user created event.
     * Each account is preloaded with 2 default events.
     */
    function handleImpactAnalysis() {
        if ($state.params.subPageId === 'events') {
            $scope.isImpactEventsPage = true;
            return;
        }

        if (
            insightsAvailable &&
            $state.params.subPageId === 'summary' &&
            shouldAutoShowImpactEventsModal(authorizationService, username, localStorageService)
        ) {
            getImpactEvents(envConfig.apiUrl(), notificationService).then(function (data) {
                $scope.impactEvents = data;
            });

            const waitForImpactEvents = function () {
                if ($scope.impactEvents === undefined) {
                    setTimeout(waitForImpactEvents);
                }

                if ($scope.impactEvents && $scope.impactEvents.length === 2) {
                    $state.go('app.impact', {
                        pageId: 'impact_analysis',
                        subPageId: 'events'
                    });
                }
            };
            waitForImpactEvents();
        }
    }
}

import { Role, RoleAccessKeys } from '../../../../../enums';
import { BundleFlag } from '../../../../../enums/BundleFlag';
import { IRoute } from '../../../../../models/IRoute';
import { refreshInsightsSettings } from '../../../../../stores/insightsSettingsStore/insightsSettingsStore';

export const appReportsTopusers: IRoute = {
  name: 'app.reports.topusers',
  stateDefinition: {
    url: '/topusers?groupBy&groupId&name',
    component: 'topUsersReport',
    resolve: {
      loadInsightsSettingsStore: [async () => await refreshInsightsSettings()]
    },
    data: {
      pageTitle: 'Top Users and Groups',
      filter: { export: true },
      exportText: 'export',
      reportName: 'topUsersReportName'
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    roleAccessKey: RoleAccessKeys.TopUsers,
    edit: [Role.Admin, Role.Configurator, Role.SuperAdmin],
    read: [Role.User, Role.Viewer],
    bundleFlags: [BundleFlag.TopUsersReport]
  }
};

export default appReportsTopusers;

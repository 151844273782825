'use strict';

angular.module('app').directive('nowhitespace', function () {
    return {
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, elem, attrs, ngModel) {
            if (!ngModel) {
                return;
            }

            // re-validate on ngModel change
            scope.$watch(attrs.ngModel, function () {
                validate();
            });
            const unicodeWhitespaceRegex = /\s/;
            var validate = function () {
                var password = ngModel.$viewValue;

                ngModel.$setValidity(
                    'nowhitespace',
                        password &&
                        !unicodeWhitespaceRegex.test(password)
                );
            };
        }
    };
});

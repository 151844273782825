import React, { useEffect, useState } from 'react';
import { useSubscription } from 'observable-hooks';
import { Routes } from '../../../common/constants';
import { useNotifications } from '../../../common/services/Notifications';
import { useAuthorization } from '../../../common/services/Authorization';
import { ICategory, IClassificationActivity } from '../../../common/models';
import CustomGrid from '../../../common/components/CustomGrid/CustomGrid';
import CompletedClassifications from '../../../classifications/components/CompletedClassifications';
import {
  useClassificationDashboardColumns,
  useClassificationViewUtils
} from '../../../classifications/utils';
import { Role, SortDirection } from '../../../common/enums';
import {
  useClassification,
  useClassificationStores
} from '../../../classifications/services';
import Box from '@mui/material/Box';

type ClassificationsTableProps = {
  tableHeight?: string;
};

//NOTE: this widget is loaded as a separate single-spa app in Angular so it does not have access to any activities or categories loaded by Classifications Macro/Micro pages
export default function ClassificationsTable(props: ClassificationsTableProps) {
  const tableHeight = props?.tableHeight;
  const [categories, setCategories] = useState<ICategory[]>();

  const { categoryService } = useClassification();
  const { classificationStore } = useClassificationStores();
  const { getRecentActivities } = classificationStore;

  const { activitiesState, resetActivitiesError } = classificationStore;
  const { activities, isLoading, error: activityError } = activitiesState;

  const { handleUpdateActivityChange, handleSortOrder } =
    useClassificationViewUtils();

  const authorizationService = useAuthorization();
  const isDisabled = authorizationService.hasAnyRole([Role.SupportAdvanced]);

  const columns = useClassificationDashboardColumns({
    categories,
    onChange: handleUpdateActivityChange,
    isTruncated: true,
    isDisabled: isDisabled
  });

  const notificationService = useNotifications();

  useSubscription(
    categoryService.getAll(),
    setCategories,
    notificationService.error
  );

  useEffect(() => {
    if (!categories || categories.length === 0) {
      return;
    }
    getRecentActivities(categories, { classified: false, limit: 20 });
  }, [getRecentActivities, categories]);

  useEffect(() => {
    if (activityError) {
      notificationService.error(activityError);
      resetActivitiesError();
    }
  }, [activityError, resetActivitiesError, notificationService]);


  const isCompletelyClassified = !isLoading && !activities?.length;

  const handleClickRowGrid = (activitySelected: IClassificationActivity) => {
    location.href =
      // baseUrl +
      // TODO: Review this: baseUrl was set to /#/app
      // const envConfig: IEnvConfig = {
      //   environmentService: envService,
      //   baseUrl: '/#/app'
      // };
      '/#/app' +
      Routes.Classification(activitySelected?.type, activitySelected?.id);
  };

  return isCompletelyClassified ? (
    <Box sx={{ paddingTop: '25px' }}>
      <CompletedClassifications />
    </Box>
  ) : (
    <CustomGrid
      height={tableHeight || '254px'}
      isVirtualized={true}
      isLoading={isLoading}
      data={activities}
      columns={columns}
      uniqueRowProp="name"
      initialSortField="duration"
      initialSortDirection={SortDirection.Descending}
      onClickRow={handleClickRowGrid}
      onSortOrder={handleSortOrder}
    />
  );
}

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ConfigurationCardWrapperWithLink } from './ConfigurationCardWrapper';
import { DefaultWidgetProps, GTEUserAction } from '../models';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import { AnchorTags, smallFontSize } from '../../common/constants';
import { GTEUsersDetails } from '../constants';
import { useGTEUsersState } from '../hooks/';
import { useNotifications } from '../../common/services/Notifications';
import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
import { useGTEUsersColumns } from '../utils/useGTEUsersColumns';
import { scrollToElement } from '../../common/utils/window.utils';

export const GTEUsersCard = ({ isSupport = false }: DefaultWidgetProps) => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const {
    init,
    gteUsers,
    gteUserNotification,
    setGTEUserNotification,
    selectedUsername,
    setSelectedUsername,
    isAcknowledged,
    setIsAcknowledged,
    deleteGTEUser,
    addGTEUser,
    resetFields,
    isLoading
  } = useGTEUsersState();
  const notificationService = useNotifications();
  const anchorRedirectRef = useRef(null);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    if (gteUserNotification?.msg && gteUserNotification?.type) {
      notificationService[gteUserNotification.type](gteUserNotification.msg);
      setGTEUserNotification(undefined);
    }
  }, [
    init,
    notificationService,
    gteUserNotification?.msg,
    gteUserNotification?.type,
    setGTEUserNotification
  ]);

  useEffect(() => {
    if (
      window.location.hash.includes(AnchorTags.GTEUsers) &&
      anchorRedirectRef.current
    ) {
      scrollToElement(anchorRedirectRef, 'smooth');
    }
  }, []);

  const handleClose = useCallback((event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
    event.stopPropagation();
  }, []);

  const handleCancel = useCallback(
    (dispatchDialogAction: (setOpenDialog: boolean) => void) => {
      resetFields();
      dispatchDialogAction(false);
    },
    [resetFields]
  );

  const handleOpen = (e, actionGTEUser: GTEUserAction) => {
    switch (actionGTEUser) {
      case GTEUserAction.Delete:
        setOpenDeleteDialog(true);
        break;
      case GTEUserAction.Create:
        setOpenCreateDialog(true);
        break;
    }
    e.stopPropagation();
  };

  const handleOpenSetUsername = (e, username, actionGTEUser: GTEUserAction) => {
    setSelectedUsername(username);
    handleOpen(e, actionGTEUser);
  };

  const handleSubmit = async (e, actionGTEUser: GTEUserAction) => {
    switch (actionGTEUser) {
      case GTEUserAction.Delete:
        await deleteGTEUser({
          username: selectedUsername,
          logondomain: null
        });
        setOpenDeleteDialog(false);
        break;
      case GTEUserAction.Create:
        await addGTEUser({ username: selectedUsername, logondomain: null });
        setOpenCreateDialog(false);
        break;
    }
    resetFields();
    init();
    e.stopPropagation();
  };

  const columns = useGTEUsersColumns({ handleOpenSetUsername, isSupport });

  return (
    <>
      {/* Create gte user dialog */}
      <Dialog open={openCreateDialog} onClose={handleClose} maxWidth="sm">
        <DialogTitle>{GTEUsersDetails.create.title}</DialogTitle>
        <DialogContent>
          <>
            <Typography>{GTEUsersDetails.create.description}</Typography>

            <Typography
              sx={{ mt: 2, fontSize: smallFontSize, fontStyle: 'italic' }}
            >
              {GTEUsersDetails.create.descriptionSecondary}
            </Typography>

            <TextField
              label="Global Tracking Exclusions username"
              variant="outlined"
              sx={{ mt: 2 }}
              value={selectedUsername}
              onChange={(e) => setSelectedUsername(e.target.value)}
              fullWidth
            />

            <Grid
              container
              alignItems="center"
              sx={{ mt: 1, position: 'relative', left: '-10px' }}
            >
              <Grid item>
                <Checkbox
                  checked={isAcknowledged}
                  onChange={(e) => setIsAcknowledged(e.target.checked)}
                  sx={{ paddingRight: 0 }}
                />
              </Grid>
              <Grid item>
                <Typography sx={{ paddingLeft: 1 }}>
                  {GTEUsersDetails.create.acknowledgment}
                </Typography>
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancel(setOpenCreateDialog)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => handleSubmit(e, GTEUserAction.Create)}
            disabled={!isAcknowledged || !selectedUsername}
          >
            {GTEUsersDetails.create.button}
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete gte user dialog */}
      {selectedUsername && (
        <Dialog
          open={openDeleteDialog}
          onClose={handleClose}
          sx={{ '& .MuiDialog-paper': { maxWidth: '460px' } }}
        >
          <DialogTitle>{GTEUsersDetails.delete.title}</DialogTitle>
          <DialogContent>
            <Typography>{GTEUsersDetails.delete.description}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleCancel(setOpenDeleteDialog)}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleSubmit(e, GTEUserAction.Delete)}
              disabled={false}
            >
              {GTEUsersDetails.delete.button}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {/* GTE user card and list */}
      {!gteUsers && (
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      )}
      {gteUsers && (
        <ConfigurationCardWrapperWithLink
          title={<span>{GTEUsersDetails.card.title}</span>}
          widgetLinkProps={{
            text: 'Users',
            redirectTo: 'app.settings.identity',
            redirectParams: { limit: 30 },
            id: '2eb01a71-4bf4-4b5d-8edb-1ab32118f6d6',
            infoIconText: GTEUsersDetails.card.infoIconText
          }}
        >
          <Box
            sx={{ mb: 2 }}
            dangerouslySetInnerHTML={{
              __html: GTEUsersDetails.card.description
            }}
          ></Box>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleOpen(e, GTEUserAction.Create)}
              disabled={isSupport}
            >
              {GTEUsersDetails.create.button}
            </Button>
          </Box>
          <Box
            ref={anchorRedirectRef}
            id={AnchorTags.GTEUsers.replace('#', '')}
          ></Box>
          <CustomGrid
            height={'65vh'}
            isLoading={isLoading}
            data={gteUsers}
            columns={columns}
          />
        </ConfigurationCardWrapperWithLink>
      )}
    </>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DownloadAgentButton } from '../../common/components/DownloadAgentButton';
import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
import { PermissionLevel } from '../../common/enums';
import { useNotifications } from '../../common/services/Notifications';
import UserIdentitySearch from '../components/UserIdentitySearch';
import Grid from '@mui/material/Grid';
import { useUserColumns } from '../utils/useUserColumns';

import { useUIRouterHistory } from '../../common/hooks/useUIRouterHistory';

import { ReactivTrakComponentWrapper } from '../../ReactivTrakComponentWrapper';
import { DialogOrphanedAgent } from '../components/DialogOrphanedAgent';
import { EditOrInsertUserModal } from '../components/EditOrInsertUserModal';
import { GlobalUserFilters } from '../components/GlobalUserFilters';
import { DownloadProvider } from '../../common/services';
import MergeTypeIcon from '@mui/icons-material/Merge';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useAuthorization } from '../../common/services/Authorization';
import { IUserIdentity } from '../models/IUserIdentity';
import { DialogVerifyDeleteUser } from '../components/DialogVerifyDeleteUser';
import {
  getDateFormat,
  getDateTimeFormat
} from '../../common/utils/datetime/datetimeFormatStrings';
import { Routes } from '../../common/constants';
import { useIdentityCommonState } from '../hooks/useIdentityCommonState';
import { useUserSearchState } from '../hooks/useUserSearchState';
import { useAgentSearchState } from '../hooks/useAgentSearchState';
import { GridPaging } from '../components/GridPaging';
import SnackbarWithUrl from '../components/SnackbarUrl';
import { useDntDeleteState } from '../hooks/useDntDeleteState';
import { DoNotTrackModal } from '../components/DoNotTrackModal';
import {
  deleteUserData,
  startTracking,
  stopTracking
} from '../constants/ButtonLabels';
import { TrackModal } from '../components/TrackModal';
import { DeleteUser } from '../components/DeleteUser';
import { BaseNoResults } from '../../common/components/NoResults';
import { HeaderBarRightActionButtons } from '../../alias/styles/Users.view.styles';
import { identityHeaderRight } from '../styles/UserList.view.styles';

export function UserIdentities() {
  const history = useUIRouterHistory();
  const dateTimeFormat = getDateTimeFormat();
  const commonState = useIdentityCommonState({
    dateFormat: getDateFormat(),
    dateTimeFormat
  });
  const agentSearchState = useAgentSearchState();
  const dntDeleteState = useDntDeleteState();
  const userSearchState = useUserSearchState({ persistSearchToStore: true });

  const authorization = useAuthorization();
  const permission = authorization.pageAuthorization(Routes.Identities);
  const isReadOnly = permission === null || permission === PermissionLevel.Read;

  const {
    identityNotification,
    setIdentityNotification,
    deleteIdentityUser,
    snackBarHyperlinkText,
    snackBarMessage,
    snackBarOpen,
    snackBarUrl,
    onSnackBarClose
  } = commonState;

  const {
    isLoadingIdentities,
    identities,
    onSortOrder,
    pageSize,
    onChangePageSize,
    onChangePage,
    initializeUserIdentities,
    cursorStack,
    currentCursor,
    setIdentities,
    gridOrderBy,
    gridOrderDirection,
    gridFilter,
    onSetGridFilter
  } = userSearchState;

  const notifications = useNotifications();
  const [nextPageDisabled, setNextPageDisabled] = useState<boolean>(false);
  const [showOrphanedAgentDialog, setShowOrphanedAgentDialog] =
    useState<boolean>(false);
  const [showVerifyDeleteDialog, setShowVerifyDeleteDialog] =
    useState<boolean>(false);
  const [openCreateUserModal, setOpenCreateUserModal] =
    useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<IUserIdentity[]>(null);

  useEffect(() => {
    if (identityNotification?.msg && identityNotification?.type) {
      notifications[identityNotification.type](identityNotification.msg);
      setIdentityNotification(undefined);
    }
  }, [notifications, identityNotification, setIdentityNotification]);

  useEffect(() => {
    initializeUserIdentities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const pageSizeInt = parseInt(pageSize);
    const disableNextPage =
      cursorStack?.length == 0 || identities.length < pageSizeInt;
    setNextPageDisabled(disableNextPage);
  }, [currentCursor, cursorStack, pageSize, identities]);

  const onChangeMenuOption = (option?: any, dataItem?: any) => {
    dntDeleteState.setUserToModify(dataItem);

    switch (option?.label) {
      case stopTracking:
        dntDeleteState.setOpenDoNotTrackDeleteModal(true);
        break;
      case startTracking:
        dntDeleteState.setOpenTrackModal(true);
        break;
      case deleteUserData:
        dntDeleteState.setOpenDeleteModal(true);
        break;
      default:
        console.log('Unknown option: ', option?.label);
    }
  };

  const identityGridColumns = useUserColumns(
    dateTimeFormat,
    onChangeMenuOption,
    isReadOnly
  );

  const handleClickRowGrid = useCallback(
    (rowItem) => {
      if (rowItem.id) {
        history.push('app.settings.identity_id', { id: rowItem.id });
      } else {
        setShowOrphanedAgentDialog(true);
      }
    },
    [history]
  );

  const closeOrphanedAgentDialog = () => {
    setShowOrphanedAgentDialog(false);
  };

  const closeVerifyDeleteUserDialog = () => {
    setShowVerifyDeleteDialog(false);
  };

  const onDeleteUserConfirmed = async () => {
    const zeroAgents = selectedUsers.filter(
      (x) => x.agents && x.agents.length > 0
    );
    if (zeroAgents.length > 0) {
      setIdentityNotification({
        msg: 'Cannot delete users with agents assigned.',
        type: 'error'
      });
    } else {
      selectedUsers.forEach(async (user) => {
        await deleteIdentityUser(user.id);
      });

      //give it a moment before calling to get new identity list
      await new Promise((f) => setTimeout(f, 500));
      initializeUserIdentities();
    }
  };

  const handleIdentityGridChange = (
    oldIdentity: IUserIdentity,
    newIdentity: IUserIdentity
  ) => {
    const clonedIdentities = [...identities];
    if (oldIdentity && newIdentity) {
      const index = clonedIdentities.findIndex(
        (usr) => usr.id === oldIdentity.id
      );
      clonedIdentities[index] = newIdentity;
    }
    const selectedIdentities = clonedIdentities?.filter((usr) => usr.selected);
    setSelectedUsers(selectedIdentities);
    setIdentities(clonedIdentities);
  };

  //disable until fully tested
  const showBetaDeleteUser = false;
  const showBetaMergeUser = false;

  function handleDeleteUsersClick(): void {
    setShowVerifyDeleteDialog(true);
  }

  const userGridRowDisableCondition = useCallback((rowItem) => {
    return !rowItem.id;
  }, []);

  const openCreateUser = () => {
    agentSearchState.resetAgentsSelected();
    setOpenCreateUserModal(true);
  };

  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={6}>
            <></>
          </Grid>
          <Grid item xs={6}>
            <UserIdentitySearch
              commonState={commonState}
              userSearchState={userSearchState}
              gridFilter={gridFilter}
              onSetGridFilter={onSetGridFilter}
            />
          </Grid>
          <Grid item xs={12} sx={identityHeaderRight}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={2}
                md={4}
                lg={5}
                sx={{ display: 'inline-flex' }}
              >
                <Button
                  onClick={openCreateUser}
                  startIcon={<AccountCircleIcon />}
                  disabled={isReadOnly}
                  color="secondary"
                >
                  Add User{' '}
                </Button>
                {showBetaMergeUser && (
                  <Button
                    startIcon={<MergeTypeIcon />}
                    // onClick={handleMergeUsersClick}
                    disabled={
                      isReadOnly ||
                      selectedUsers == null ||
                      selectedUsers?.length < 2
                    }
                  >
                    Merge Users
                  </Button>
                )}
                {showBetaDeleteUser && (
                  <Button
                    startIcon={<DeleteIcon />}
                    onClick={handleDeleteUsersClick}
                    disabled={
                      isReadOnly ||
                      selectedUsers == null ||
                      selectedUsers?.length == 0
                    }
                  >
                    Delete
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} sm={10} md={8} lg={7}>
                <HeaderBarRightActionButtons>
                  <GlobalUserFilters
                    gridFilter={gridFilter}
                    onSetGridFilter={onSetGridFilter}
                  />
                  <DownloadAgentButton />
                </HeaderBarRightActionButtons>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!isLoadingIdentities && !identities?.length ? (
          <BaseNoResults />
        ) : (
          <>
            <CustomGrid
              isVirtualized={false}
              isLoading={isLoadingIdentities}
              data={identities}
              columns={identityGridColumns}
              initialSortField={gridOrderBy}
              uniqueRowProp="id"
              initialSortDirection={gridOrderDirection}
              onSelectAllClick={() => handleIdentityGridChange(null, null)}
              onClickRow={handleClickRowGrid}
              onSortOrder={onSortOrder}
              rowDisableCondition={userGridRowDisableCondition}
            />
            <Box sx={{ mt: 1 }}>
              <GridPaging
                currentCursor={currentCursor}
                cursorStack={cursorStack}
                nextPageDisabled={nextPageDisabled}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
                pageSize={pageSize}
                idPrefix="identities"
              />
            </Box>
          </>
        )}
      </Box>
      <EditOrInsertUserModal
        isOpen={openCreateUserModal}
        selectedIdentity={null}
        onClose={() => setOpenCreateUserModal(false)}
        title="Add User"
        saveButtonText="Add User"
        newUserMode={true}
        agentSearchState={agentSearchState}
        commonState={commonState}
      />
      <SnackbarWithUrl
        open={snackBarOpen}
        message={snackBarMessage}
        hyperlinkText={snackBarHyperlinkText}
        url={snackBarUrl}
        onClose={onSnackBarClose}
      />
      <DoNotTrackModal
        dntState={dntDeleteState}
        openDoNotTrackDeleteModal={dntDeleteState.openDoNotTrackDeleteModal}
        commonState={commonState}
        handleIdentityGridChange={handleIdentityGridChange}
      />
      <TrackModal
        dntState={dntDeleteState}
        openTrackModal={dntDeleteState.openTrackModal}
        commonState={commonState}
        handleIdentityGridChange={handleIdentityGridChange}
      />
      <DeleteUser
        dntState={dntDeleteState}
        commonState={commonState}
        handleIdentityGridChange={handleIdentityGridChange}
      />
      <DialogOrphanedAgent
        closeDialog={closeOrphanedAgentDialog}
        dialogOpen={showOrphanedAgentDialog}
      ></DialogOrphanedAgent>
      <DialogVerifyDeleteUser
        closeDialog={closeVerifyDeleteUserDialog}
        dialogOpen={showVerifyDeleteDialog}
        onDelete={onDeleteUserConfirmed}
        itemsToDelete={selectedUsers}
      />
    </>
  );
}

export const UserIdentitiesComponent = () => (
  <ReactivTrakComponentWrapper>
    <DownloadProvider>
      <UserIdentities />
    </DownloadProvider>
  </ReactivTrakComponentWrapper>
);

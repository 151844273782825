import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import ProductivityStatus from '../../../../_app/constants/productivityStatus';
import { ProductivityColors as productivityColors } from '../../../../_reactivtrak/src/common/constants/productivityColors';
import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import { BundleFlag } from '../../../../_reactivtrak/src/common/enums/BundleFlag';
import authorization from '../../../../_reactivtrak/src/common/helpers/authorization';
import lodash from 'lodash';
import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters.ts';
import { setExportParamsStore } from '../../../../_reactivtrak/src/common/stores/exportsStore/exportParamsStore.ts';

// Register report component
import './topusers.component.js';

angular.module('app').controller('TopUsersCtrl', TopUsersCtrl);

TopUsersCtrl.$inject = [
    '$rootScope',
    '$scope',
    '$state',
    '$timeout',
    '$document',
    '$window',
    'localStorageService',
    'messagesService',
    'topUsersService',
    'filterState',
    'pageSizeService',
    'googleChartApiPromise',
    'exportInfoService',
    'atHelperFunctions',
    'templateServiceFunctions',
    'iconResolverServiceFunctions'
];

function TopUsersCtrl(
    $rootScope,
    $scope,
    $state,
    $timeout,
    $document,
    $window,
    localStorageService,
    msg,
    topUsersService,
    filterState,
    pageSizeService,
    googleChartApiPromise,
    exportInfoService,
    atHelperFunctions,
    templateServiceFunctions,
    iconResolverServiceFunctions
) {
    const accountSettings = getAccountSettings();

    const { calendarIntegrated } = $scope.$parent;

    $scope.download = $rootScope.download;

    // Set local storage variables
    const intervalLocalStorageKey = 'timeline-interval-' + accountSettings.username;
    const isStrictLocalStorageKey = 'timeline-is-strict-' + accountSettings.username;
    const hideEmptyDaysLocalStorageKey = 'timeline-hide-empty-days' + accountSettings.username;
    const pageModeLocalStorageKey = 'top-users-page-mode' + accountSettings.username;
    const layoutModeLocalStorageKey = 'top-users-detail-mode' + accountSettings.username;

    const showOfflineMeetingData = authorization.hasFeature(FeatureFlag.ShowOfflineMeetingData);
    const topUsersReportOfflineMeetingsData = authorization.hasFeature(BundleFlag.TopUsersReportOfflineMeetingsData);
    const showLocationData =
        authorization.hasFeature(FeatureFlag.ShowLocationData) && authorization.hasFeature(BundleFlag.LocationData);

    localStorageService.bind($scope, 'interval', '5', intervalLocalStorageKey);
    localStorageService.bind($scope, 'isStrict', false, isStrictLocalStorageKey);
    localStorageService.bind($scope, 'hideEmptyDays', false, hideEmptyDaysLocalStorageKey);
    localStorageService.bind($scope, 'pageMode', 'User', pageModeLocalStorageKey);
    localStorageService.bind($scope, 'layoutMode', 'Summary', layoutModeLocalStorageKey);

    // Declare variables
    let groupDetailTotalActivity = 0;
    let maxUserTotal = 0;
    let initialLoad = true;
    const dateFormat = accountSettings.dateFormat.toUpperCase();

    // Report Filters
    $scope.reportFilters = {};

    // Set title bar
    $scope.title = msg.get('user');

    // Set variables for timeline chart
    $scope.chartTimeFormat = accountSettings.timeFormat.replace(':ss', '').replace('tt', 'a');
    $scope.tooltipTimeFormat = $scope.chartTimeFormat.replace('hh', 'h').replace(' ', '');
    $scope.tooltipOptions = templateServiceFunctions.getTooltipOptions({
        filter: 'td.grid-tooltip',
        position: 'right'
    });
    $scope.reloadTrigger = 0;

    // Set loading bool
    $scope.isLoading = true;
    $scope.isDetailLoading = true;
    $scope.timelineReady = true;

    $scope.usedLicenses = accountSettings.usedLicenses;
    $scope.colors = {
        1: productivityColors[1],
        '-1': productivityColors['-1'],
        0: productivityColors[0],
        '-3': productivityColors['-3'],
        2: productivityColors[2],
        '-2': productivityColors['-2'],
        9: productivityColors[9]
    };

    $scope.createOfflineMeetingTooltipTemplate = function () {
        return templateServiceFunctions.createOfflineMeetingTooltipTemplate(calendarIntegrated);
    };

    // Set header height used to calculate grid heights
    function headerHeight() {
        return $window.innerHeight - 300;
    }

    $scope.friendlyViewTimeFormat = templateServiceFunctions.friendlyViewTimeFormat;

    // Grid height calculation functions
    $scope.mainGridHeight = function () {
        return headerHeight();
    };

    $scope.groupDetailsGridHeight = function () {
        return headerHeight() + 34;
    };

    // Get label for current filter mode
    function getModeLabel(singular) {
        return ($scope.user?.filterMode === 'computers' ? 'Computer' : 'User') + (singular ? '' : 's');
    }

    //  Update group by and set title/breadcrumbs
    function updateGroupBy(groupBy) {
        //TODO: move this to route handler
        if ($scope.groupBy !== groupBy) {
            // Update title and breadcrumbs
            if (groupBy === 'computers') {
                $scope.title = msg.get('computer');
            } else if (groupBy === 'groups') {
                $scope.title = msg.get('group');
            } else {
                $scope.title = msg.get('user');
            }
            atHelperFunctions.updateTitle($rootScope, $state);

            angular
                .element('.top-users-grid th[data-field=user] .k-link')
                .html('<div style="font-weight: 500; color: #404242;">' + $scope.title + '</div>');
            angular
                .element('.top-groups-details-grid th[data-field=user] .k-link')
                .html('<div style="font-weight: 500; color: #404242;">' + getModeLabel(true) + '</div>');

            $scope.selectedRow = null;
            $scope.groupBy = groupBy;
            $scope.nextGroupBy = null;

            filterState.setGroupBy($scope.groupBy); // Only used to set the groupBy in the export.js

            if ($scope.reloadUsers) {
                $scope.reloadUsers = false;
            }
        }

        pageSizeService.dataSourceReload(topUsersDataSource);
    }

    // Define Top Users Datasource
    const topUsersDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                if (accountSettings.overLimitOrOverStorage) {
                    options.success([]);
                    $scope.noResults = true;
                    $scope.isLoading = false;
                    $scope.timelineReady = true;
                    return;
                }

                $scope.isLoading = true;
                $scope.noResults = false;

                const params = generateParameters($scope.reportFilters, {});
                topUsersService
                    .getUsers(params, $scope.groupBy)
                    .success(function (result) {
                        $scope.noResults = !(result && result.rows && result.rows.length > 0);

                        if ($scope.noResults) {
                            $scope.timelineReady = true;
                        }

                        // get largest duration from users in result set
                        maxUserTotal = Math.max(...result.rows.map((o) => o.total));
                        result.rows = lodash.orderBy(result.rows, ['total', 'user'], ['desc', 'asc']) || [];
                        const data = {
                            data: result.rows,
                            total: result.rows.length
                        };
                        options.success(data);
                    })
                    .error(function (result) {
                        options.error(result);
                        $scope.isLoading = false;
                        if (result && result.message) {
                            $scope.$emit('showNotification', {
                                message: result.message,
                                color: 'danger'
                            });
                        }
                    });
            }
        },
        pageSize: 25,
        serverPaging: false,
        serverSorting: false,
        serverFiltering: false,
        schema: {
            data: 'data',
            total: 'total'
        }
    });
    $scope.topUsersDataSource = topUsersDataSource;

    // Define Group Details Datasource
    const groupDetailsDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                let params = generateParameters($scope.reportFilters, {});
                let nextParamIndex;
                let subString;
                const selectedUserId = $scope.selectedRow?.userId;

                if (accountSettings.overLimitOrOverStorage) {
                    options.success([]);
                    $scope.noResults = true;
                    return;
                }

                // Replace global filter UserId with selected UserId
                const userIdIndex = params.indexOf('&userId=');
                if (userIdIndex > -1 && $scope.selectedRow) {
                    nextParamIndex = params.indexOf('&', userIdIndex + 1);
                    subString = params.slice(0, userIdIndex) + params.slice(nextParamIndex, params.length);
                    params = subString + '&userId=' + selectedUserId;
                }

                // Replace global filter userType with group
                const userTypeIndex = params.indexOf('&userType=');
                if (userTypeIndex > -1) {
                    nextParamIndex = params.indexOf('&', userIdIndex + 1);
                    subString = params.slice(0, userIdIndex) + params.slice(nextParamIndex, params.length);
                    params = subString + '&userType=Group';
                }

                // Request Group Top User's Data
                topUsersService
                    .getUsers(params, $scope.groupBy)
                    .success(function (result) {
                        if (selectedUserId !== $scope.selectedRow?.userId) {
                            if (detailLoadCount > 5) {
                                $scope.$emit('showNotification', {
                                    message: msg.get('reportLoadingError'),
                                    color: 'danger'
                                });
                                return;
                            }
                            detailLoadCount++;
                            loadGroupDetails($scope.selectedRow);
                            options.error({ data: [], total: 0 });
                            return;
                        }

                        $scope.noResults = !(result && result.rows && result.rows.length > 0);

                        // get largest duration from groups in result set
                        groupDetailTotalActivity = Math.max(...result.rows.map((o) => o.total));
                        result.rows = lodash.orderBy(result.rows, ['total', 'user'], ['desc', 'asc']);
                        options.success(result.rows || []);

                        detailLoadCount = 0;
                        $scope.isDetailLoading = false;
                    })
                    .error(function () {
                        options.error();

                        detailLoadCount = 0;
                        $scope.isDetailLoading = false;
                        $scope.$emit('showNotification', {
                            message: msg.get('reportLoadingError'),
                            color: 'danger'
                        });
                    });
            }
        }
    });
    $scope.groupDetailsDataSource = groupDetailsDataSource;

    // Define Timeline Datasource
    $scope.timelineDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                if (accountSettings.overLimitOrOverStorage) {
                    options.success([]);
                    $scope.timelineData = [];
                    $scope.timelineReady = true;
                    $scope.reloadTrigger++;
                    return;
                }

                $scope.timelineReady = false;
                $scope.productivityChartState = $scope.PRODUCTIVITY_CHART_STATE_INITIAL;
                const filterMode = $scope.user?.filterMode || 'users';

                const params =
                    'from=' +
                    encodeURIComponent(moment($scope.fromDate).format('YYYY-MM-DD')) +
                    '&' +
                    'to=' +
                    encodeURIComponent(moment($scope.toDate).format('YYYY-MM-DD')) +
                    '&' +
                    'userType=' +
                    $scope.userType +
                    '&' +
                    'userId=' +
                    encodeURIComponent($scope.userId) +
                    '&' +
                    'interval=' +
                    $scope.interval +
                    '&' +
                    'mode=' +
                    filterMode +
                    '&' +
                    'strict=' +
                    $scope.isStrict +
                    '&' +
                    'noempty=' +
                    $scope.hideEmptyDays +
                    '&' +
                    'showOfflineMeetings=' +
                    (showOfflineMeetingData && topUsersReportOfflineMeetingsData);

                topUsersService
                    .getProductivityTimeline(
                        params,
                        {
                            params: options.data
                        },
                        $scope.userId
                    )
                    .success(function (result) {
                        // If the returned user different from the last selected user, reload the user details
                        if (
                            lastSelectedUser &&
                            result?.data?.length &&
                            result.data[0].user &&
                            result.data[0].user !== lastSelectedUser.user
                        ) {
                            loadUserDetails(lastSelectedUser);
                            options.error({ data: [], total: 0 });
                            return;
                        }

                        result.data = lodash.map(result.data, function (item) {
                            const startDate = item.start.substr(0, 10);
                            const startTime = item.start.substr(11);
                            const endDate = item.end.substr(0, 10);
                            const endTime = item.end.substr(11);
                            item.date = moment(startDate).format(dateFormat);
                            item.start = startTime;
                            item.end = startDate === endDate ? endTime : '24:00:00';

                            switch (item.productivity) {
                                case 1:
                                    item.productivity = ProductivityStatus.Productive;
                                    break;
                                case 2:
                                    item.productivity = ProductivityStatus.Unproductive;
                                    break;
                                case 3:
                                    item.productivity = ProductivityStatus.Undefined;
                                    break;
                                case 4:
                                    item.productivity =
                                        $scope.layoutMode !== 'Detailed'
                                            ? ProductivityStatus.Undefined
                                            : ProductivityStatus.UndefinedPassive;
                                    break;
                                case 5:
                                    item.productivity =
                                        $scope.layoutMode === 'Detailed'
                                            ? ProductivityStatus.ProductivePassive
                                            : ProductivityStatus.Productive;
                                    break;
                                case 6:
                                    item.productivity = item.productivity =
                                        $scope.layoutMode === 'Detailed'
                                            ? ProductivityStatus.UnproductivePassive
                                            : ProductivityStatus.Unproductive;
                                    break;
                                case 7:
                                    item.productivity =
                                        $scope.layoutMode === 'Detailed'
                                            ? ProductivityStatus.UndefinedPassive
                                            : ProductivityStatus.Undefined;
                                    break;
                                case 9:
                                    item.productivity = ProductivityStatus.OfflineMeetings;
                                    break;
                                default:
                                    item.productivity = undefined;
                            }

                            const locationObj = result.location?.find(
                                (location) => moment(location.date, 'YYYY-MM-DD', true).format(dateFormat) === item.date
                            );

                            const isTodaysDate =
                                atHelperFunctions.isToday($scope.fromDate) && atHelperFunctions.isToday($scope.toDate);

                            const isLocationPresent =
                                typeof locationObj?.location === 'string' && locationObj?.location.trim() !== '';

                            const showLocation = isLocationPresent && showLocationData && locationObj && !isTodaysDate;

                            item.date = showLocation ? `${item.date} (${locationObj.location})` : item.date;

                            return item;
                        });
                        options.success(result);
                        $scope.timelineData = result.data;
                        $scope.timelineReady = true;
                        $scope.reloadTrigger++;
                    })
                    .error(function (result) {
                        options.error(result);
                        $scope.$emit('showNotification', {
                            message: result.error || msg.get('reportLoadingError'),
                            color: 'danger'
                        });
                    });
            }
        },
        pageSize: pageSizeService.loadPageSize('topUsers-timeline', 10),
        serverPaging: true,
        schema: {
            data: 'data',
            total: 'total'
        }
    });

    // Set timeline pager options
    $scope.pagerOptions = {
        dataSource: $scope.timelineDataSource,
        refresh: true,
        pageSizes: ['5', '7', '10', '14'],
        buttonCount: 3,
        messages: {
            itemsPerPage: msg.get('itemsPerPage', 'days'),
            display: msg.get('itemsDisplay', 'days'),
            empty: msg.get('noItemsToDisplay', 'days')
        }
    };

    // Handle top user grid row click
    let lastRowUid;
    $scope.rowClicked = function (row) {
        try {
            $scope.selectedRow = angular.copy(row);

            if (!!$scope.selectedRow && row.uid && lastRowUid !== row.uid) {
                if ($scope.pageMode === 'User') {
                    loadUserDetails(row);
                } else {
                    loadGroupDetails(row);
                }
            }

            lastRowUid = row && row.uid;
        } catch (error) {
            console.error('rowClicked: ', error);
        }
    };

    let groupDetailsDataSourceReloadTimeout = null;

    let detailLoadCount = 0;

    // Load Group Details
    function loadGroupDetails(group) {
        // Set selected group name and duration
        $scope.isDetailLoading = true;
        $scope.selectedGroupName = group.user || group.userName;
        $scope.selectedGroupTotalTime = group.total;

        $timeout.cancel(groupDetailsDataSourceReloadTimeout);
        groupDetailsDataSourceReloadTimeout = $timeout(function () {
            // Refresh group details grid
            pageSizeService.dataSourceReload(groupDetailsDataSource);
        }, 400);
    }

    // Create Usage HTML
    function getUsageTemplateWithDot(field, colorName = null) {
        if (colorName == null) colorName = field;

        return kendo.template(function () {
            return (
                '<div class="text-right at-ellipsis">{{friendlyViewTimeFormat(dataItem.' +
                field +
                ')}} <i class="fa fa-circle ignore-selection text-' +
                colorName +
                '"></i></div>'
            );
        });
    }

    function getUsageTemplate(field) {
        return kendo.template(function () {
            return '<div class="text-right at-ellipsis">{{friendlyViewTimeFormat(dataItem.' + field + ')}}';
        });
    }

    // Create colored bar HTML
    const getBar = function (duration, color, isDetail) {
        if (duration === 0) {
            return '';
        }
        const total = isDetail ? groupDetailTotalActivity : maxUserTotal;
        const percent = Math.max(Math.round((duration * 100) / total), 1);
        return '<div style="width: ' + percent + '%;" class="' + color + ' inline min-width-non-zero">&nbsp;</div>';
    };

    // Define Group detail productivity ratio HTML
    const productivityDetailTemplate = kendo.template(function (data) {
        return productivityTemplate(data, true);
    });

    const isDetailedView = function () {
        return $scope.layoutMode === 'Detailed';
    };

    // Define productivity ratio HTML
    const productivityTemplate = kendo.template(function (data, isDetail) {
        if (!isDetailedView()) {
            const prod = getBar(data.productive, 'bg-productive', isDetail);
            const unprod = getBar(data.unproductive, 'bg-unproductive', isDetail);
            const undef = getBar(data.undefinedActive + data.undefinedPassive, 'bg-undefined', isDetail);
            data.tooltip = productivityTooltip(data);
            return (
                '<div at-tooltip tooltip-content="dataItem.tooltip" tooltip-placement="left" class="text-nowrap">' +
                prod +
                unprod +
                undef +
                '</div>'
            );
        } else {
            const productiveActive = getBar(data.productiveActive, 'bg-productive', isDetail);
            const productivePassive = getBar(data.productivePassive, 'bg-productive-passive', isDetail);
            const unproductiveActive = getBar(data.unproductiveActive, 'bg-unproductive', isDetail);
            const unproductivePassive = getBar(data.unproductivePassive, 'bg-unproductive-passive', isDetail);
            const undefinedActive = getBar(data.undefinedActive, 'bg-undefined', isDetail);
            const undefinedPassive = getBar(data.undefinedPassive, 'bg-passive', isDetail);
            data.tooltip = productivityTooltip(data);
            return (
                '<div at-tooltip tooltip-content="dataItem.tooltip" tooltip-placement="left" class="text-nowrap">' +
                productiveActive +
                productivePassive +
                unproductiveActive +
                unproductivePassive +
                undefinedActive +
                undefinedPassive +
                '</div>'
            );
        }
    });

    // Calc percents from productivity statuses
    function getPercents(data, item) {
        const total = data.total;
        const percent = Math.round((item / total) * 10000) / 100;
        return (isNaN(percent) ? '--' : percent.toFixed(2)) + '%';
    }

    function createDetailedViewTooltip(data) {
        const prod =
            '<tr><td><div class="bg-productive" style="border-radius: 50%; border: 1px solid #BDBDBD; height: 1em; width: 1em"></div></td><td class="p-l-15 text-right">' +
            getPercents(data, data.productiveActive) +
            '</td></tr>';

        const prodPassive =
            '<tr><td><div class="bg-productive-passive" style="border-radius: 50%; border: 1px solid #BDBDBD; height: 1em; width: 1em"></div></i></td><td class="p-l-15 text-right">' +
            getPercents(data, data.productivePassive) +
            '</td></tr>';

        const unprod =
            '<tr><td><div class="bg-unproductive" style="border-radius: 50%; border: 1px solid #BDBDBD; height: 1em; width: 1em"></div></td><td class="p-l-15 text-right">' +
            getPercents(data, data.unproductiveActive) +
            '</td></tr>';

        const unprodPassive =
            '<tr><td><div class="bg-unproductive-passive" style="border-radius: 50%; border: 1px solid #BDBDBD; height: 1em; width: 1em"></div></td><td class="p-l-15 text-right">' +
            getPercents(data, data.unproductivePassive) +
            '</td></tr>';

        const undefinedActive =
            '<tr><td><div class="bg-undefined" style="border-radius: 50%; border: 1px solid #BDBDBD; height: 1em; width: 1em"></div></td><td class="p-l-15 text-right">' +
            getPercents(data, data.undefinedActive) +
            '</td></tr>';

        const undefinedPassive =
            '<tr><td><div class="bg-passive" style="border-radius: 50%; border: 1px solid #BDBDBD; height: 1em; width: 1em"></div></td><td class="p-l-15 text-right">' +
            getPercents(data, data.undefinedPassive) +
            '</td></tr>';

        return (
            '<table>' + prod + prodPassive + unprod + unprodPassive + undefinedActive + undefinedPassive + '</table>'
        );
    }

    function createSummaryViewTooltip(data) {
        const prod =
            '<tr><td><div class="bg-productive" style="border-radius: 50%; border: 1px solid #BDBDBD; height: 1em; width: 1em"></div></td><td class="p-l-15 text-right">' +
            getPercents(data, data.productive) +
            '</td></tr>';

        const unprod =
            '<tr><td><div class="bg-unproductive" style="border-radius: 50%; border: 1px solid #BDBDBD; height: 1em; width: 1em"></div></td><td class="p-l-15 text-right">' +
            getPercents(data, data.unproductive) +
            '</td></tr>';

        const undefinedActive =
            '<tr><td><div class="bg-undefined" style="border-radius: 50%; border: 1px solid #BDBDBD; height: 1em; width: 1em"></div></td><td class="p-l-15 text-right">' +
            getPercents(data, data.undefinedActive + data.undefinedPassive) +
            '</td></tr>';

        return '<table>' + prod + unprod + undefinedActive + '</table>';
    }

    // Define productivity tooltip HTML
    function productivityTooltip(data) {
        if (isDetailedView()) {
            return createDetailedViewTooltip(data);
        } else {
            return createSummaryViewTooltip(data);
        }
    }

    function updateColumns() {
        const grids = [$scope.mainGrid, $scope.groupDetails];

        grids.forEach(function (grid) {
            if ($scope.layoutMode == 'Detailed') {
                grid.showColumn('productiveActive');
                grid.showColumn('productivePassive');
                grid.showColumn('unproductiveActive');
                grid.showColumn('unproductivePassive');
                grid.showColumn('undefinedActive');
                grid.showColumn('undefinedPassive');
                grid.showColumn('active');
                grid.showColumn('offline');

                // originally just stacked cols
                grid.hideColumn('productive');
                grid.hideColumn('unproductive');
                grid.hideColumn('other');
            } else {
                grid.showColumn('productive');
                grid.showColumn('unproductive');
                grid.showColumn('other');
                grid.showColumn('offline');

                grid.hideColumn('productiveActive');
                grid.hideColumn('productivePassive');
                grid.hideColumn('unproductiveActive');
                grid.hideColumn('unproductivePassive');
                grid.hideColumn('undefinedActive');
                grid.hideColumn('undefinedPassive');
                grid.hideColumn('active');
            }
        });
    }

    function boldWrapper(innerHtml) {
        return '<div style="font-weight: 500; color: #404242;">' + innerHtml + '</div>';
    }

    function getGridColumns(barTemplate) {
        const headerTemplate = function (mainText, subText, productivityStatus, tooltip) {
            return (
                '<div style="display: inline-flex; align-items: center;"><div style="text-align:left; color: #404242;"><div style="font-weight: 500;">' +
                mainText +
                '</div> ' +
                subText +
                '</div><div style="padding: 0 0 0 0.75em;" at-tooltip="' +
                tooltip +
                '">' +
                iconResolverServiceFunctions.getProductivityIcon(productivityStatus) +
                '</div></div>'
            );
        };

        const columns = [
            {
                field: 'user',
                headerTemplate: kendo.template(function () {
                    switch ($scope.groupBy) {
                        case 'computers':
                            return boldWrapper(msg.get('computer'));
                        case 'groups':
                            return boldWrapper(msg.get('group'));
                        default:
                            return boldWrapper(msg.get('user'));
                    }
                }),
                attributes: {
                    class: 'grid-tooltip text-nowrap'
                },
                filterable: templateServiceFunctions.createFilter('user', topUsersDataSource)
            },
            {
                field: 'userId',
                sortable: {
                    compare: function (a, b, desc) {
                        const field = desc ? 'unproductive' : 'productive';
                        const direction = desc ? 1 : -1;
                        const valueA = parseInt(a[field]);
                        const valueB = parseInt(b[field]);
                        return valueA < valueB ? -1 * direction : valueA > valueB ? 1 * direction : 0;
                    }
                },
                headerTemplate: kendo.template(function () {
                    return boldWrapper(msg.get('productivityRatio'));
                }),
                template: barTemplate,
                filterable: false
            },
            {
                field: 'productive',
                headerTemplate: kendo.template(function () {
                    return headerTemplate(
                        'Productive',
                        '',
                        ProductivityStatus.Productive,
                        'Time within productive activities'
                    );
                }),
                filterable: false,
                attributes: {
                    class: 'text-nowrap'
                },
                template: getUsageTemplateWithDot('productive', 'productive')
            },
            {
                field: 'productiveActive',
                headerTemplate: kendo.template(function () {
                    return headerTemplate(
                        'Productive',
                        'Active',
                        ProductivityStatus.Productive,
                        "Time <span style='font-style: italic; font-weight: 500;'>interacting</span> within productive activities"
                    );
                }),
                filterable: false,
                attributes: {
                    class: 'text-nowrap'
                },
                template: getUsageTemplateWithDot('productiveActive', 'productive')
            },
            {
                field: 'productivePassive',
                headerTemplate: kendo.template(function () {
                    return headerTemplate(
                        'Productive',
                        'Passive',
                        ProductivityStatus.ProductivePassive,
                        "Time <span style='font-style: italic; font-weight: 500;'>consuming</span> information within productive activities"
                    );
                }),
                filterable: false,
                attributes: {
                    class: 'text-nowrap'
                },
                template: getUsageTemplateWithDot('productivePassive', 'productive-passive')
            },
            {
                field: 'unproductive',
                headerTemplate: kendo.template(function () {
                    return headerTemplate(
                        'Unproductive',
                        '',
                        ProductivityStatus.Unproductive,
                        'Time within unproductive activities'
                    );
                }),
                filterable: false,
                attributes: {
                    class: 'text-nowrap'
                },
                template: getUsageTemplateWithDot('unproductive', 'unproductive')
            },
            {
                field: 'unproductiveActive',
                headerTemplate: kendo.template(function () {
                    return headerTemplate(
                        'Unproductive',
                        'Active',
                        ProductivityStatus.Unproductive,
                        "Time <span style='font-style: italic; font-weight: 500;'>interacting</span> within unproductive activities"
                    );
                }),
                filterable: false,
                attributes: {
                    class: 'text-nowrap'
                },
                template: getUsageTemplateWithDot('unproductiveActive', 'unproductive')
            },
            {
                field: 'unproductivePassive',
                headerTemplate: kendo.template(function () {
                    return headerTemplate(
                        'Unproductive',
                        'Passive',
                        ProductivityStatus.UnproductivePassive,
                        "Time <span style='font-style: italic; font-weight: 500;'>consuming</span> information within unproductive activities"
                    );
                }),
                filterable: false,
                attributes: {
                    class: 'text-nowrap'
                },
                template: getUsageTemplateWithDot('unproductivePassive', 'unproductive-passive')
            },
            {
                field: 'other',
                headerTemplate: kendo.template(function () {
                    return headerTemplate(
                        'Undefined',
                        '',
                        ProductivityStatus.Undefined,
                        'Time within undefined activities'
                    );
                }),
                filterable: false,
                attributes: {
                    class: 'text-nowrap'
                },
                template: getUsageTemplateWithDot('other', 'undefined')
            },
            {
                field: 'undefinedActive',
                headerTemplate: kendo.template(function () {
                    return headerTemplate(
                        'Undefined',
                        'Active',
                        ProductivityStatus.Undefined,
                        "Time <span style='font-style: italic; font-weight: 500;'>interacting</span> within non-classified activities"
                    );
                }),
                filterable: false,
                attributes: {
                    class: 'text-nowrap'
                },
                template: getUsageTemplateWithDot('undefinedActive', 'undefined')
            },
            {
                field: 'undefinedPassive',
                headerTemplate: kendo.template(function () {
                    return headerTemplate(
                        'Undefined',
                        'Passive',
                        ProductivityStatus.UndefinedPassive,
                        "Time <span style='font-style: italic; font-weight: 500;'>consuming</span> information within non-classified activities"
                    );
                }),
                filterable: false,
                attributes: {
                    class: 'text-nowrap'
                },
                template: getUsageTemplateWithDot('undefinedPassive', 'passive')
            },
            {
                field: 'total',
                headerTemplate: kendo.template(function () {
                    return (
                        '<div style="font-weight: 500; color: #404242;" at-tooltip="Total time includes active time (productive,<br/>unproductive, & undefined) and passive time.">' +
                        msg.get('totalUsage') +
                        '</div>'
                    );
                }),
                attributes: {
                    class: 'text-right'
                },
                template: getUsageTemplate('total'),
                filterable: false
            },
            {
                field: 'active',
                headerTemplate: kendo.template(function () {
                    return boldWrapper(msg.get('activeTime'));
                }),
                filterable: false,
                attributes: {
                    class: 'text-right'
                },
                template: getUsageTemplate('active')
            },
            {
                field: 'offline',
                title: 'Offline Meetings',
                headerTemplate: kendo.template(function () {
                    return templateServiceFunctions.offlineMeetingsHeaderTemplate(calendarIntegrated);
                }),
                filterable: false,
                sortable: false,
                template: kendo.template(function (dataItem) {
                    const isTodaysDate =
                        atHelperFunctions.isToday($scope.fromDate) && atHelperFunctions.isToday($scope.toDate);

                    if (isTodaysDate) {
                        return '';
                    }

                    if (!calendarIntegrated) {
                        return templateServiceFunctions.productivityTimeTemplate(0, -4);
                    }
                    return templateServiceFunctions.productivityTimeTemplate(dataItem.offline || 0, -4);
                }),
                attributes: {
                    class: 'text-nowrap text-right no-tooltip'
                },
                removeColumn: !topUsersReportOfflineMeetingsData || !showOfflineMeetingData
            }
        ];
        return atHelperFunctions.filterDefaultColumns(columns);
    }

    function generateMainGridOptions() {
        return {
            autoBind: false,
            dataSource: topUsersDataSource,
            columns: getGridColumns(productivityTemplate),
            filterable: {
                mode: 'row'
            },
            selectable: 'row',
            sortable: true,
            scrollable: {
                virtual: true
            },
            height: atHelperFunctions.getGridHeight($scope.mainGridHeight()),
            dataBound: function (e) {
                const paginationState = {
                    index: topUsersDataSource.page(),
                    size: topUsersDataSource.pageSize()
                };

                const exportParams = {
                    limitToPage: paginationState,
                    groupBy: $scope.groupBy,
                    hasDataForTable: !$scope.noResults
                };

                setExportParamsStore(exportParams);

                const grid = e.sender;
                const data = grid._data;
                let firstRow;
                let selectedRow;
                data.forEach(function (row, key) {
                    if (key === 0) {
                        firstRow = row;
                    } else if (
                        ($scope.selectedRow?.userId && $scope.selectedRow.userId === row.userId) ||
                        ($scope.groupBy !== 'groups' && row.user && $scope.selectedUser === row.user && initialLoad) ||
                        ($scope.groupBy === 'groups' &&
                            row.userId &&
                            $scope.selectedUser === row.user &&
                            initialLoad) ||
                        ($scope.groupBy === 'groups' &&
                            row.userId &&
                            $scope.selectedGroupId === row.userId &&
                            initialLoad)
                    ) {
                        selectedRow = row;
                    }
                });

                if (!selectedRow || !selectedRow.uid) {
                    selectedRow = firstRow;
                }

                $scope.selectedRow = selectedRow;

                if (selectedRow && selectedRow.uid) {
                    grid.select('tr[data-uid="' + selectedRow.uid + '"]');
                }

                updateColumns();

                initialLoad = false;
                $scope.isLoading = false;
            }
        };
    }

    // Setup main grid (left grid)
    $scope.mainGridOptions = generateMainGridOptions();

    // Setup group details grid (right grid in Group mode)
    $scope.groupDetailsGridOptions = {
        autoBind: false,
        dataSource: groupDetailsDataSource,
        columns: getGridColumns(productivityDetailTemplate),
        filterable: {
            mode: 'row'
        },
        sortable: true,
        scrollable: true,
        height: atHelperFunctions.getGridHeight($scope.groupDetailsGridHeight())
    };

    // Setup mobile grid
    $scope.topUsersMobileGridOptions = {
        autoBind: false,
        dataSource: topUsersDataSource,
        pageSizes: [5, 10, 20, 50]
    };

    $scope.comboboxOption = function (data) {
        return {
            dataTextField: data.dataTextField || 'text',
            dataValueField: data.dataValueField || 'value',
            dataSource: data.dataSource || [],
            select: function () {
                if (this && this.input && typeof this.input.blur === 'function') {
                    this.input.blur();
                }
            },
            dataBound: function (e) {
                e.sender.input
                    .attr('readonly', true)
                    .on('keydown', function (e) {
                        if (e.keyCode === 8) {
                            e.preventDefault();
                        }
                    })
                    .on('click', function () {
                        e.sender.toggle();
                    });

                if (typeof data.dataBound === 'function') {
                    data.dataBound(e);
                }
            }
        };
    };

    // Define intervals for timeline segments
    $scope.intervalOptions = $scope.comboboxOption({
        dataSource: [
            {
                text: msg.get('fiveMinutes'),
                value: '5'
            },
            {
                text: msg.get('fifteenMinutes'),
                value: '15'
            },
            {
                text: msg.get('thirtyMinutes'),
                value: '30'
            },
            {
                text: msg.get('oneHour'),
                value: '60'
            },
            {
                text: msg.get('twoHours'),
                value: '120'
            }
        ]
    });

    let lastSelectedUser;
    let loadCount = 0;

    // Load user details
    function loadUserDetails(dataItem) {
        try {
            // If the dateItem user is the same as the last selected user,
            // increment the load count to ensure we don't get stuck in a loop
            if (dataItem?.user === lastSelectedUser?.user) {
                loadCount++;
            } else {
                loadCount = 0;
            }

            lastSelectedUser = {
                user: dataItem.user,
                userId: dataItem.userId,
                userType: dataItem.userType,
                total: dataItem.total
            };

            if (loadCount > 5) {
                throw new Error('loadUserDetails: Load count exceeded.');
            }

            $scope.userId = dataItem.userId;
            $scope.userType = dataItem.userType;
            $scope.timelineReady = false;
            pageSizeService.dataSourceReload($scope.timelineDataSource);
            $scope.username = dataItem.user;
            $scope.totalTime = dataItem.total;
            $scope.timelineType = $scope.groupBy;
        } catch (error) {
            const errorMessage = `Error loading user details for ${dataItem.user}.`;
            console.error(`ActivTrak Error: ${errorMessage}`, error);
            $scope.$emit('showNotification', {
                message: errorMessage,
                color: 'danger'
            });
        }
    }

    // Toggle fullscreen timeline
    $scope.toggleTimelineFullScreen = function (value) {
        $scope.timelineFullScreen = value;
        $scope.reloadTrigger++;
        const tooltips = $document.find('[data-role=tooltip]');
        tooltips.each(function () {
            const tooltip = $(this).data('kendoTooltip');
            if (tooltip) {
                tooltip.hide();
            }
        });
    };

    // Handle group details user click
    $scope.groupUserClicked = function (user) {
        $scope.toggleTimelineFullScreen(true);
        $timeout(function () {
            loadUserDetails(user);
        });
    };

    // Handle change to timeline interval
    $scope.$watch('interval', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            $scope.timelineReady = false;
            reloadTimeline();
        }
    });

    // Handle change to timeline strict mode
    $scope.$watch('isStrict', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            reloadTimeline();
        }
    });

    // Handle change to timeline hide empty days checkbox
    $scope.$watch('hideEmptyDays', function (newValue, oldValue) {
        if (newValue !== oldValue) {
            reloadTimeline();
        }
    });

    // Handle reloading of the timeline
    function reloadTimeline() {
        $scope.timelineReady = false;
        $timeout(function () {
            pageSizeService.dataSourceReload($scope.timelineDataSource);
        });
    }

    // Change page mode
    let lastMode = null;
    $scope.updatePageMode = function () {
        if (!$scope.timelineReady || $scope.isLoading) {
            return;
        }

        if (lastMode !== $scope.pageMode) {
            lastMode = $scope.pageMode;
            $scope.selectedRow = null;
            const filterMode = $scope.user?.filterMode || 'users';
            $scope.reloadUsers = true;
            updateGroupBy($scope.pageMode === 'User' ? filterMode : 'groups');
        }
    };

    $scope.updateLayoutMode = function () {
        if (!$scope.timelineReady || $scope.isLoading) {
            return;
        }
        $scope.mainGrid.setOptions(generateMainGridOptions());
        updateColumns();
        pageSizeService.dataSourceReload(topUsersDataSource);
    };

    // Bind report on load and global filter changes
    $scope.bindReport = function (filters) {
        $scope.reportFilters = filters ?? $scope.reportFilters;
        const { users, dates } = $scope.reportFilters;
        const { toDate, fromDate } = dates.getDates();
        $scope.user = users[0];
        $scope.fromDate = fromDate;
        $scope.toDate = toDate;
        if (initialLoad) {
            $scope.processStateParams();
        } else {
            $scope.nextGroupBy = $scope.pageMode === 'User' ? $scope.user?.filterMode : 'groups';
        }

        const groupBy = $scope.nextGroupBy || $scope.groupBy;
        $scope.timelineReady = groupBy === 'groups';
        $scope.toggleTimelineFullScreen(false);
        setPageModeToggleOptions();

        googleChartApiPromise.then(function () {
            $scope.reloadUsers = true;
            updateGroupBy(groupBy);
        });
    };

    function setPageModeToggleOptions() {
        $scope.pageModeToggleOptions = {
            labels: [
                {
                    heapId: 'id_ff880562-e76b-4509-972b-a85a86175f61',
                    text: 'User',
                    value: getModeLabel(false)
                },
                {
                    heapId: 'id_36c902f6-4754-4e62-a7e4-43b81f1cf2a0',
                    text: 'Group',
                    value: 'Groups'
                }
            ],
            onChange: function (val) {
                $scope.pageMode = val;
                $scope.updatePageMode();
            }
        };
    }

    $scope.layoutModeToggleOptions = {
        labels: [
            {
                heapId: 'id_d86eb257-c31d-4415-a989-e767eba0640c',
                text: 'Summary',
                value: 'Summary View'
            },
            {
                heapId: 'id_3c58f86c-8652-4cd6-91c4-36ab39e04fc7',
                text: 'Detailed',
                value: 'Detailed View'
            }
        ],
        onChange: function (val) {
            $scope.layoutMode = val;
            $scope.updateLayoutMode();
        }
    };

    // Export...
    exportInfoService.setExportInfo({
        mainDataSource: topUsersDataSource,
        getSecondaryParameters: function () {
            return {
                filter: topUsersDataSource.filter()
            };
        },
        getSortParameters: function () {
            return {
                sort: topUsersDataSource.sort()
            };
        }
    });

    $scope.$watch('pageMode', function (newVal, oldVal) {
        if (!!newVal && newVal !== oldVal) {
            $state.current.data.reportName = newVal === 'User' ? 'topUsersReportName' : 'topGroupsReportName';
        }
    });

    $scope.$watch(
        function () {
            return angular.element('div[kendo-pager]').length || 0;
        },
        function (newValue) {
            if (newValue > 1) {
                angular
                    .element('.hidden-lg.hidden-md .k-pager-wrap.k-widget.k-pager-sm .k-pager-sizes.k-label')
                    .contents()
                    .each(function (i, elem) {
                        if (elem.constructor === Text) {
                            elem.remove();
                        }
                    });
            }
        }
    );

    $scope.processStateParams = () => {
        function getGroupByFromStateParams(groupBy) {
            if (groupBy) {
                return groupBy;
            }

            return $scope.pageMode === 'User' ? $scope.user?.filterMode : 'groups';
        }

        function getSelectedUserFromStateParams(name) {
            return name ? lodash.unescape(decodeURIComponent(name)) : null;
        }

        $scope.selectedUser = getSelectedUserFromStateParams($state.params?.name);
        $scope.nextGroupBy = getGroupByFromStateParams($state.params?.groupBy);
        $scope.selectedGroupId = $state.params?.groupId;
        $scope.pageMode = $scope.nextGroupBy === 'groups' ? 'Group' : 'User';
        $scope.groupsSelected = $scope.nextGroupBy === 'groups';
    };

    $timeout(function () {
        if (pageSizeService.setDropdownHandler) {
            pageSizeService.setDropdownHandler('topUsers-master', '.top-users-grid');
            pageSizeService.setDropdownHandler('topUsers-timeline', '.top-users-timeline');
        }
    });

    // Set page mode button label
    $scope.toggleButtonLabel = function () {
        return getModeLabel();
    };
}

angular.module('app').service('topUsersService', TopUsersService);

TopUsersService.$inject = ['$http', 'envConfig'];

function TopUsersService($http, envConfig) {
    this.getUsers = function (parameters, groupBy) {
        const grpBy = groupBy || 'users';
        const url = envConfig.reportingServiceUrl() + '/reports/v1/topusers?';
        return $http.get(url + parameters + '&groupBy=' + grpBy);
    };

    this.getProductivityTimeline = function (parameters, data, userId) {
        const url = envConfig.reportingServiceUrl() + '/reports/v1/topusers/' + userId + '/gantt?';
        return $http.get(url + parameters, data); //userId is also in the data, the reporting service ignores it because its in the URL path, needed for legacy
    };
}

import React, { useEffect, useState } from 'react';

import { IUserIdentity, IValueValidation } from '../models/IUserIdentity';

import { IUserIdentityCreateDto } from '../models/IUserIdentityDto';
import { AgentSearchModal } from './AgentSearchModal';
import { IIdentityCommonState } from '../models/IIdentityCommonState';
import CustomGrid from '../../common/components/CustomGrid/CustomGrid';
import { useSelectedAgentColumns } from '../utils/useSelectedAgentColumns';
import { IIdentityDetailState } from '../models/IIdentityDetailState';
import { IAgentSearchState } from '../models/IAgentSearchState';
import { validateEmailArray } from '../utils/DetailEmailEmployeeIdUtils';
import AddIcon from '@mui/icons-material/Add';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DefaultUserIdentity } from '../constants/DefaultObjects';
import { trimValueId } from '../utils/TrimUtils';

type ComponentProps = {
  isOpen: boolean;
  selectedIdentity: IUserIdentity;
  onClose();
  title: string;
  saveButtonText: string;
  newUserMode: boolean;
  agentSearchState: IAgentSearchState;
  commonState: IIdentityCommonState;
  detailState?: IIdentityDetailState;
};

export const EditOrInsertUserModal = (props: ComponentProps) => {
  const {
    isOpen,
    selectedIdentity,
    onClose,
    title,
    newUserMode,
    saveButtonText,
    agentSearchState,
    commonState,
    detailState
  } = props;

  const { setOpenAssignAgentModal, agentsSelected, isLoadingAgents } =
    agentSearchState;

  const [saveButtonEnabled, setSaveButtonEnabled] = useState<boolean>(false);
  const [tempSelectedIdentity, setTempSelectedIdentity] =
    useState<IUserIdentity>();
  const defaultValueValidation: IValueValidation = {
    value: null,
    originalValue: null,
    validationFailed: false,
    failedExplanation: ' '
  };

  const agentGridColumns = useSelectedAgentColumns();

  useEffect(() => {
    if (!newUserMode) {
      const clone = structuredClone(selectedIdentity);
      setTempSelectedIdentity(clone);
    }
  }, [selectedIdentity, isOpen, newUserMode]);

  const handleFirstChange = (e) => {
    setTempSelectedIdentity((prevState) => {
      return {
        ...prevState,
        firstName: {
          ...(prevState?.firstName ?? defaultValueValidation),
          value: e.target.value
        }
      };
    });
  };
  const handleMiddleChange = (e) => {
    setTempSelectedIdentity((prevState) => {
      return {
        ...prevState,
        middleName: {
          ...(prevState?.middleName ?? defaultValueValidation),
          value: e.target.value
        }
      };
    });
  };
  const handleLastChange = (e) => {
    setTempSelectedIdentity((prevState) => {
      return {
        ...prevState,
        lastName: {
          ...(prevState?.lastName ?? defaultValueValidation),
          value: e.target.value
        }
      };
    });
  };
  const handleDisplayNameChange = (e) => {
    setTempSelectedIdentity((prevState) => {
      return {
        ...prevState,
        displayName: {
          ...(prevState?.displayName ?? defaultValueValidation),
          value: e.target.value
        }
      };
    });
  };

  const handleEmployeeIdChange = (e) => {
    setTempSelectedIdentity((prevState) => {
      return {
        ...prevState,
        employeeId: {
          ...(prevState?.employeeId ?? defaultValueValidation),
          value: e.target.value
        }
      };
    });
  };

  const handleEmailChange = (e) => {
    const clone = { ...tempSelectedIdentity };

    if (clone.emails == null) {
      clone.emails = [];
    }
    if (clone.emails.length == 0) {
      clone.emails.push({
        value: e.target.value,
        originalValue: '',
        validationFailed: false,
        failedExplanation: ' ',
        primary: false
      });
    } else {
      clone.emails[0].value = e.target.value;
    }

    setTempSelectedIdentity(clone);
  };

  useEffect(() => {
    const changedData =
      selectedIdentity?.firstName?.value !==
        tempSelectedIdentity?.firstName?.value ||
      selectedIdentity?.middleName?.value !==
        tempSelectedIdentity?.middleName?.value ||
      selectedIdentity?.lastName?.value !==
        tempSelectedIdentity?.lastName?.value ||
      selectedIdentity?.displayName?.value !==
        tempSelectedIdentity?.displayName?.value ||
      selectedIdentity?.employeeId?.value !==
        tempSelectedIdentity?.employeeId?.value ||
      (newUserMode &&
        JSON.stringify(selectedIdentity?.emails) !==
          JSON.stringify(tempSelectedIdentity?.emails));
    const newUserEmailValidated =
      !newUserMode ||
      (tempSelectedIdentity?.emails?.length > 0 &&
        tempSelectedIdentity?.emails[0].value &&
        tempSelectedIdentity?.emails[0].validationFailed == false);
    const tempSaveEnabled = changedData && newUserEmailValidated;

    setSaveButtonEnabled(tempSaveEnabled);
  }, [selectedIdentity, tempSelectedIdentity, newUserMode]);

  const resetNewUser = () => {
    if (newUserMode) {
      setTempSelectedIdentity({ ...DefaultUserIdentity });
    }
  };

  const onCancelChanges = () => {
    resetNewUser();
    onClose();
  };

  const onCloseAgentModal = () => {
    setOpenAssignAgentModal(false);
  };

  const onAssignAgent = () => {
    agentSearchState.initializeAgents();
    setOpenAssignAgentModal(true);
  };

  const GetSaveDto = () => {
    const saveDto: IUserIdentityCreateDto = {
      firstName:
        selectedIdentity?.firstName?.value ==
        tempSelectedIdentity?.firstName?.value
          ? null
          : trimValueId(tempSelectedIdentity?.firstName),
      middleName:
        selectedIdentity?.middleName?.value ==
        tempSelectedIdentity?.middleName?.value
          ? null
          : trimValueId(tempSelectedIdentity?.middleName),
      lastName:
        selectedIdentity?.lastName?.value ==
        tempSelectedIdentity?.lastName?.value
          ? null
          : trimValueId(tempSelectedIdentity?.lastName),
      displayName:
        selectedIdentity?.displayName?.value ==
        tempSelectedIdentity?.displayName?.value
          ? null
          : trimValueId(tempSelectedIdentity?.displayName),
      email:
        JSON.stringify(selectedIdentity?.emails) ==
        JSON.stringify(tempSelectedIdentity?.emails)
          ? null
          : {
              id: null,
              value: tempSelectedIdentity?.emails[0].value
            },
      employeeId:
        selectedIdentity?.employeeId?.value ==
        tempSelectedIdentity?.employeeId?.value
          ? null
          : trimValueId(tempSelectedIdentity?.employeeId),
      userid:
        agentsSelected?.length > 0
          ? { value: agentsSelected[0].userId.toString() }
          : null,
      entity:
        agentsSelected?.length > 0
          ? { value: agentsSelected[0].userId?.toString() }
          : null,
      revision:
        agentsSelected?.length > 0
          ? { value: agentsSelected[0].revision?.toString() }
          : null
    };
    return saveDto;
  };

  const validateChanges = () => {
    let passedValidation = true;
    if (newUserMode) {
      passedValidation = !validateEmailArray(tempSelectedIdentity?.emails);
    }
    return passedValidation;
  };

  const saveNewUser = async () => {
    const createDto = GetSaveDto();
    const [tempIdentity, tempError] = await commonState.onCreateNewUser(
      createDto,
      tempSelectedIdentity,
      agentsSelected
    );
    if (tempError) {
      setTempIdentityAndClose(tempIdentity, tempError);
    } else {
      reloadUserAndClose(tempError);
      resetNewUser();
    }
  };

  const saveExistingUser = async () => {
    const editDto = GetSaveDto();
    const [tempIdentity, tempError] = await detailState.onSaveUserDetail(
      editDto,
      tempSelectedIdentity,
      null
    );
    setTempIdentityAndClose(tempIdentity, tempError);
  };

  const onSaveChanges = async () => {
    if (validateChanges()) {
      if (newUserMode) {
        await saveNewUser();
      } else {
        await saveExistingUser();
      }
    } else {
      setTempSelectedIdentity({ ...tempSelectedIdentity });
    }
  };

  const reloadUserAndClose = (tempError) => {
    if (
      !tempError &&
      agentsSelected &&
      agentsSelected.length > 0 &&
      selectedIdentity &&
      detailState
    ) {
      //rebind to remove bound agents that were moved
      detailState.getUserDetail(selectedIdentity.id);
    }
    onClose();
  };

  const setTempIdentityAndClose = (identity: IUserIdentity, error: any) => {
    if (identity) {
      setTempSelectedIdentity({ ...identity });
    }
    if (!error) {
      onClose();
    }
  };

  const updateEmailValidation = () => {
    if (
      tempSelectedIdentity?.emails &&
      tempSelectedIdentity.emails.length > 0
    ) {
      const oldValidationFailed =
        tempSelectedIdentity.emails[0].validationFailed;
      const newFailed = validateEmailArray(tempSelectedIdentity.emails);
      if (oldValidationFailed !== newFailed) {
        setTempSelectedIdentity({ ...tempSelectedIdentity });
      }
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={'md'}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent id="edit-user-dialog-content" sx={{ paddingBottom: 2 }}>
        <Grid container justifyContent={'space-between'} spacing={1}>
          {newUserMode && (
            <Grid item xs={12}>
              <TextField
                required={newUserMode}
                name="email"
                label="Email"
                value={
                  tempSelectedIdentity?.emails?.length > 0
                    ? tempSelectedIdentity?.emails[0].value
                    : ''
                }
                onChange={(e) => handleEmailChange(e)}
                fullWidth
                inputProps={{ maxLength: 255 }}
                error={
                  tempSelectedIdentity?.emails?.length > 0
                    ? tempSelectedIdentity?.emails[0].validationFailed
                    : false
                }
                helperText={
                  tempSelectedIdentity?.emails?.length > 0
                    ? tempSelectedIdentity?.emails[0].failedExplanation
                    : ' '
                }
                onBlur={updateEmailValidation}
              />
            </Grid>
          )}
          <Grid item xs={7}>
            <Box sx={{ paddingRight: '16px;' }}>
              <TextField
                name="firstName"
                label="First Name"
                value={tempSelectedIdentity?.firstName?.value}
                onChange={(e) => handleFirstChange(e)}
                fullWidth
                inputProps={{ maxLength: 255 }}
                helperText=" "
              />
            </Box>
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="middleName"
              label="Middle Name"
              value={tempSelectedIdentity?.middleName?.value}
              onChange={(e) => handleMiddleChange(e)}
              fullWidth
              inputProps={{ maxLength: 255 }}
              helperText=" "
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="lastName"
              label="Last Name"
              value={tempSelectedIdentity?.lastName?.value}
              onChange={(e) => handleLastChange(e)}
              fullWidth
              inputProps={{ maxLength: 255 }}
              helperText=" "
            />
          </Grid>
          <Grid item xs={7}>
            <Box sx={{ paddingRight: '16px;' }}>
              <TextField
                name="displayName"
                label="Display Name"
                value={tempSelectedIdentity?.displayName?.value}
                onChange={(e) => handleDisplayNameChange(e)}
                fullWidth
                inputProps={{ maxLength: 255 }}
                helperText={
                  tempSelectedIdentity?.displayName?.failedExplanation &&
                  tempSelectedIdentity?.displayName?.failedExplanation !== ' '
                    ? tempSelectedIdentity?.displayName?.failedExplanation
                    : 'Easily identify your reports by adding a unique display name'
                }
                error={tempSelectedIdentity?.displayName?.validationFailed}
              />
            </Box>
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="employeeId"
              label="Employee ID"
              value={tempSelectedIdentity?.employeeId?.value}
              onChange={(e) => handleEmployeeIdChange(e)}
              fullWidth
              inputProps={{ maxLength: 255 }}
              helperText={
                tempSelectedIdentity?.employeeId?.failedExplanation &&
                tempSelectedIdentity?.employeeId?.failedExplanation !== ' '
                  ? tempSelectedIdentity?.employeeId?.failedExplanation
                  : 'Employee ID must be associated with this employee'
              }
              error={tempSelectedIdentity?.employeeId?.validationFailed}
            />
          </Grid>

          {newUserMode && (
            <>
              <Grid item xs={12}>
                <Box sx={{ pt: 4 }}>
                  {agentsSelected && agentsSelected.length ? (
                    <span>Agent assigned to user</span>
                  ) : (
                    <span>Assign an agent to this user</span>
                  )}
                </Box>
              </Grid>
              {agentsSelected && agentsSelected.length > 0 && (
                <Grid item xs={12}>
                  <Box sx={{ pb: 2 }}>
                    <CustomGrid
                      data={agentsSelected}
                      columns={agentGridColumns}
                      isLoading={isLoadingAgents}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={6}>
                {((newUserMode && agentsSelected == null) ||
                  agentsSelected.length === 0) && (
                  <Button
                    onClick={onAssignAgent}
                    disabled={!saveButtonEnabled}
                    color="secondary"
                    startIcon={<AddIcon />}
                  >
                    Assign Agent
                  </Button>
                )}
              </Grid>
            </>
          )}
          {!newUserMode && (
            <Grid item xs={6}>
              &nbsp;
            </Grid>
          )}
          <Grid item xs={6}>
            <DialogActions>
              <Button onClick={onCancelChanges}>Cancel</Button>
              <Button
                onClick={onSaveChanges}
                variant="contained"
                disabled={!saveButtonEnabled}
              >
                {saveButtonText}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
        <AgentSearchModal
          agentSearchState={agentSearchState}
          commonState={commonState}
          onClose={onCloseAgentModal}
          //used to add an agent to a brand new user
          userDescription="the new user"
          readOnlyAgentIds={[]}
        />
      </DialogContent>
    </Dialog>
  );
};

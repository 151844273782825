import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { IGridColumn } from '../../common/components/CustomGrid/CustomGrid.models';
import { TimeAgo } from '../../common/components/TimeAgo';
import {
  InfoOutlinedIcon,
  TextOverflow
} from '../../common/styles/app.component.styles';
import {
  deleteUserData,
  startTracking,
  stopTracking
} from '../constants/ButtonLabels';
import { WhiteBackgroundTooltip } from '../../common/components/Tooltip/TextWithTooltip.styles';
import IconButton from '@mui/material/IconButton';
import { IdentityActionsButton } from '../components/IdentityActionsButton';
import Link from '../../common/components/Link';
import { AnchorTags } from '../../common/constants/routesUrls';
import { getTimeLapsedSinceNowString } from '../../common/utils/datetime/datetimeCalculations';

const nameColumn = (dataItem: any) => {
  return (
    <Tooltip
      title={
        dataItem.id
          ? dataItem.nameColumnDisplay ?? ''
          : 'This user is being processed. This may take up to 24 hours to complete.'
      }
      placement="bottom-start"
    >
      <TextOverflow>{dataItem.nameColumnDisplay}</TextOverflow>
    </Tooltip>
  );
};

const displayNameColumn = (dataItem: any) => {
  return (
    dataItem.displayName?.value && (
      <Tooltip
        title={dataItem.displayName?.value ?? ''}
        placement="bottom-start"
      >
        <TextOverflow>{dataItem.displayName?.value}</TextOverflow>
      </Tooltip>
    )
  );
};

const employeeIdColumn = (dataItem: any) => {
  return (
    dataItem.employeeId && (
      <Tooltip
        title={dataItem.employeeId?.value ?? ''}
        placement="bottom-start"
      >
        <TextOverflow>{dataItem.employeeId?.value}</TextOverflow>
      </Tooltip>
    )
  );
};

const primaryIdColumn = (dataItem: any) => {
  return (
    <Tooltip title={dataItem.emailsDisplay ?? ''} placement="bottom">
      <TextOverflow>{dataItem.emailsDisplay}</TextOverflow>
    </Tooltip>
  );
};

const lastActivityColumn = (dataItem: any) => {
  const timeAgo = getTimeLapsedSinceNowString(dataItem.maxLastLog);
  return (
    <TimeAgo
      lastLog={dataItem.maxLastLog}
      learnMoreLink="https://support.activtrak.com/"
      timeAgo={timeAgo}
    />
  );
};

const headerGlobalExclusion = () => {
  return (
    <>
      Tracking
      <WhiteBackgroundTooltip
        title={
          <>
            User level tracking is managed from this page. If you want to
            exclude on a global level, please navigate to the{' '}
            <Link
              rel="noreferrer"
              href={`/#/app/settings/configuration${AnchorTags.GTEUsers}`}
            >
              Account Configuration page.
            </Link>
          </>
        }
        enterTouchDelay={0}
      >
        <IconButton sx={{ padding: '0px 0px 0px 3px !important' }}>
          <InfoOutlinedIcon />
        </IconButton>
      </WhiteBackgroundTooltip>
    </>
  );
};

const menuColumn = (
  dataItem: any,
  onChangeOption: (option?: any, dataItem?: any) => void,
  isReadOnly: boolean
) => {
  const menuOptions: { label: string; disabled?: boolean }[] = [];
  menuOptions.push({
    label: dataItem.tracked ? stopTracking : startTracking,
    disabled: isReadOnly
  });
  menuOptions.push({
    label: deleteUserData,
    disabled: dataItem.agents?.length === 0 || isReadOnly
  });
  return (
    <IdentityActionsButton
      menuOptions={menuOptions}
      onChangeOption={(e, option) => onChangeOption(option, dataItem)}
      disabled={isReadOnly}
    />
  );
};

export const useUserColumns = (
  dateTimeFormat: string,
  onChangeOption: (option?: any, dataItem?: any) => void,
  isReadOnly: boolean
): IGridColumn[] => {
  return [
    {
      field: 'Name',
      fieldName: 'name',
      headerName: 'Name',
      type: 'string',
      isSortable: true,
      width: '21%',
      template: nameColumn
    },
    {
      field: 'displayName',
      fieldName: 'displayName',
      headerName: 'Display Name',
      type: 'string',
      isSortable: true,
      width: '16%',
      template: displayNameColumn
    },
    {
      field: 'employeeId',
      headerName: 'Employee ID',
      type: 'string',
      isSortable: true,
      width: '10%',
      template: employeeIdColumn
    },
    {
      field: 'email',
      fieldName: 'email',
      headerName: 'Primary ID',
      type: 'string',
      isSortable: true,
      width: '22%',
      template: primaryIdColumn
    },
    {
      field: 'tracked',
      headerName: headerGlobalExclusion(),
      type: 'boolean',
      isSortable: true,
      width: '9%',
      align: 'center',
      template: (dataItem) => {
        return dataItem?.tracked ? 'Tracked' : 'Untracked';
      }
    },
    {
      field: 'userCount',
      headerName: 'Agents',
      type: 'string',
      isSortable: true,
      width: '6%',
      align: 'right'
    },
    {
      field: 'lastLog',
      headerName: 'Last Activity',
      type: 'date',
      isSortable: true,
      width: '11%',
      template: (dataItem) => lastActivityColumn(dataItem)
    },
    {
      type: 'string',
      isSortable: false,
      width: '5%',
      template: (dataItem) => menuColumn(dataItem, onChangeOption, isReadOnly)
    }
  ];
};

import { authenticatedUserService } from 'activtrak-ui-utilities';
import {
  getLocalStorageItem,
  setLocalStorageItem
} from '../../common/utils/localStorage';
import { supportPortalLocalStorage } from '../constants';
import { ITokenDto } from '../models/IApiResponseDto';

const switchToImpersonatedCredentials = async (
  token: string,
  email: string
) => {
  //make a backup
  const backup = getLocalStorageItem(supportPortalLocalStorage.globals);
  setLocalStorageItem(supportPortalLocalStorage.backup, backup);

  //impersonate
  const payload = { currentUser: { token, username: email } };
  authenticatedUserService.setGlobals(payload);
};

export const impersonateUser = (tokenInfo: ITokenDto) => {
  const userToken = tokenInfo.impersonateToken || tokenInfo.token;
  switchToImpersonatedCredentials(userToken, tokenInfo.userName);
};

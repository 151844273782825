import { ApiRoutes } from '../../constants/routesApi';
import { fetchData } from '../../helpers/apis/apiRequest';
import { IActivTrakConfig, IApiDomainStore } from './apiDomainStore.models';
import { IRegionUrls } from '../../models/IAppLoad';
import { startsWith } from 'lodash';

export const buildWss = (url) => {
  if (!startsWith(url, 'wss://')) {
    url = 'wss://' + url;
  }
  return url + '/ws/client/v1';
};

export const buildHttps = (url) => {
  if (!startsWith(url, 'https://') && !startsWith(url, 'http://')) {
    return 'https://' + url;
  }
  return url;
};

export const mapUrls = (urls: IRegionUrls): Partial<IApiDomainStore> => {
  return {
    frontendApi: buildHttps(urls.apiURL),
    identityService: buildHttps(urls.identityServiceUrl),
    reportingService: buildHttps(urls.reportingServiceUrl),
    websocket: buildWss(urls.wsURL),
    websocketHttps: buildHttps(urls.wsURL),
    adminApi: buildHttps(urls.webApiUrl)
  };
};

export const fetchUrls = async (): Promise<Partial<IApiDomainStore>> => {
  const urls = await fetchData<IRegionUrls>({
    path: ApiRoutes.account.urls
  });

  return mapUrls(urls);
};

//activTrakConfig

const defaultUrl = `https://${
  import.meta.env.VITE_NAME
}-frontendv2-gcp-us.activtrak.com`;

export const activTrakConfig: IActivTrakConfig = {
  name: import.meta.env.VITE_NAME,

  // TODO: remove
  defaultUrl: defaultUrl,

  apiUrl: import.meta.env.VITE_API_URL,

  authorizationServiceUrl:
    import.meta.env.VITE_AUTHORIZATION_SERVICE_URL ?? defaultUrl,
  ssoAuthorizationUrl: import.meta.env.VITE_SSO_AUTHORIZATION_URL ?? defaultUrl,
  reportingServiceUrl: import.meta.env.VITE_REPORTING_SERVICE_URL,
  accountsApiUrl: import.meta.env.VITE_ACCOUNTS_API_URL ?? defaultUrl,
  adminApiUrl: import.meta.env.VITE_ADMIN_API_URL ?? defaultUrl,

  identityServiceUrl: import.meta.env.VITE_IDENTITY_SERVICE_URL ?? defaultUrl,

  websocketUrl: import.meta.env.VITE_WEBSOCKET_URL,
  signUpAppUrl: import.meta.env.VITE_SIGNUP_URL,
  lookerUrl: import.meta.env.VITE_LOOKER_URL,

  disableRegionRedirect:
    import.meta.env.VITE_DISABLE_REGION_REDIRECT === 'true',
  gtmAuth: import.meta.env.VITE_GTM_AUTH,

  marketo: {
    munchkinId: import.meta.env.VITE_MARKETO_MUNCHKIN_ID,
    formUrl: import.meta.env.VITE_MARKETO_FORM_URL,
    dataConnect: {
      demoRequestFormId: Number(
        import.meta.env.VITE_MARKETO_DATA_CONNECT_DEMO_REQUEST_FORM_ID
      ),
      quoteRequestFormId: Number(
        import.meta.env.VITE_MARKETO_DATA_CONNECT_QUOTE_REQUEST_FORM_ID
      )
    },
    insights: {
      demoRequestFormId: Number(
        import.meta.env.VITE_MARKETO_INSIGHTS_DEMO_REQUEST_REQUEST_FORM_ID
      ),
      quoteRequestFormId: Number(
        import.meta.env.VITE_MARKETO_INSIGHTS_QUOTE_REQUEST_FORM_ID
      ),
      trialRequestFormId: Number(
        import.meta.env.VITE_MARKETO_INSIGHTS_TRIAL_REQUEST_FORM_ID
      )
    },
    teamPulse: Number(import.meta.env.VITE_MARKETO_TEAM_PULSE),
    coaching: {
      demoRequestFormId: Number(
        import.meta.env.VITE_MARKETO_COACHING_DEMO_REQUEST_FORM_ID
      ),
      trialRequestFormId: Number(
        import.meta.env.VITE_MARKETO_COACHING_TRIAL_REQUEST_FORM_ID
      )
    },
    impact: {
      demoRequestFormId: Number(
        import.meta.env.VITE_MARKETO_IMPACT_DEMO_REQUEST_FORM_ID
      ),
      trialRequestFormId: Number(
        import.meta.env.VITE_MARKETO_IMPACT_TRIAL_REQUEST_FORM_UD
      )
    },
    workingHoursReport: {
      location: Number(
        import.meta.env.VITE_MARKETO_WORKING_HOURS_REPORT_LOCATION
      ),
      offlineMeetings: Number(
        import.meta.env.VITE_MARKETO_WORKING_HOURS_REPORT_OFFLINE_MEETINGS
      )
    }
  },

  datadog: {
    token: import.meta.env.VITE_DATADOG_TOKEN,
    site: import.meta.env.VITE_DATADOG_SITE,
    environment: import.meta.env.VITE_DATADOG_ENVIRONMENT,
    service: import.meta.env.VITE_DATADOG_SERVICE,
    logLevel: import.meta.env.VITE_DATADOG_LOG_LEVEL
  }
};

import authorizationService from '../../helpers/authorization';
import {
  getTest,
  triggerDataLayerEvent
} from '../../../../../_app/serviceFunctions/abTestService.js';
import { groupsStore } from '../../hooks';
import { setAccountSettings } from '../../stores/accountSettingsStore/accountSettingsStore';
import { filterInitialAccountSettings } from '../../stores/accountSettingsStore/accountSettingsStore.utils';
import { profileStore } from '../Profile/useProfileState';
import {
  setUserBundleFlags,
  setUserFeatureFlags
} from '../../stores/userPermissionsStore/userPermissionStore';
import { initializeReportFilters } from '../../components/ReportFilters/hooks/reportFiltersStore';
import { FeatureFlag } from '../../enums/FeatureFlag';
import { getAllEntities } from '../../stores/entityStore/entityStore';
import { invalidateEntitiesCache } from '../../stores/entityStore/entityStore';
import { setPrivacySettings } from '../../hooks/privacySettingsStore';
import { mapPrivacySettingsDtoToPrivacySettings } from '../../utils/privacySettingsStore.utils';
import { isAccountPlanInTrial } from '../../stores/accountPlanStore';
import { initializeExportStore } from '../../stores/exportsStore/exportStore.utils';
import { initializeStoreCacheHandler } from '../../stores/cacheStore/cacheStore.utils';
import { postLoginStore } from '../../stores/postLoginStore';
import { newFlexUsageEvent } from '../../analytics/flexUsageEvent';
import { fetchDateSettings } from '../../stores/dateSettingsStore';
import { fetchScreenshotSettings } from '../../stores/screenshotSettingsStore';
import { fetchScreenshotRedactionSettings } from '../../stores/screenshotSettingsStore/screenshotRedactionSettingsStore';
import { fetchRealtimeSettings } from '../../stores/realtimeSettingsStore/realtimeSettingsStore';
import { fetchWebsocketSettings } from '../../stores/websocketSettingsStore/websocketSettingsStore';
import { fetchScreenshotSafeSearchSettings } from '../../stores/screenshotSettingsStore/screenshotSafeSearchSettingsStore';
import { fetchLiveReportSettings } from '../../stores/liveReportsSettingsStore/liveReportSettingsStore';
import { fetchApiDomains } from '../../stores/apiDomainStore/apiDomainStore';

export const LoadApplication = async () => {
  await fetchApiDomains();
  const accountSettings = await postLoginStore.getState().fetch();

  await Promise.allSettled([
    initializeStoreCacheHandler(),
    groupsStore.getState().fetchFilters(),
    getAllEntities(),
    profileStore.getState().fetch()
  ]);

  const filteredSettings = filterInitialAccountSettings(accountSettings);

  setUserBundleFlags(filteredSettings.bundleFlags);
  setUserFeatureFlags(filteredSettings.featureFlags);
  setAccountSettings(filteredSettings);

  const privacySettings = mapPrivacySettingsDtoToPrivacySettings(
    filteredSettings?.privacySettings
  );
  setPrivacySettings(privacySettings);

  // Pre-fetch Insights groups to map against groupId
  // Depends on account settings and flags being set
  if (authorizationService.hasFeature(FeatureFlag.InsightsAvailable)) {
    try {
      await groupsStore.getState().fetchInsightsFilters();
    } catch (error) {
      console.error('ActivTrak Error: unable to load Insights groups', error);
    }
  }

  // Depends on group store being initialized: groupFilters, entities, and insightsFilters being fetched
  initializeReportFilters();
  invalidateEntitiesCache();

  // TODO: Determine if we need to await these
  await Promise.allSettled([
    initializeExportStore(),
    fetchDateSettings(),
    fetchScreenshotSettings(),
    fetchRealtimeSettings(),
    fetchWebsocketSettings(),
    fetchScreenshotRedactionSettings(),
    fetchScreenshotSafeSearchSettings(),
    fetchLiveReportSettings()
  ]);

  if (isAccountPlanInTrial()) {
    if (getTest('liveReportNavigationBVariant') === undefined) {
      triggerDataLayerEvent('optimize.activate.liveReportNavigation');
    }
  }

  // Depends on account settings being set
  newFlexUsageEvent(filteredSettings);

  return {
    accountSettings
  };
};

export default {
  LoadApplication
};

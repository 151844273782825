import React from 'react';
import InternalLink from '../../../common/components/Link';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export const LocationTooltipText = () => (
  <Typography>
    Location data is available historically and not available for today's
    activity.{' '}
    <Link
      href="https://support.activtrak.com/hc/en-us/articles/8298409034267"
      target="_blank"
      rel="noreferrer"
    >
      Learn more
    </Link>
  </Typography>
);

export const OfflineMeetingsTooltipText = (props: {
  isIntegratedCalendarEnabled: boolean;
}) => {
  const { isIntegratedCalendarEnabled } = props;

  const templateValues = {
    enabled: {
      text: 'Offline Meeting data is available historically and not live. It is not included in Productive, Total Hours, or Active Hours.',
      link: (
        <Link
          href="https://support.activtrak.com/hc/en-us/articles/14157396964123"
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </Link>
      )
    },
    disabled: {
      text: 'Offline Meeting data can take up to 24 hours to begin populating after enabling the integration.',
      link: (
        <InternalLink to="app.integrations">Enable Integration</InternalLink>
      )
    }
  };

  const templateValue = isIntegratedCalendarEnabled
    ? templateValues.enabled
    : templateValues.disabled;

  return (
    <Typography>
      {templateValue.text} {templateValue.link}
    </Typography>
  );
};

export const TotalProdAndTimeOffTooltipText = () => (
  <Typography>
    Total time includes All Productive Time throughout the day
  </Typography>
);

import angular from 'angular';

// // Start app (in WEB build)
angular.element(document).ready(function () {

  angular.bootstrap(document, ['app', 'login', 'cc']);

  if (window.location.hash === '') {
    history.pushState({}, 'init', '/#/app/home');
  }
});

import React from 'react';
import { LoaderContainer } from './atGrid.styles';
import { DefaultSkeletonLoader } from './DefaultSkeletonLoader';

export const AtGridLoader = (props) => {
  const { height, columnCount, includeCheckboxes } = props;

  return (
    <LoaderContainer sx={{ height: height || '65vh' }}>
      <DefaultSkeletonLoader
        columnCount={columnCount}
        includeCheckboxes={includeCheckboxes}
      />
    </LoaderContainer>
  );
};

import React from 'react';
import { map, sortBy } from 'lodash';
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view';
import { Box, Typography } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { generateGuid } from '../../common/utils/generateGuid';
import { IDetailsTreeViewProps } from '../models/IComponentProps';

export const DetailsTreeView = (props: IDetailsTreeViewProps) => {
  const { accountInfo } = props;

  const createTreeChildBranch = (value: any, key: string) => {
    if (value === null) {
      value = 'null';
    } else if (Array.isArray(value) && value.length === 0) {
      value = 'none';
    } else {
      value =
        typeof value.toString === 'function' ? value.toString() : 'undefined';
    }

    return (
      <TreeItem
        key={generateGuid()}
        itemId={generateGuid()}
        label={
          <Typography component="div" variant="subtitle2">
            {key}:{' '}
            <Box display="inline" fontWeight={400}>
              {value}
            </Box>
          </Typography>
        }
      />
    );
  };

  const createTreeItemNode = (data: object) => {
    return map(
      sortBy(Object.entries(data), (o) => o[0]),
      (property) => {
        if (
          Array.isArray(property[1]) ||
          typeof property[1] !== 'object' ||
          !property[1]
        ) {
          return createTreeChildBranch(property[1], property[0]);
        }
        return (
          <TreeItem
            key={generateGuid()}
            itemId={generateGuid()}
            label={property[0]}
          >
            {createTreeItemNode(property[1])}
          </TreeItem>
        );
      }
    );
  };

  return (
    <SimpleTreeView
      slots={{
        expandIcon: ChevronRight,
        collapseIcon: ExpandMore
      }}
    >
      {createTreeItemNode(accountInfo)}
    </SimpleTreeView>
  );
};

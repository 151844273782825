import React from 'react';
import { useReportViewStore } from '../hooks/useReportViewToggleStore';
import { setReportView } from '../stores/reportViewStore';
import { ReportViewTypes } from '../constants/reportViewToggle.constants';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

type ReportViewToggleProps = {
  onChange: (view: ReportViewTypes) => void;
};

export const ReportViewToggle = (props: ReportViewToggleProps) => {
  const { onChange } = props;

  const view = useReportViewStore((s) => s.view);

  const handleToggleChange = (
    e: React.MouseEvent<HTMLElement>,
    value: ReportViewTypes
  ) => {
    if (value !== null) {
      setReportView(value);
      onChange(value);
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={view}
      exclusive
      onChange={handleToggleChange}
      aria-label="Report View"
      sx={{ mr: 2 }}
    >
      <ToggleButton
        value={ReportViewTypes.SUMMARY}
        id="8c0e6e06-3843-4226-8a72-f613d05f01a7-summary"
      >
        Summary
      </ToggleButton>
      <ToggleButton
        value={ReportViewTypes.DETAILED}
        id="8c0e6e06-3843-4226-8a72-f613d05f01a7-detailed"
      >
        Detailed
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

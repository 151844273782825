import React, { useState } from 'react';
import { getAccountSetting } from '../../../common/stores/accountSettingsStore/accountSettingsStore';
import { AgentActivationModal } from '../../../dashboard/components/AgentActivationModal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const NoReportData = () => {
  const [open, setOpen] = useState<boolean>(false);

  const usedLicenses = getAccountSetting('usedLicenses');

  return (
    <Box
      sx={{
        height: '65vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Typography component="h5">No Results</Typography>
      <Typography sx={{ fontSize: '16px' }}>
        Application data was not available to generate this report
      </Typography>
      {usedLicenses === 0 && (
        <>
          <Box>
            <Button
              sx={{ padding: `0 3px 0 0 !important` }}
              onClick={() => setOpen(true)}
            >
              Download the Agent
            </Button>{' '}
            and install on a target computer to begin collecting data
          </Box>
          <AgentActivationModal open={open} onClose={() => setOpen(false)} />
        </>
      )}
    </Box>
  );
};

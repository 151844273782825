angular.module('app').controller('AppLoadingCtrl', AppLoadingCtrl);

AppLoadingCtrl.$inject = ['$state', '$window', 'authorizationService', 'routesService', 'activTrakAcademyService'];

// App Loading Page was added to ensure app settings are fully loaded before any other checks are made.
// This was needed for routing to restricted pages and needing the role access info to be in place.
function AppLoadingCtrl($state, $window, authorizationService, routesService, activTrakAcademyService) {
    const toState = $state.params.toState;
    const toParams = $state.params.toParams;

    const next = $state.params.next;
    let stateName = toState ? toState.name : null;
    let stateParams = toParams ? toParams : null;

    const queryStringToObject = function (queryString) {
        if (!queryString) return {};
        const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
        const result = {};
        pairs.forEach(function (pair) {
            const parts = pair.split('=');
            const key = decodeURIComponent(parts[0]);
            const value = decodeURIComponent(parts[1] || '');
            if (result[key] !== undefined) {
                if (!Array.isArray(result[key])) {
                    result[key] = [result[key]];
                }
                result[key].push(value);
            } else {
                result[key] = value;
            }
        });
        return result;
    };

    // If CC Role, redirect to CC
    // Deep linking is not allowed for CC due to multiple account access
    if (authorizationService.hasRole(['ccaccess', 'ccenterpriseaccess'])) {
        $window.location.href = '/#/cc/dashboard'; // TODO: Convert to app.cc once CC routes are added to the same router
        return;
    } else if (next && next !== '/') {
        const route = routesService.getRouteByUrl(next);
        if (route && (authorizationService.hasRouteByName(route.name) || route.name === 'app.help.academy')) {
            const nextParts = next.split('?');
            stateName = route.name;
            stateParams = queryStringToObject(nextParts[1]);
        }
    }

    if (stateName === 'app.help.academy') {
        activTrakAcademyService.redirectToAcademy(stateParams, true);
    } else if (stateName && stateName !== 'app.loading' && stateName !== 'app') {
        $state.go(stateName, stateParams);
    } else {
        authorizationService.redirectToAuthorizedHome($state);
    }
}

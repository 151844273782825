import { stringify } from '../../common/utils/parse/parse';
import { Role } from '../../common/enums';
import { IAccountSettingsDto, IStorageDto } from '../../common/models';
import { IPrivacySettingsDto } from '../../common/models/IPrivacySettings';
import { IAccountSnapshotDto, ITokenDto } from '../models/IApiResponseDto';
import { IAccountPlan } from '../models/IFetchedDetails';

export const isValidTokenDto = (tokenData: ITokenDto): boolean => {
  const isValid =
    typeof tokenData === 'object' &&
    tokenData !== null &&
    typeof tokenData.ccAccess === 'boolean' &&
    (typeof tokenData.impersonateToken === 'string' ||
      tokenData.impersonateToken === null) &&
    typeof tokenData.token === 'string' &&
    typeof tokenData.userName === 'string';

  return isValid;
};

export const isValidAccountCredentialsDto = (
  accountData: IAccountSettingsDto
): boolean => {
  const isValid =
    typeof accountData === 'object' &&
    accountData !== null &&
    typeof accountData.account === 'string' &&
    typeof accountData.companyName === 'string' &&
    typeof accountData.usedLicenses === 'number';

  return isValid;
};

export const isValidAccountSnapshotDto = (
  snapshotData: IAccountSnapshotDto,
  storageData: IStorageDto
): boolean => {
  const isValid =
    typeof snapshotData === 'object' &&
    snapshotData !== null &&
    typeof snapshotData.creationDate === 'string' &&
    typeof snapshotData.dataCollection === 'boolean' &&
    typeof snapshotData.db === 'string' &&
    (typeof snapshotData.lastActivityDate === 'string' ||
      snapshotData.lastActivityDate === null) &&
    (typeof snapshotData.lastLoginDate === 'string' ||
      snapshotData.lastLoginDate === null) &&
    typeof snapshotData.licenseCount === 'number' &&
    (typeof snapshotData.maintenance === 'boolean' ||
      snapshotData.maintenance === null) &&
    typeof snapshotData.planCode === 'string' &&
    typeof snapshotData.planPeriod === 'string' &&
    typeof snapshotData.planType === 'string' &&
    typeof storageData.storage === 'number';

  return isValid;
};

const isRole = (value: any): value is Role => {
  return Object.values(Role).includes(value.toLowerCase());
};

const isArrayOfRoles = (array: any[]): array is Role[] => {
  return Array.isArray(array) && array.every(isRole);
};

const isValidSubscriptionTypeDto = (data: IAccountPlan): boolean => {
  const isValid =
    typeof data === 'object' &&
    data !== null &&
    typeof data?.code === 'string' &&
    typeof data?.type === 'string' &&
    typeof data?.period === 'string' &&
    typeof data?.isTrial === 'boolean';

  return isValid;
};

const isValidPrivacySettingsDto = (data: IPrivacySettingsDto): boolean => {
  const isValid =
    typeof data === 'object' &&
    data !== null &&
    typeof data.screenshots?.enabled === 'boolean' &&
    typeof data.activity?.enabled === 'boolean' &&
    typeof data.alarms?.enabled === 'boolean';

  return isValid;
};

export const isValidBaseAccountSettingsDto = (
  data: Partial<IAccountSettingsDto>
): boolean => {
  const isValid =
    typeof data === 'object' &&
    data !== null &&
    // ACCOUNT SETTINGS CHECK
    typeof stringify(data.agentDownloadSettings) === 'string' &&
    typeof data.autoUpdate === 'boolean' &&
    typeof data.dataCollection === 'boolean' &&
    typeof data.macAutoupdate === 'boolean' &&
    typeof data.settings.dataHash === 'boolean' &&
    typeof stringify(data.defaultGroup) === 'string' &&
    typeof data.delayedDelete === 'boolean' &&
    typeof stringify(data.filterSettings) === 'string' &&
    typeof data.flexUsageEnabled === 'boolean' &&
    typeof data.flexUsageExpirationDate === 'string' &&
    typeof data.settings.insightsAvailable === 'boolean' &&
    typeof stringify(data.insightsSetting) === 'string' &&
    typeof data.isDataConsoleEnabled === 'boolean' &&
    typeof data.landingPage === 'string' &&
    typeof data.maintenanceMode === 'boolean' &&
    typeof data.redactionFeatureFlag === 'boolean' &&
    Array.isArray(data.roleDateFilters) &&
    typeof data.safeSearchFeatureFlag === 'boolean' &&
    typeof data.showTeamPulseScreenViews === 'boolean' &&
    typeof data.userMergeEnabled === 'boolean' &&
    typeof data.videoAlarmsActive === 'number' &&
    typeof data.videoAlarmsFeatureFlag === 'boolean' &&
    typeof data.videoAlarmsLimit === 'number' &&
    typeof stringify(data.viewableGroupsCount) === 'string' &&
    isValidPrivacySettingsDto(data.privacySettings) &&
    // DATE TIME SETTINGS CHECK
    typeof data.dateFormat === 'string' &&
    typeof data.timeFormat === 'string' &&
    typeof data.unixCreationTime === 'number' &&
    typeof data.currentTimeZone === 'string' &&
    typeof data.currentIanaTimeZone === 'string' &&
    // ACCOUNT CREATOR CHECK
    typeof data.firstName === 'string' &&
    typeof data.lastName === 'string' &&
    typeof data.creator === 'string' &&
    // ACCOUNT USER CHECK
    typeof data?.user?.username === 'string' &&
    isArrayOfRoles(data?.user?.roles) &&
    Array.isArray(data?.roleAccess) &&
    // ACCOUNT DETAILS PREVIEW CHECK
    typeof data.account === 'string' &&
    (typeof data.parentAccountId === 'string' ||
      data.parentAccountId === null) &&
    typeof data.isVerified === 'boolean' &&
    typeof data.phone === 'string' &&
    typeof data.databaseName === 'string' &&
    typeof data.maxStorage === 'number' &&
    typeof data.overLimitOrOverStorage === 'boolean' &&
    typeof data.msaAcceptanceRequired === 'boolean' &&
    // ACCOUNT SUBSCRIPTION CHECK
    isValidSubscriptionTypeDto(data?.subscriptions?.current) &&
    isValidSubscriptionTypeDto(data?.subscriptions?.previous) &&
    typeof data?.isSubscriptionCancelled === 'boolean' &&
    typeof data?.state === 'string' &&
    typeof data?.subscriptionType === 'string' &&
    typeof data?.hasFailedPayment === 'boolean' &&
    // BUNDLE FLAGS CHECK
    Object.values(data.bundleFlags).every(
      (value) => typeof value === 'boolean'
    );

  return isValid;
};

export const isValidFullDetailsForModal = (
  accountSettings: IAccountSettingsDto,
  accountSnapshot: IAccountSnapshotDto,
  storageData: IStorageDto
): boolean => {
  const isValid =
    isValidAccountSnapshotDto(accountSnapshot, storageData) &&
    isValidBaseAccountSettingsDto(accountSettings);

  return isValid;
};

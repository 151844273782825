import { includes } from 'lodash';

const mailFormat =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const isValidEmail = (email: string) => email.match(mailFormat);

export const digitOnlyRegex = (config?: 'phoneNumber') => {
  return config === 'phoneNumber' ? /^[0-9+]*$/ : /\d/;
};
export const passwordHasUpperCaseRegex = /[A-Z]/;
export const passwordHasSpecialCharRegex = /[^A-Za-z0-9]/;
export const spacesRegex = / /g;
export const unicodeWhitespaceRegex = /\s/;
export const trimSpaces = (value: string) => value.replace(spacesRegex, '');
export const checkInvalidPassword = (
  psw: string,
  securityLevel: 'advancedSecurity' | null
) => {
  const requiredLength = (length: number) => psw.length < length;
  if (securityLevel) {
    return (
      requiredLength(16) ||
      !digitOnlyRegex().test(psw) ||
      !passwordHasUpperCaseRegex.test(psw) ||
      !passwordHasSpecialCharRegex.test(psw) ||
      unicodeWhitespaceRegex.test(psw)
    );
  } else {
    return (
      requiredLength(8) ||
      !passwordHasUpperCaseRegex.test(psw) ||
      !passwordHasSpecialCharRegex.test(psw) ||
      unicodeWhitespaceRegex.test(psw)
    );
  }
};

export const checkArrayIncludes = (array: Array<any>, value) => {
  if (includes(array, value)) {
    return value;
  }
};

const acctIdFormat = /^\b\d{6}\b/;

export const isValidAcctId = (acctId: string) => acctId.match(acctIdFormat);

export const validDomain = (domain: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const domainRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/;
  return domainRegex.test(domain);
};

export const validGuid = (guid: string): boolean => {
  const guidRegex =
    /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;
  return guidRegex.test(guid);
};

import { Role } from '../../../../../enums';
import { getAccountSettings } from '../../../../../stores/accountSettingsStore/accountSettingsStore';
import { IRoute } from '../../../../../models/IRoute';
import { accessTemplate as angularJsTemplate } from '../../templates';

export const appAccountAccess: IRoute = {
  name: 'app.account.access',
  stateDefinition: {
    url: '/account/access?newmember',
    template: angularJsTemplate,
    controller: 'SettingsAccessCtrl',
    data: { pageTitle: 'App Access' },
    resolve: {
      asyncPayload: [
        'accessApiService',
        function (accessApiService) {
          return new Promise(function (resolve, reject) {
            const { account } = getAccountSettings();
            const all = Promise.all([
              accessApiService.getSsoEligible(account),
              accessApiService.getGroups(),
              accessApiService.getAccounts(),
              accessApiService.getSsoEnabled(account)
            ]);

            all
              .then(function (result) {
                const settings = {
                  ssoEligible: result[0].data,
                  groups: result[1].data,
                  accounts: result[2].data,
                  ssoEnabled: result[3].data
                };

                resolve(settings);
              })
              .catch(function (e) {
                console.error(
                  `ActivTrak Error: Error loading app access resolve.\\n\\n${e}`
                );
                reject(e);
              });
          });
        }
      ]
    }
  },
  access: {
    redirectTo: 'app.dashboard',
    edit: [Role.Admin, Role.SuperAdmin, Role.SupportAdvanced],
    read: [Role.SupportBasic]
  }
};

export default appAccountAccess;

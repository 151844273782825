import viewTemplate from 'views/widgets/integration.html?raw';
import integrateResourceModalTemplate from 'views/modals/integrateResourceModal.html?raw';
import loadingGif from 'images/rolling.gif';
import { formatDateByTimezone } from '../../../_reactivtrak/src/common/utils/datetime/datetimeFormatters';
import { analyticEventServiceFunctions } from '../../../_app/serviceFunctions/analyticEventServiceFunctions';
import { INSIGHTS_UPGRADE_PLAN_LABEL as highestPlan } from '../../../_reactivtrak/src/common/constants/plans';

var contactSupportHTML =
    '<a href="https://www.activtrak.com/support" target="_blank" rel="noreferrer">contact support</a>';

angular.module('app').directive('integration', integration);

integration.$inject = [];

function integration() {
    return {
        restrict: 'E',
        template: viewTemplate,
        scope: {
            integration: '=',
            userIdInfo: '=',
            reloadSolutions: '='
        },
        controller: [
            '$scope',
            '$window',
            'integrationsService',
            'trayConfigWizard',
            '$q',
            'notificationService',
            'customUibModal',
            'messagesService',
            '$document',
            'authorizationService',
            function (
                $scope,
                $window,
                integrationsService,
                trayConfigWizard,
                $q,
                notificationService,
                customUibModal,
                messagesService,
                $document,
                authorizationService
            ) {
                $document.on('click', function (event) {
                    if (!event.target.closest('.integrations-panel') && $scope.isMenuActive) {
                        $scope.$apply(function () {
                            $scope.isMenuActive = false;
                        });
                    }
                });

                $scope.integrationsTags = {
                    salesforce: 'uid_sfdc',
                    salesforceLeads: 'uid_sfdclds',
                    salesforceOpportunities: 'uid_sfdcopp',
                    azureAD: 'uid_azad',
                    googleCalendar: 'uid_gcal',
                    outlookCalendar: 'uid_ocal',
                    hrdc: 'uid_hrdc'
                };

                $scope.nonScheduledIntegrations = {
                    [$scope.integrationsTags.salesforceOpportunities]: 'uid_sfdcopp',
                    [$scope.integrationsTags.salesforceLeads]: 'uid_sfdclds',
                    [$scope.integrationsTags.salesforce]: 'uid_sfdc',
                    [$scope.integrationsTags.hrdc]: 'uid_hrdc'
                };

                $scope.loading = loadingGif;
                $scope.isPaidPlan = authorizationService.hasFeature('isPaidFunctionalityEnabled');

                $scope.analyticEventServiceFunctions = analyticEventServiceFunctions;

                $scope.badgeLabel = function () {
                    return (
                        $scope.integration.badge ||
                        (!$scope.integration.isPlanAccessible && !$scope.integration.isBeta ? highestPlan : undefined)
                    );
                };

                $scope.badgeId = function () {
                    var integrationCode = $scope.integration && $scope.integration.integrationCode;
                    var badgeIds = {
                        [$scope.integrationsTags.salesforceLeads]: 'ee376-5ca0-11ee-8c99-0242ac120002',
                        [$scope.integrationsTags.salesforceOpportunities]: 'd862d8-5ca0-11ee-8c99-0242ac120002',
                        [$scope.integrationsTags.azureAD]: 'be6690-5ca0-11ee-8c99-0242ac120002',
                        [$scope.integrationsTags.googleCalendar]: 'a8f0de4a-5ca0-11ee-8c99-0242ac120002',
                        [$scope.integrationsTags.outlookCalendar]: 'aef42aa4-5ca0-11ee-8c99-0242ac120002',
                        [$scope.integrationsTags.hrdc]: 'b478ee10-5ca0-11ee-8c99-0242ac120002'
                    };

                    return badgeIds[integrationCode] || '';
                };

                $scope.waitingPeriodFlag = function () {
                    var waitPeriod = 60 * 60 * 24 * 1000;
                    return (
                        $scope.integration.solutionInstance &&
                        Date.now() - Date.parse($scope.integration.solutionInstance.created) < waitPeriod
                    );
                };

                $scope.badgeLabel = function () {
                    return (
                        $scope.integration.badge ||
                        (!$scope.integration.isPlanAccessible && !$scope.integration.isBeta ? highestPlan : undefined)
                    );
                };

                $scope.emailActivTrak = '<a href="mailto:support@activtrak.com">ActivTrak Support.</a> ';

                $scope.errorCodes = {
                    Default: ' Something went wrong. Please contact ' + $scope.emailActivTrak,
                    1000:
                        ' Data was not received because credentials to the data provider were invalid. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    2000:
                        ' An error occurred while retrieving the user from the source of record. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    2100:
                        ' An error occurred while creating the user from the source of record. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    3000:
                        ' An error occurred while deleting the integration. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    3100:
                        ' An error occurred while enabling the integration. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    3200:
                        ' An error occurred while disabling the integration. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    3300:
                        ' An error occurred while creating the integration. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    3400:
                        ' An error occurred while retrieving the integration information from the source of record. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    3500:
                        ' An error occurred while retrieving the list of integrations information from the source of record. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    4000:
                        ' An error occurred while retrieving the account from the source of record. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    5000:
                        ' An error occurred during the Azure AD integration. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    5002:
                        ' An error occurred while retrieving your Google calendar data. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    5003:
                        ' An error occurred while retrieving your Outlook calendar data. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    5004: ' If the problem persists, please contact ' + $scope.emailActivTrak,
                    5005:
                        ' An error occurred while retrieving your calendar data. Token has expired during calendar retrieval. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    5006: ' Data was not received. Please verify credentials and assigned role, then delete and re-enable the integration. ',
                    6444:
                        ' Integration has not fetched for more than 48 hours. Please contact ' + $scope.emailActivTrak,
                    7000:
                        ' An error occurred while creating a token for the user. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    8000:
                        ' An error occurred while authorizing the configuration. If the problem persists, please contact ' +
                        $scope.emailActivTrak,
                    8200: ' Data was not received because top level group name was invalid or not provided. Delete, correct the configuration, and re-enable the integration.',
                    8100: ' Data was not received because a required field was missing. Resend the CSV.',
                    9000: ' Something went wrong. Please contact ' + $scope.emailActivTrak,
                    9100:
                        ' Integration has not successfully initiated. If the problem persists, please contact ' +
                        $scope.emailActivTrak
                };

                $scope.message = function () {
                    if (!$scope.integration.integrationState) {
                        return '';
                    }
                    switch ($scope.integration.integrationState.stateType) {
                        case 32:
                            if ($scope.waitingPeriodFlag()) {
                                return 'Your data is being processed. This may take up to 24 hours to complete.';
                            }

                            var updatedTime = $scope.integration.integrationState.updatedtime;
                            if (updatedTime) {
                                if (!$scope.nonScheduledIntegrations[$scope.integration.integrationCode]) {
                                    return `Last data received: ${formatDateByTimezone(updatedTime)}`;
                                }
                            }

                            return '';

                        case 4:
                            if ($scope.errorCodes[$scope.integration.integrationState.errorcode]) {
                                return 'Data was not received because the service account credentials were invalid. Please verify credentials, then delete and re-enable the integration.';
                            }
                            return 'An error occurred while configuring the integration, possibly due to pop-ups being blocked. Please allow pop-ups, then delete and re-enable the integration.';

                        case 2:
                            if ($scope.errorCodes[$scope.integration.integrationState.errorcode] === undefined) {
                                return $scope.errorCodes.Default;
                            }
                            return $scope.errorCodes[$scope.integration.integrationState.errorcode];

                        case 64:
                            if ($scope.errorCodes[$scope.integration.integrationState.errorcode] === undefined) {
                                return $scope.errorCodes.Default;
                            }
                            return (
                                $scope.errorCodes[$scope.integration.integrationState.errorcode] +
                                $scope.integration.integrationState.statemessage
                            );

                        case 8:
                            return '';
                        case 1:
                            return '';
                        default:
                            return 'Your data is being retrieved. This may take up to an hour to complete.';
                    }
                };

                $scope.checkIfDeprecated = function () {
                    var deprecatedCodes = [];
                    return deprecatedCodes.includes($scope.integration.integrationCode);
                };

                $scope.isDeprecated = $scope.checkIfDeprecated();

                $scope.deprecationLink = function () {
                    var link = '';
                    return link;
                };

                $scope.showModal = function () {
                    if (!$scope.isDeprecated) {
                        customUibModal
                            .open({
                                animation: false,
                                template: integrateResourceModalTemplate,
                                controller: 'integrateResourceModalController',
                                windowClass: 'centered-modal centered-modal-800px',
                                resolve: {
                                    modalData: {
                                        integration: $scope.integration,
                                        integrationsTags: $scope.integrationsTags
                                    }
                                }
                            })
                            .result.then(
                                function () {},
                                function () {}
                            );
                    }
                };

                $scope.toggleMenu = function (event) {
                    event.stopPropagation();
                    $scope.isMenuActive = !$scope.isMenuActive;
                };

                $scope.openBetaUrl = function (event) {
                    event.stopPropagation();
                    $window.open($scope.integration.betaSignupUrl, '_blank');
                };

                $scope.navigateToUpgradeUrl = function (event) {
                    event.stopPropagation();
                    $window.open('/#/app/account/upgrade', '_self');
                };

                //pop tray.io window
                $scope.integrate = function (event) {
                    event.stopPropagation();

                    $scope.analyticEventServiceFunctions.newEvent('Start to Configure', {
                        title: $scope.integration.title
                    });
                    if ($scope.integration.solutionInstance.instance) {
                        enableSolution(
                            $scope.integration.solutionInstance.instance,
                            $scope.integration.integrationCode,
                            true
                        );
                    } else {
                        var config = $scope.integration.solutionInstance.configSlots.find(function (x) {
                            return x.title === 'at-account-id' || x.title === 'at_account_id';
                        });
                        var configId = (config && config.externalId) || '';

                        integrationsService
                            .getConfigWizard(
                                $scope.userIdInfo.userId,
                                $scope.integration.solutionInstance.id,
                                `${$scope.userIdInfo.companyName} ${$scope.integration.title}`,
                                $scope.integration.integrationCode,
                                configId,
                                $scope.integration.title
                            )
                            .then(
                                function (result) {
                                    var url = `https://embedded.tray.io/external/solutions/ActivTrakIntegration/configure/${result.solutionInstanceId}?code=${result.authorizationCode}`;
                                    var configWindow = trayConfigWizard.openConfigWizard(url);

                                    var CHECK_TIMEOUT = 1000;
                                    function checkWindow() {
                                        if (!configWindow) {
                                            notificationService.showNotification(
                                                `An error occurred, possibly due to pop-ups being blocked. Please delete configuration and try again.`,
                                                'danger',
                                                3000,
                                                true
                                            );
                                            $scope.analyticEventServiceFunctions.newEvent('Configuration Failed', {
                                                title: $scope.integration.title
                                            });

                                            setTimeout(function () {
                                                $window.location.reload();
                                            }, 2500);
                                        } else if (configWindow && configWindow.closed) {
                                            if (trayConfigWizard.trayConfigState() == 'complete') {
                                                notificationService.showNotification(
                                                    'Integration successfully initiated.',
                                                    'success'
                                                );
                                                enableSolution(
                                                    result.solutionInstanceId,
                                                    $scope.integration.integrationCode,
                                                    false
                                                );
                                                $scope.analyticEventServiceFunctions.newEvent('Integration Success', {
                                                    title: $scope.integration.title
                                                });
                                            } else {
                                                if (trayConfigWizard.trayConfigError()) {
                                                    notificationService.showNotification(
                                                        `Integration was not successfully enabled. Please ${contactSupportHTML} for assistance.`,
                                                        'danger',
                                                        3000,
                                                        true
                                                    );
                                                    $scope.analyticEventServiceFunctions.newEvent(
                                                        'Configuration Failed',
                                                        { title: $scope.integration.title }
                                                    );
                                                } else {
                                                    notificationService.showNotification(
                                                        'Integration setup cancelled.',
                                                        'success'
                                                    );
                                                    $scope.analyticEventServiceFunctions.newEvent(
                                                        'Integration Incomplete',
                                                        { title: $scope.integration.title }
                                                    );
                                                }
                                                deleteSolution(
                                                    result.solutionInstanceId,
                                                    $scope.integration.integrationCode
                                                );
                                            }
                                        } else {
                                            setTimeout(checkWindow, CHECK_TIMEOUT);
                                        }
                                    }
                                    checkWindow();
                                },
                                function (result) {
                                    if (
                                        result.data.messages.some(function (m) {
                                            return m.includes('Permission checks failed');
                                        })
                                    ) {
                                        notificationService.showNotification(
                                            'Your subscription plan is not eligible for this integration. Please <a href="mailto:sales@activtrak.com">contact sales</a> about access.',
                                            'warning',
                                            10000,
                                            true
                                        );
                                    } else {
                                        notificationService.showNotification(
                                            `Integration was not successfully enabled. Please ${contactSupportHTML} for assistance.`,
                                            'danger',
                                            3000,
                                            true
                                        );
                                    }
                                    return $q.reject(result);
                                }
                            );
                    }
                };

                $scope.disable = function (event) {
                    event.stopPropagation();
                    integrationsService
                        .disableSolution(
                            $scope.integration.solutionInstance.instance,
                            $scope.integration.integrationCode
                        )
                        .then(
                            function () {
                                $scope.reloadSolutions();
                                notificationService.showNotification('Integration successfully paused.', 'success');
                                $scope.analyticEventServiceFunctions.newEvent('Integration Disabled', {
                                    title: $scope.integration.title
                                });
                                $scope.integration.integrationState.state = 'Paused';
                                $scope.integration.integrationState.stateType = 8;
                            },
                            function (result) {
                                notificationService.showNotification(
                                    `Integration was not successfully disabled. Please ${contactSupportHTML} for assistance.`,
                                    'danger',
                                    3000,
                                    true
                                );
                                return $q.reject(result);
                            }
                        );
                };

                $scope.delete = function (event) {
                    event.stopPropagation();
                    integrationsService
                        .deleteSolution(
                            $scope.integration.solutionInstance.instance,
                            $scope.integration.integrationCode
                        )
                        .then(
                            function () {
                                $scope.reloadSolutions();
                                notificationService.showNotification('Integration successfully deleted.', 'success');
                                $scope.analyticEventServiceFunctions.newEvent('Integration Deleted', {
                                    title: $scope.integration.title
                                });

                                $scope.integration.integrationState.state = 'Deleted';
                                $scope.integration.integrationState.stateType = 1;
                            },
                            function (result) {
                                notificationService.showNotification(
                                    `Integration was not successfully deleted. Please ${contactSupportHTML} for assistance.`,
                                    'danger',
                                    3000,
                                    true
                                );
                                return $q.reject(result);
                            }
                        );
                };

                $scope.refresh = function (event) {
                    event.stopPropagation();
                    integrationsService
                        .runSolution($scope.integration.solutionInstance.instance, $scope.integration.integrationCode)
                        .then(
                            function () {
                                $scope.reloadSolutions();
                                notificationService.showNotification('Integration successfully refreshed.', 'success');
                                $scope.analyticEventServiceFunctions.newEvent('Integration Manually Ran', {
                                    title: $scope.integration.title
                                });
                                $scope.integration.integrationState.state = 'Running';
                                $scope.integration.integrationState.stateType = 16;
                            },
                            function (result) {
                                notificationService.showNotification(
                                    `Integration was not successfully refreshed. Please ${contactSupportHTML} for assistance.`,
                                    'danger',
                                    3000,
                                    true
                                );
                                return $q.reject(result);
                            }
                        );
                };

                var deleteSolution = function (solutionInstanceId, integrationCode) {
                    integrationsService.deleteSolution(solutionInstanceId, integrationCode).then(
                        function () {
                            solutionInstanceId = null;
                        },
                        function (result) {
                            notificationService.showNotification(messagesService.get('somethingWrong'), 'danger');
                            return $q.reject(result);
                        }
                    );
                };

                var enableSolution = function (solutionInstanceId, integrationCode, showNotification) {
                    integrationsService.enableSolution(solutionInstanceId, integrationCode).then(
                        function () {
                            $scope.reloadSolutions();
                            if (showNotification) {
                                notificationService.showNotification('Integration successfully enabled.', 'success');
                            }
                            $scope.integration.integrationState.state = 'Waiting';
                            $scope.integration.integrationState.stateType = 32;
                            if ($scope.isMenuActive) $scope.isMenuActive = false;
                        },
                        function (result) {
                            notificationService.showNotification(
                                messagesService.get('somethingWrong'),
                                'danger',
                                3000,
                                true
                            );
                            return $q.reject(result);
                        }
                    );
                };
            }
        ]
    };
}

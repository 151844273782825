//https://www.figma.com/file/NnMUvvZvPtfDzzjhkoJoHT/AT-Library--v2_Q12022?node-id=726%3A519

// Colors
// Grey
export const gray0 = '#FFFFFF';
export const gray1 = '#FAFAFA';
export const gray2 = '#F5F5F5';
export const gray3 = '#EEEEEE';
export const gray4 = '#E0E0E0';
export const gray5 = '#BDBDBD';
export const gray6 = '#9E9E9E';
export const gray7 = '#444444';
export const gray8 = '#212121';
export const gray9 = '#616161';
export const gray10 = '#D9D9D9';
export const gray11 = '#626262';
export const gray12 = '#22252D';
export const gray13 = '#C4C4C4';
export const gray14 = '#E5E5E5';
export const gray15 = '#F9F9F9';
export const gray16 = '#999999';
export const gray17 = '#161616';
export const gray18 = '#666666';
export const gray19 = '#ABB2BF';
export const gray20 = '#AAB2C0';

// Blue
export const blue1 = '#2F4B7C';
export const blue2 = '#3c93ce';
export const blue3 = '#94D8FF';
export const blue4 = '#48b0f7';
export const blue5 = '#2E91A6';
export const blue6 = '#38ADAC';
export const blue7 = '#D1EBFD';
export const blue8 = '#66ACFF';
export const blue9 = '#1897EC';

// Purple
export const purple1 = '#665191';
export const purple2 = '#A05195';
export const purple3 = '#893FF2';
export const purple4 = '#C49FF9';
export const purple5 = '#D2AEF7';

// Red
export const red1 = '#D45086';
export const red2 = '#F55D6A';
export const red3 = '#FF6D6D';
export const red4 = '#DC362E';
export const red5 = '#B10B19';
export const red6 = '#F77975';
export const red7 = '#F55753';

// Orange
export const orange1 = '#F67C43';
export const orange2 = '#FFBD47';
export const orange3 = '#E46124';
export const orange4 = '#D65B22';
export const orange5 = '#F8A601';
export const orange6 = '#F2C2A7';

// Yellow
export const yellow1 = '#FFEB10';
export const yellow2 = '#FFD630';
export const yellow3 = '#edc72d';
export const yellow4 = '#FBD631';

// Green
export const green1 = '#B9D12D';
export const green2 = '#6AE08E';
export const green3 = '#00CAA8';

// Aqua
export const aqua1 = '#03DAC5';
export const aqua2 = '#81EDE2';
export const aqua3 = '#4FE5D6';
export const aqua4 = '#47C8BB';
export const aqua5 = '#EFF8F7';
export const aqua6 = '#DCF5F3';
export const aqua7 = '#41CAA8';
export const aqua8 = '#4AE598';
export const aqua9 = '#0091A6';
export const aqua10 = '#4DEFE1';
export const aqua11 = '#7FECE1';

//tokens
export const fontColor900 = '#2C2E2E';
export const fontColor800 = '#404242';
export const fontColor700 = '#626666';
export const fontColor600 = '#7A807F';
export const fontColor400 = '#BFC7C6';
export const fontColorError = '#B3261E';

// Style Guide
export const primaryAqua = aqua1;
export const secondaryAqua = aqua2;
export const primaryPurple = purple3;
export const secondaryPurple = purple4;
export const primaryBlue = blue2;
export const secondaryBlue = blue3;
export const primaryOrange = orange3;
export const secondaryOrange = orange4;
export const primaryYellow = yellow2;
export const primaryRed = red3;

export const notifSuccess = primaryAqua;
export const notifError = primaryRed;
export const notifWarn = primaryYellow;
export const notifInfo = primaryBlue;

export const statusColors = {
  none: primaryAqua,
  maintenance: primaryBlue,
  minor: primaryYellow,
  major: primaryRed,
  critical: primaryRed
};

export const primaryColor = fontColor800;
export const headerColor = fontColor900;
export const linkHoverColor = blue4;
export const buttonTextColor = fontColor900;
export const disabledTextColor = fontColor400;

//font-family
export const primaryFontFamily =
  'Roboto,"Helvetica Neue",Arial,-apple-system,BlinkMacSystemFont,"Segoe UI",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"';

//font-size
export const primaryFontSize = '14px';
export const secondaryFontSize = '14px';
export const smallFontSize = '12px';
export const formElementFontSize = primaryFontSize;
export const heading1FontSize = '48.83px';
export const heading2FontSize = '39.06px';
export const heading3FontSize = '31.25px';
export const heading4FontSize = '25px';
export const heading5FontSize = '22px';
export const heading6FontSize = '20px';
export const buttonFontSize = primaryFontSize;
export const tooltipFontSize = secondaryFontSize;

//font-weight
export const regularFontWeight = 400;
export const mediumFontWeight = 500;
export const heavyFontWeight = 700;

export const productivityColors = {
  productive: primaryAqua,
  productivePassive: aqua10,
  passive: gray16,
  unproductivePassive: purple5,
  unproductive: purple3,
  offlineMeetings: orange6,
  undefined: gray18
};

export const stopLightProductivityColors = {
  productive: primaryAqua,
  productivePassive: aqua11,
  passive: gray16,
  unproductivePassive: purple5,
  unproductive: purple3,
  offlineMeetings: orange6,
  undefined: gray18,
  undefinedPassive: gray16
};

import { buildHttps, buildWss } from './apiDomainStore.utils';
import { IApiDomainStore } from './apiDomainStore.models';
import { activTrakConfig } from './apiDomainStore.utils';

export const API_DOMAIN_STORE_DEFAULT: IApiDomainStore = {
  defaultDomain: activTrakConfig.defaultUrl,
  frontendApi: buildHttps(activTrakConfig.apiUrl ?? activTrakConfig.defaultUrl),
  authorizationService: buildHttps(
    activTrakConfig.authorizationServiceUrl ?? activTrakConfig.defaultUrl
  ),
  ssoAuthorization: buildHttps(
    activTrakConfig.ssoAuthorizationUrl ?? activTrakConfig.defaultUrl
  ),
  reportingService: buildHttps(
    activTrakConfig.reportingServiceUrl ?? activTrakConfig.defaultUrl
  ),
  accountsApi: buildHttps(
    activTrakConfig.accountsApiUrl ?? activTrakConfig.defaultUrl
  ),
  adminApi: buildHttps(
    activTrakConfig.adminApiUrl ?? activTrakConfig.defaultUrl
  ),
  identityService: buildHttps(
    activTrakConfig.identityServiceUrl ?? activTrakConfig.defaultUrl
  ),
  accountApiKeyPage: buildHttps(activTrakConfig.defaultUrl),
  websocket: buildWss(activTrakConfig.websocketUrl),
  websocketHttps: buildHttps(activTrakConfig.websocketUrl)
};

import { FeatureFlag } from '../../../../_reactivtrak/src/common/enums/FeatureFlag';
import authorizationService from '../../../../_reactivtrak/src/common/helpers/authorization';
import atInsightsNavigationBarTemplate from 'views/reports/insights/atInsightsNavigationBar.html?raw';
import lodash from 'lodash';
import { getInsightsSettingsStore } from '../../../../_reactivtrak/src/common/stores/insightsSettingsStore/insightsSettingsStore';
import { getPrivacySettings } from '_reactivtrak/src/common/hooks/privacySettingsStore';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';

angular.module('app').directive('atInsightsNavigationBar', function () {
    return {
        restrict: 'E',
        scope: {},
        template: atInsightsNavigationBarTemplate,
        controller: atInsightsNavigationBarCtrl
    };
});

atInsightsNavigationBarCtrl.$inject = ['$scope', '$rootScope', '$state', 'localStorageService'];

function atInsightsNavigationBarCtrl($scope, $rootScope, $state, localStorageService) {
    const { activityAllowed = false } = getPrivacySettings();
    let features = { activityAllowed };
    const { account } = getAccountSettings();
    const INSIGHTS_SUB_NAV_FEATURE = `${account}_insights_sub_nav_features`;

    function hasInsightsSubNavFeature(feature) {
        return features && features[feature];
    }

    function getFilteredSubNavigation(menuItem) {
        const subNavs = menuItem.getSubNavigation();

        const filteredSubNavs = [];
        lodash.forEach(subNavs, function (subnav) {
            const subNavRules = subnav.rules;
            if (!subNavRules) {
                filteredSubNavs.push(subnav);
            } else {
                const subNavMenuFeature = subNavRules.map(function (rule) {
                    return rule.value;
                });
                let showSubNav = true;
                lodash.forEach(subNavMenuFeature, function (feature) {
                    showSubNav = showSubNav && hasInsightsSubNavFeature(feature);
                });
                if (showSubNav) {
                    filteredSubNavs.push(subnav);
                }
            }
        });
        return filteredSubNavs;
    }

    function setTabs() {
        // Get current page's sub-navigation
        const menuItem = $rootScope.sidebar.getMenuItem($state.current.name, $state.params);
        const navigationBar = menuItem && getFilteredSubNavigation(menuItem);

        // Map navigation options to tab options
        function mapNavigationToTabs(options) {
            if (!options || options.length <= 1) {
                return null;
            }

            return lodash.map(options, function (o) {
                return {
                    heapId: 'id_' + o.id,
                    text: o.subPageId,
                    value: o.label
                };
            });
        }

        // Handle tab click
        function handleViewChange(selection) {
            $state.go($state.current.name, { subPageId: selection });
        }

        // Define tab directive properties
        $scope.tab = $state.params.subPageId;
        $scope.tabOptions = {
            tabs: mapNavigationToTabs(navigationBar),
            onChange: handleViewChange
        };
    }

    $scope.back = function () {
        window.history.back();
    };

    const sidebarSubscription = $rootScope.sidebar.menu.subscribe(function () {
        const menuItem = $rootScope.sidebar.getMenuItem($state.current.name, $state.params);

        if (menuItem && menuItem.getSubNavigation()) {
            const subNavFeatures = localStorageService.get(INSIGHTS_SUB_NAV_FEATURE, 'sessionStorage');
            if (subNavFeatures) {
                features = {
                    ...features,
                    ...subNavFeatures
                };
            } else if (authorizationService.hasFeature(FeatureFlag.InsightsAvailable)) {
                const feat = { calendarIntegrated: false };
                getInsightsSettingsStore()
                    .then(function (result) {
                        feat.calendarIntegrated = result?.calendarIntegrated;
                        localStorageService.set(INSIGHTS_SUB_NAV_FEATURE, feat, 'sessionStorage');
                    })
                    .catch(function (e) {
                        console.error('ActivTrak Error: Error retrieving Insights subnav features', e);
                    })
                    .finally(function () {
                        //Don't include activityAllowed in session storage.
                        //It's not an insights flag and is more volatile (real time)
                        features = {
                            ...features,
                            ...feat
                        };

                        setTabs();
                    });
                return;
            }
        }

        setTabs();
    });

    $scope.$on('$destroy', function () {
        sidebarSubscription.unsubscribe();
    });
}

import { createStore } from 'zustand/vanilla';
import { IAccountPlanDto, IAccountPlan } from '../models/IAccountPlan';
import { getAccountSetting } from '../stores/accountSettingsStore/accountSettingsStore';
import { ChannelType } from '../enums/ChannelType';
import { PlanType } from '../enums/PlanType';
import { fetchData } from '../helpers/apis/apiRequest';
import { mapAccountPlanDtoToAccountPlan } from '../utils/accountPlanStore.utils';
import { ACCOUNT_PLAN_STORE_DEFAULT } from '../constants/accountPlanStoreDefault';

export const accountPlanStore = createStore<IAccountPlan>(() => ({
  ...ACCOUNT_PLAN_STORE_DEFAULT
}));

/**
 * Fetches the account plan from the API and sets it in the account plan store.
 */
export const fetchAccountPlanStore = async (): Promise<void> => {
  let newAccountPlan = {};
  try {
    const response = await fetchData<IAccountPlanDto>({
      path: '/account/plan'
    });
    newAccountPlan = mapAccountPlanDtoToAccountPlan(response);
  } catch (error) {
    console.error('ActivTrak Error: Error fetching account plan', error);
  }

  const currentState = accountPlanStore.getState();
  accountPlanStore.setState({
    ...currentState,
    ...newAccountPlan
  });
};

/**
 * Returns the current account plan store state.
 */
export const getAccountPlanStore = (): IAccountPlan =>
  accountPlanStore.getState();

/**
 * Sets the account plan store with the given account plan (can be partial).
 */
export const setAccountPlanStore = (
  newAccountPlan: Partial<IAccountPlan>
): void => {
  const current = accountPlanStore.getState();
  accountPlanStore.setState({ ...current, ...newAccountPlan });
};

/**
 * Resets the account plan store to its default state.
 */
export const resetAccountPlanStore = (): void =>
  accountPlanStore.setState(ACCOUNT_PLAN_STORE_DEFAULT);

/**
 * Checks if the account plan is in trial.
 */
export const isAccountPlanInTrial = (): boolean => {
  const { planPeriod } = accountPlanStore.getState();
  return planPeriod === 'trial' || false;
};

/**
 * Checks if the account plan type is equal to the given type.
 */
export const isAccountPlanType = (type: PlanType): boolean => {
  const { planType } = accountPlanStore.getState();
  return planType?.toLowerCase() === type.toLowerCase();
};

/**
 * Checks if the account is in a trial of the given plan type.
 */
export const isAccountPlanTypeAndInTrial = (type: PlanType): boolean => {
  const { planType } = accountPlanStore.getState();
  return (
    planType?.toLowerCase() === type.toLowerCase() && isAccountPlanInTrial()
  );
};

// TODO: update where channel type is retrieved from once the plan route is updated
/**
 * Checks if the account is a child MSP account using the plan channel type.
 */
export const isMSPChild = () => {
  const channelType = getAccountSetting('subscriptionType');
  return [
    ChannelType.OfficialMSPChild,
    ChannelType.OfficialMSPChildNFR,
    ChannelType.OfficialMSPChildHistoricNFR
  ].includes(channelType);
};

/**
 * Checks if the account is a parent MSP account using the plan channel type.
 */
export const isMSPParent = () => {
  const channelType = getAccountSetting('subscriptionType');
  return [
    ChannelType.OfficialMSP,
    ChannelType.UnofficialMSP,
    ChannelType.OfficialMSPParent
  ].includes(channelType);
};

/**
 * Checks if the account is an MSP parent or child account.
 */
export const isMSPAccount = () => {
  return isMSPChild() || isMSPParent();
};

import { DialogContent, Box, Dialog, DialogTitle, Link } from '@mui/material';
import React from 'react';
import {
  DialogActionsStyled,
  GetStartedTypogStyled
} from '../../styles/TimeOnTask.view.styles';
import DialogActionsBox from './DialogActionsBox';

type ComponentProps = {
  open: boolean;
  onClose: () => void;
  getStarted: () => void;
};

export const GetStartedDialog = (props: ComponentProps) => {
  const { open, onClose, getStarted } = props;

  const close = () => {
    onClose();
  };

  return (
    open && (
      <Dialog open={open} onClose={close}>
        <Box width={590} maxHeight={320}>
          <DialogTitle>Welcome to Activity Alignment!</DialogTitle>
          <DialogContent>
            <Box>
              <GetStartedTypogStyled>
                Activity Alignment lets you measure the time teams spend on
                activities critical to their role.{' '}
              </GetStartedTypogStyled>
              <GetStartedTypogStyled>
                Start by selecting Core Categories for a team or read our{' '}
                <Link
                  href="https://support.activtrak.com/hc/en-us/articles/31463500125979/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Configuration Guide
                </Link>{' '}
                for best practices.
              </GetStartedTypogStyled>
            </Box>
          </DialogContent>
          <DialogActionsStyled>
            <DialogActionsBox
              close={close}
              onClick={getStarted}
              disabled={false}
              confirmText={'Get started'}
            />
          </DialogActionsStyled>
        </Box>
      </Dialog>
    )
  );
};

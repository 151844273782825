import { createStore } from 'zustand/vanilla';
import {
  IUserPermissionDefinition,
  IUserPermissionStore
} from './userPermissionStore.models';
import { AuthLevel, Role } from '../../enums';
import { BundleFlag } from '../../enums/BundleFlag';
import {
  checkUserFlags,
  checkUserRoleAccess,
  checkUserRoles,
  fetchRouteAccess
} from './userPermissionStore.utils';
import { FeatureFlag } from '../../enums/FeatureFlag';
import { IBundleFlags } from '../../models/IBundleFlags';
import { IFeatureFlags } from '../../models/IFeatureFlags';
import { PrivacySetting } from '../../enums/PrivacySetting';
import { getPrivacySettings } from '../../hooks/privacySettingsStore';
import { USER_PERMISSION_STORE_DEFAULT } from './userPermissionStore.constants';

// TODO: Refactor to create a list of routes the user has access to with levels instead of doing this check every time
export const userPermissionStore = createStore<IUserPermissionStore>((set) => ({
  ...USER_PERMISSION_STORE_DEFAULT,
  reset: () => {
    set(USER_PERMISSION_STORE_DEFAULT);
  }
}));

export const getUserBundleFlags = () => {
  return userPermissionStore.getState().userBundleFlags;
};

export const setUserBundleFlags = (flags: IBundleFlags) => {
  const current = userPermissionStore.getState();
  userPermissionStore.setState({ ...current, userBundleFlags: flags });
};

export const getUserFeatureFlags = () => {
  return userPermissionStore.getState().userFeatureFlags;
};

export const setUserFeatureFlags = (flags: IFeatureFlags) => {
  const current = userPermissionStore.getState();
  userPermissionStore.setState({ ...current, userFeatureFlags: flags });
};

export const getRouteAccess = (
  routeName: string
): IUserPermissionDefinition => {
  let routeAccesses = userPermissionStore.getState().routeAccesses;

  if (Object.keys(routeAccesses).length === 0) {
    routeAccesses = fetchRouteAccess();
    const current = userPermissionStore.getState();
    userPermissionStore.setState({ ...current, routeAccesses });
  }
  return routeAccesses[routeName];
};

export const getRouteAccessLevel = (
  routeName: string,
  userRoles: string[] = [] // TODO: replace with roles and rbacKeys from this store
): AuthLevel => {
  const routeAccess = getRouteAccess(routeName);
  if (!routeAccess) {
    console.error('ActivTrak Error: route access not found', routeName);
    return AuthLevel.None;
  }

  // Role Access check
  if (!checkUserRoleAccess(routeAccess, userRoles)) {
    return AuthLevel.None;
  }

  // Bundle Flag check
  const userBundleFlags = getUserBundleFlags();
  if (
    !checkUserFlags<BundleFlag>(
      userBundleFlags,
      routeAccess.bundleFlags,
      routeAccess.excludedBundleFlags
    )
  ) {
    return AuthLevel.None;
  }

  // Feature Flag check
  const userFeatureFlags = getUserFeatureFlags();
  if (
    !checkUserFlags<FeatureFlag>(
      userFeatureFlags,
      routeAccess.featureFlags,
      routeAccess.excludedFeatureFlags
    )
  ) {
    return AuthLevel.None;
  }

  // Privacy Setting check
  const userPrivacySettings = getPrivacySettings();
  if (
    !checkUserFlags<PrivacySetting>(
      userPrivacySettings,
      routeAccess.privacySettings,
      routeAccess.excludedPrivacySettings
    )
  ) {
    return AuthLevel.None;
  }

  // TODO: Add route restrictions check

  // Custom Access Function Check
  if (
    typeof routeAccess.customFunction === 'function' &&
    !routeAccess.customFunction()
  ) {
    return AuthLevel.None;
  }

  const { edit, read, allowAnonymous } = routeAccess;

  // Anonymous grants view access
  if (allowAnonymous) {
    return AuthLevel.View;
  }

  // Check if role has edit access
  if (checkUserRoles(edit, userRoles as Role[])) {
    return AuthLevel.Edit;
  }

  // Check if role has read access
  if (checkUserRoles(read, userRoles as Role[])) {
    return AuthLevel.View;
  }

  // Default to no access
  return AuthLevel.None;
};

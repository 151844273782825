import React, { useEffect, useState } from 'react';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { tooltipClasses } from '@mui/material/Tooltip';
import { showModal } from '../../../stores/globalModalStore';
import { ExportErrorModalContent } from '../../../../_global/modals/components/ExportErrorModalContent';
import { useExportStore } from '../../../stores/exportsStore/exportStore.hooks';
import {
  startExport,
  openExportInitiatedModal,
  validateReportPayload
} from '../../../stores/exportsStore/exportStore.utils';
import {
  ExportDestination,
  ExportValidationResult
} from '../../../stores/exportsStore/exportStore.models';
import { ReportType } from '../../../enums/ReportType';
import { BaseTooltipSC } from '../styles';
import { ExportsLimitedTooltip } from './ExportsLimitedTooltip';
import { AsyncExportMenuItem } from './AsyncExportMenuItem';
import {
  IExportReportParams,
  IExportMenuOption
} from '../../../stores/exportsStore/exportStore.models';
import { hasExportsInProgress } from '../../../stores/exportsStore/exportStore';
import authorization from '../../../helpers/authorization';
import { Role } from '../../../enums';

export interface IAsyncExportButtonProps {
  reportType: ReportType;
  createExportPayload: (menuOption?: string) => IExportReportParams;
  showExportsLimitedTooltip: boolean;
  menuOptions: IExportMenuOption[];
  exportableItemCount?: string;
  isDisabled?: boolean;
}

export const AsyncExportButton = (props: IAsyncExportButtonProps) => {
  const {
    reportType,
    exportableItemCount,
    showExportsLimitedTooltip,
    createExportPayload,
    menuOptions,
    isDisabled
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [exportInProgress, setExportInProgress] = useState<boolean>(false);
  const exports = useExportStore((s) => s.exports);
  const exportError = useExportStore((s) => s.exportError);

  useEffect(() => {
    setExportInProgress(hasExportsInProgress());
  }, [exports]);

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const handleExportButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSaveExportClick = (
    exportDestination: ExportDestination,
    selectedExportType?: string
  ) => {
    const payload = createExportPayload(selectedExportType);
    const payloadStatus = validateReportPayload(payload, exportDestination);

    if (payloadStatus === ExportValidationResult.Valid) {
      startExport(payload, exportDestination);
      setIsMenuOpen(false);
    } else {
      showModal(<ExportErrorModalContent />);
      setIsMenuOpen(false);

      console.error(
        `ActivTrak Error: ${payloadStatus} => Payload: `,
        JSON.stringify(payload),
        ', Export Destination: ',
        exportDestination
      );
    }
  };

  const handleManageExportClick = () => {
    openExportInitiatedModal();
    setIsMenuOpen(false);
  };

  const isExportInProgressValid: boolean = exportInProgress && !exportError;

  const exportBtnLabel: string = isExportInProgressValid
    ? 'Exporting...'
    : 'Export';

  if (
    authorization.hasAnyRole([
      Role.SupportBasic,
      Role.SupportIntermediate,
      Role.SupportAdvanced
    ])
  ) {
    return <></>;
  }

  return (
    <Box maxWidth="400px" margin="auto">
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <BaseTooltipSC
            PopperProps={{
              disablePortal: true
            }}
            onClose={handleClose}
            open={isMenuOpen}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            sx={{
              [`& .${tooltipClasses.tooltip}`]: {
                minWidth: '200px',
                padding: '0px'
              }
            }}
            placement="bottom-end"
            title={
              <List component="nav">
                {showExportsLimitedTooltip && (
                  <>
                    <ExportsLimitedTooltip
                      exportableItemCount={exportableItemCount}
                    />
                    <Divider />
                  </>
                )}
                {menuOptions?.map((option, index) => (
                  <AsyncExportMenuItem
                    key={`${option.key}-${index}`}
                    option={option}
                    exportInProgress={isExportInProgressValid}
                    reportType={reportType}
                    handleSaveExportClick={handleSaveExportClick}
                  />
                ))}
                {isExportInProgressValid && (
                  <>
                    <Divider />
                    <ListItemButton
                      onClick={handleManageExportClick}
                      sx={{ minWidth: 4 }}
                    >
                      <ListItemText>Manage Export</ListItemText>
                    </ListItemButton>
                  </>
                )}
              </List>
            }
          >
            <Button
              variant="outlined"
              onClick={handleExportButtonClick}
              endIcon={isMenuOpen ? <ArrowDropUp /> : <ArrowDropDown />}
              id={`487a49f5-14dc-47dc-bf2f-0c68a0b38d89-${reportType}`}
              disabled={isDisabled}
            >
              {exportBtnLabel}
            </Button>
          </BaseTooltipSC>
        </div>
      </ClickAwayListener>
    </Box>
  );
};

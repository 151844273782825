import { useCallback, useState } from 'react';
import moment from 'moment';
import { useAuthorization } from '../../common/services/Authorization';
import { useNotifications } from '../../common/services/Notifications';
import { Role } from '../../common/enums';
import { SupportPortalService, useSupportPortalState } from './';
import {
  decodeToken,
  getTokenExpiration,
  impersonateUser,
  redirectToPath
} from '../utils';
import { isValidTokenDto } from '../utils/checkIfValidData.utils';
import { consoleMessage, displayMessage } from '../constants';
import { RedirectStatus } from '../enums/RedirectStatus';
import { INewlyAddedAccount } from '../models/IAccountData';

type RedirectingStatus =
  | RedirectStatus.Done
  | RedirectStatus.Error
  | RedirectStatus.Loading;

export const useRedirect = () => {
  const service = SupportPortalService();
  const { fetchAccountToken } = useSupportPortalState({
    service
  });

  const [status, setStatus] = useState<RedirectingStatus>(RedirectStatus.Done);

  const authorizationService = useAuthorization();
  const notificationService = useNotifications();
  const isSuperAdmin: boolean = authorizationService.hasRole(Role.SuperAdmin);

  const impersonateAndRedirect = useCallback(
    async (account: INewlyAddedAccount, path?: string) => {
      const { payload, tokenData } = account;

      setStatus(RedirectStatus.Loading);

      try {
        if (
          tokenData.expiration &&
          moment(tokenData.expiration).isAfter(moment())
        ) {
          const newUserToken = await fetchAccountToken(payload);

          if (!isValidTokenDto(newUserToken)) {
            setStatus(RedirectStatus.Error);
            notificationService.error(displayMessage.apiError.redirect);
            console.error(consoleMessage.tokenValidationError.redirect);
            return;
          }

          tokenData.token = newUserToken.token;
          tokenData.impersonateToken = newUserToken.impersonateToken;
          tokenData.expiration = getTokenExpiration(
            decodeToken(newUserToken.token)
          );

          impersonateUser(tokenData);
          redirectToPath(path, tokenData, isSuperAdmin);
        }
      } catch (error) {
        setStatus(RedirectStatus.Error);

        notificationService.error(displayMessage.apiError.redirect);
        console.error(error?.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return { impersonateAndRedirect, status, setStatus };
};

import React from 'react';
import { ColDef, ITooltipParams } from 'ag-grid-community';
import { BundleFlag } from '../../../common/enums/BundleFlag';
import { FeatureFlag } from '../../../common/enums/FeatureFlag';
import {
  durationFormat,
  formatTimeStamp
} from '../../../common/utils/datetime/time';
import { formatDate } from '../../../common/utils/datetime/datetimeFormatters';
import authorization from '../../../common/helpers/authorization';
import { isTodayByTimezone } from '../../../common/utils/datetime/isToday';
import { IReportColumnLabel } from '../../../common/components/ReportFilters/models/IGridColumnFilter.models';
import {
  generateLinkedItem,
  getAccessForFeature,
  getProductivityTimeTemplate
} from './workingHoursReport.utils';
import { smallFontSize } from '../../../common/constants';
import { LocationHeader } from '../components/LocationHeader';
import { OnlineMeetingsHeader } from '../components/OfflineMeetingsHeader';
import { TotalProdAndTimeOffHeader } from '../components/TotalProdAndTimeOffHeader';
import { CustomHeaderTemplate } from '../../../common/components/AtGrid/CustomHeaderTemplate';
import Typography from '@mui/material/Typography';

const getHasLocationAccess = () =>
  getAccessForFeature(
    BundleFlag.LocationData,
    BundleFlag.LocationPromo,
    FeatureFlag.ShowLocationData
  );

const getHasOfflineAccess = () =>
  getAccessForFeature(
    BundleFlag.OfflineMeetingsData,
    BundleFlag.OfflineMeetingsPromo,
    FeatureFlag.ShowOfflineMeetingData
  );

//NOTE: Because the Columns Selector uses the 'hide' property to determine if a column is visible, a 'hasAccess' property is used to separately determine if a column should be included in the total list of columns to display.
export const getScheduledColumns = (): ColDef[] => {
  const hasLocationAccess = getHasLocationAccess();
  const hasOfflineAccess = getHasOfflineAccess();

  const cols = [
    {
      colId: 'date',
      field: 'date',
      headerName: 'Date',
      type: 'string',
      sortable: false,
      valueFormatter: (params) => formatDate(params?.value),
      minWidth: 150
    },
    {
      colId: 'user',
      field: 'user',
      headerName: 'User',
      type: 'string',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return generateLinkedItem(dataItem.user, 'user');
      },
      tooltipValueGetter: (params: ITooltipParams) => params?.value,
      minWidth: 180
    },
    {
      colId: 'location',
      field: 'location',
      headerName: 'Location',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: <LocationHeader />
      },
      type: 'string',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;

        if (!dataItem) return '';

        const hasFeatureEnabled = authorization.hasFeature(
          BundleFlag.LocationData
        );
        const isTodayDate = isTodayByTimezone(dataItem.date);
        if (!dataItem.location || !hasFeatureEnabled || isTodayDate) return '';

        return dataItem.location;
      },
      minWidth: 130,
      hasAccess: hasLocationAccess
    },
    {
      colId: 'shiftTimes.productiveTime',
      field: 'shiftTimes.productiveTime',
      headerName: 'Productive Time (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Productive Time
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.productiveTime,
          1
        );
      },
      minWidth: 175
    },
    {
      colId: 'shiftTimes.productiveActiveTime',
      field: 'shiftTimes.productiveActiveTime',
      headerName: 'Productive Active (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Productive Active
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.productiveActiveTime,
          1
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.productivePassiveTime',
      field: 'shiftTimes.productivePassiveTime',
      headerName: 'Productive Passive (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Productive Passive
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.productivePassiveTime,
          2
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.unproductiveTime',
      field: 'shiftTimes.unproductiveTime',
      headerName: 'Unproductive Time (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Unproductive Time
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.unproductiveTime,
          -1
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.unproductiveActiveTime',
      field: 'shiftTimes.unproductiveActiveTime',
      headerName: 'Unproductive Active (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Unproductive Active
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.unproductiveActiveTime,
          -1
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.unproductivePassiveTime',
      field: 'shiftTimes.unproductivePassiveTime',
      headerName: 'Unproductive Passive (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Unproductive Passive
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.unproductivePassiveTime,
          -2
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.undefinedTime',
      field: 'shiftTimes.undefinedTime',
      headerName: 'Undefined Time (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Undefined Time
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.undefinedTime,
          0
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.undefinedActiveTime',
      field: 'shiftTimes.undefinedActiveTime',
      headerName: 'Undefined Active (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Undefined Active
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.undefinedActiveTime,
          0
        );
      },
      minWidth: 200
    },
    {
      colId: 'shiftTimes.undefinedPassiveTime',
      field: 'shiftTimes.undefinedPassiveTime',
      headerName: 'Undefined Passive (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Undefined Passive
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.shiftTimes.undefinedPassiveTime,
          -3
        );
      },
      minWidth: 200
    },
    {
      colId: 'productiveGoalAchievement',
      field: 'productiveGoalAchievement',
      headerName: 'Productivity Goal (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Productivity Goal
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return `${Math.floor(dataItem.productiveGoalAchievement)}%`;
      },
      minWidth: 200
    },
    {
      colId: 'productiveHoursGoal',
      field: 'productiveHoursGoal',
      headerName: 'Productive Hours Goal',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Productive
            <br />
            Hours Goal
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      minWidth: 175
    },
    {
      colId: 'firstActivity',
      field: 'firstActivity',
      headerName: 'First Activity',
      type: 'rightAligned',
      sortable: false,
      valueFormatter: (params) => formatTimeStamp(params?.value, 'hh:mm:ss A'),
      minWidth: 150
    },
    {
      colId: 'lastActivity',
      field: 'lastActivity',
      headerName: 'Last Activity',
      type: 'rightAligned',
      sortable: false,
      valueFormatter: (params) => formatTimeStamp(params?.value, 'hh:mm:ss A'),
      minWidth: 150
    },
    {
      colId: 'shiftPunctuality',
      field: 'shiftPunctuality',
      headerName: 'Late Start (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Late Start
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return durationFormat(dataItem.shiftPunctuality, false);
      },
      minWidth: 200
    },
    {
      colId: 'earlyDeparture',
      field: 'earlyDeparture',
      headerName: 'Early End (Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Early End
            <br />
            <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return durationFormat(dataItem.earlyDeparture, false);
      },
      minWidth: 200
    },
    {
      colId: 'nonShiftTimes.productiveTime',
      field: 'nonShiftTimes.productiveTime',
      headerName: 'Productive Time (Non-Scheduled Hours)',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: (
          <Typography sx={{ textAlign: 'right' }}>
            Productive Time
            <br />
            <span style={{ fontSize: smallFontSize }}>
              (Non-Scheduled Hours)
            </span>
          </Typography>
        )
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return getProductivityTimeTemplate(
          dataItem.nonShiftTimes.productiveTime,
          1
        );
      },
      minWidth: 200
    },
    {
      colId: 'screenTime',
      field: 'screenTime',
      headerName: 'Screen Time',
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return durationFormat(dataItem.screenTime, false);
      },
      minWidth: 150
    },
    {
      colId: 'totalTimeOffline',
      field: 'totalTimeOffline',
      headerName: 'Offline Meetings',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: <OnlineMeetingsHeader />
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        const hasFeatureEnabled = authorization.hasFeature(
          BundleFlag.OfflineMeetingsData
        );
        const isTodayDate = isTodayByTimezone(dataItem.date);
        if (!hasFeatureEnabled || isTodayDate) return '';

        return getProductivityTimeTemplate(dataItem.totalTimeOffline || 0, -4);
      },
      minWidth: 150,
      hasAccess: hasOfflineAccess
    },
    {
      colId: 'totalProductiveAndTimeOff',
      field: 'totalProductiveAndTimeOff',
      headerName: 'Total',
      headerComponent: CustomHeaderTemplate,
      headerComponentParams: {
        customHeader: <TotalProdAndTimeOffHeader />
      },
      type: 'rightAligned',
      sortable: false,
      cellRenderer: (params) => {
        const dataItem = params?.data;
        if (!dataItem) return '';

        return durationFormat(dataItem.totalProductiveAndTimeOff, false);
      },
      minWidth: 150
    }
  ];
  const filteredCols = cols.filter((col) => col.hasAccess !== false);

  //remove the 'hasAccess' property from the columns to make typescript happy
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return filteredCols.map(({ hasAccess, ...rest }) => rest);
};

export const getScheduledColumnLabels = (): IReportColumnLabel[] => {
  const hasLocationAccess = getHasLocationAccess();
  const hasOfflineAccess = getHasOfflineAccess();

  const cols = [
    { colId: 'all', label: 'All' },
    { colId: 'date', label: 'Date' },
    { colId: 'user', label: 'User' },
    {
      colId: 'location',
      label: 'Location',
      hasAccess: hasLocationAccess
    },
    {
      colId: 'shiftTimes.productiveTime',
      label: 'Productive Time',
      customLabel: (
        <Typography>
          Productive Time
          <br />
          <span style={{ fontSize: smallFontSize }}>(Scheduled hours)</span>
        </Typography>
      )
    },
    {
      colId: 'shiftTimes.productiveActiveTime',
      label: 'Productive Active',
      customLabel: (
        <Typography>
          Productive Active
          <br />
          <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
        </Typography>
      )
    },
    {
      colId: 'shiftTimes.productivePassiveTime',
      label: 'Productive Passive',
      customLabel: (
        <Typography>
          Productive Passive
          <br />
          <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
        </Typography>
      )
    },
    {
      colId: 'shiftTimes.unproductiveTime',
      label: 'Unproductive Time',
      customLabel: (
        <Typography>
          Unproductive Time
          <br />
          <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
        </Typography>
      )
    },
    {
      colId: 'shiftTimes.unproductiveActiveTime',
      label: 'Unproductive Active',
      customLabel: (
        <Typography>
          Unproductive Active
          <br />
          <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
        </Typography>
      )
    },
    {
      colId: 'shiftTimes.unproductivePassiveTime',
      label: 'Unproductive Passive',
      customLabel: (
        <Typography>
          Unproductive Passive
          <br />
          <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
        </Typography>
      )
    },
    {
      colId: 'shiftTimes.undefinedTime',
      label: 'Undefined Time',
      customLabel: (
        <Typography>
          Undefined Time
          <br />
          <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
        </Typography>
      )
    },
    {
      colId: 'shiftTimes.undefinedActiveTime',
      label: 'Undefined Active',
      customLabel: (
        <Typography>
          Undefined Active
          <br />
          <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
        </Typography>
      )
    },
    {
      colId: 'shiftTimes.undefinedPassiveTime',
      label: 'Undefined Passive',
      customLabel: (
        <Typography>
          Undefined Passive
          <br />
          <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
        </Typography>
      )
    },
    {
      colId: 'productiveGoalAchievement',
      label: 'Productivity Goal',
      customLabel: (
        <Typography>
          Productivity Goal
          <br />
          <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
        </Typography>
      )
    },
    {
      colId: 'productiveHoursGoal',
      label: 'Productive Hours Goal',
      customLabel: (
        <Typography>
          Productive
          <br />
          Hours Goal
        </Typography>
      )
    },
    { colId: 'firstActivity', label: 'First Activity' },
    { colId: 'lastActivity', label: 'Last Activity' },
    {
      colId: 'shiftPunctuality',
      label: 'Late Start',
      customLabel: (
        <Typography>
          Late Start
          <br />
          <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
        </Typography>
      )
    },
    {
      colId: 'earlyDeparture',
      label: 'Early End',
      customLabel: (
        <Typography>
          Early End
          <br />
          <span style={{ fontSize: smallFontSize }}>(Scheduled Hours)</span>
        </Typography>
      )
    },
    {
      colId: 'nonShiftTimes.productiveTime',
      label: 'Productive Time',
      customLabel: (
        <Typography>
          Productive Time
          <br />
          <span style={{ fontSize: smallFontSize }}>(Non-Scheduled Hours)</span>
        </Typography>
      )
    },
    { colId: 'screenTime', label: 'Screen Time' },
    {
      colId: 'totalTimeOffline',
      label: 'Offline Meetings',
      hasAccess: hasOfflineAccess
    },
    { colId: 'totalProductiveAndTimeOff', label: 'Total' }
  ];

  const filteredCols = cols.filter((col) => col.hasAccess !== false);

  //remove the 'hasAccess' property from the columns to make typescript happy
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return filteredCols.map(({ hasAccess, ...rest }) => rest);
};

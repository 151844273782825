import { BundleFlag } from '../../../../_reactivtrak/src/common/enums/BundleFlag';
import messageModalV2Template from 'views/modals/messageModalV2.html?raw';
import groupsModalTemplate from 'views/settings/groups-modal.html?raw';
import dntGlobalUserModalTemplate from 'views/settings/dnt-global-user-modal.html?raw';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';

angular.module('app').controller('doNotTrackCtrl', DoNotTrackCtrl);

DoNotTrackCtrl.$inject = [
    '$scope',
    'gridPropertiesService',
    'messagesService',
    'doNotTrackService',
    '$timeout',
    'customUibModal',
    'pageSizeService',
    'authorizationService',
    'templateServiceFunctions',
    'browserServiceFunctions',
    'iconResolverServiceFunctions'
];

function DoNotTrackCtrl(
    $scope,
    gridPropertiesService,
    msg,
    doNotTrackService,
    $timeout,
    customUibModal,
    pageSizeService,
    authorizationService,
    templateServiceFunctions,
    browserServiceFunctions,
    iconResolverServiceFunctions
) {
    // default group id -1 'All Computers'
    //$scope.groupId = 0;
    $scope.addingUsers = false;
    $scope.allRows = {
        selected: false
    };

    $scope.identityBeta = authorizationService.hasFeature(BundleFlag.UserIdentityConfiguration);

    $scope.hasViewLevel = function (levels) {
        return authorizationService.hasAuthorizationLevel(levels, 'app.settings.doNotTrack');
    };

    var userDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                if (options.data && options.data.cache) {
                    options.success(options.data.cache);
                } else {
                    doNotTrackService.getUsers().then(
                        function (result) {
                            options.success(result.data);
                        },
                        function (result) {
                            options.error(result);
                        }
                    );
                }
            }
        },
        pageSize: 20,
        sort: [
            {
                field: 'userName',
                dir: 'asc'
            },
            {
                field: 'domain',
                dir: 'asc'
            }
        ]
    });

    var dntDataSource = new kendo.data.CustomDataSource({
        transport: {
            read: function (options) {
                doNotTrackService.getDNTList({ params: options.data }).then(
                    function (result) {
                        options.success(result);
                    },
                    function (result) {
                        options.error(result);
                    }
                );
            }
        },
        pageSize: pageSizeService.loadPageSize('dnt-master', 50),
        serverPaging: true,
        serverSorting: true,
        serverFiltering: true,
        schema: {
            data: 'data',
            total: 'total'
        }
    });
    $scope.userDataSource = userDataSource;

    var rowDeleteVisible = !$scope.identityBeta
        ? function () {
              return true;
          }
        : function () {
              return false;
          };

    var removeUser = function (e) {
        e.preventDefault();
        var dataItem = this.dataItem(angular.element(e.currentTarget).closest('tr'));

        var modal = customUibModal.open({
            animation: false,
            template: messageModalV2Template,
            controller: 'messageModalController',
            windowClass: 'centered-modal',
            resolve: {
                messageData: {
                    messageTitle: msg.get('removeUserFromDNTTitle'),
                    messageBody: msg.get(
                        'removeUserFromDNTBody',
                        browserServiceFunctions.htmlEscape(dataItem.userName)
                    ),
                    confirmLabel: msg.get('remove')
                }
            }
        });

        modal.result.then(function () {
            doNotTrackService
                .removeFromList([dataItem])
                .then(function () {
                    $scope.$emit('showNotification', {
                        message: msg.get('dntUserRemoved', dataItem.userName),
                        color: 'success'
                    });
                    dntDataSource.read();
                })
                .catch(function () {
                    $scope.$emit('showNotification', {
                        message: msg.get('dtnErrorRemovingUser', dataItem.userName),
                        color: 'error'
                    });
                });
        });
    };

    var dntGridColumns = [
        {
            field: 'domain',
            title: msg.get('logonDomain'),
            headerAttributes: {
                style: 'padding-left: 17px'
            },
            attributes: {
                class: 'spaces-pre'
            },
            width: '250px',
            filterable: templateServiceFunctions.createFilter('domain', dntDataSource),
            sortable: true,
            template: function (item) {
                return item.domain === null ? '<em>Any</em>' : item.domain;
            }
        },
        {
            field: 'userName',
            title: msg.get('user'),
            headerAttributes: {
                style: 'padding-left: 17px'
            },
            attributes: {
                class: 'spaces-pre'
            },
            filterable: templateServiceFunctions.createFilter('userName', dntDataSource),
            sortable: true
        }
    ];

    if ($scope.hasViewLevel('edit')) {
        dntGridColumns.push({
            command: [
                {
                    name: 'remove',
                    text: msg.get('remove'),
                    click: removeUser,
                    visible: rowDeleteVisible
                }
            ],
            attributes: {
                class: 'text-right p-r-20',
                style: 'padding: 5px'
            },
            width: '120px'
        });
    }

    $scope.dntGridOptions = {
        dataSource: dntDataSource,
        columns: dntGridColumns,
        scrollable: true,
        editable: 'inline',
        filterable: {
            mode: 'row'
        },
        sortable: true,
        pageable: {
            refresh: true,
            pageSizes: [10, 25, 50, 100, 500],
            buttonCount: 5,
            messages: {
                itemsPerPage: msg.get('itemsPerPage', 'users'),
                display: msg.get('itemsDisplay', 'users'),
                empty: msg.get('noItemsToDisplay', 'users')
            }
        },
        dataBound: function (e) {
            gridPropertiesService.bindFilterAutoComplete(e, true);
        }
    };

    $scope.addMembersOptions = {
        dataSource: userDataSource,
        columns: [
            {
                field: 'selected',
                title: ' ',
                template:
                    '<div class="checkbox"><input type="checkbox" at-multi-select-checkbox at-data-source="userDataSource" at-item="dataItem" at-model-field="\'selected\'" id="selectbox-member-{{dataItem.uid}}" ng-model="dataItem.selected"></input><label class="m-l-m2-i m-b-m5-i" for="selectbox-member-{{dataItem.uid}}"></label></div>',
                attributes: {
                    class: 'select text-overflow-clip'
                },
                filterable: false,
                width: '35px'
            },
            {
                field: 'domain',
                title: msg.get('logonDomain'),
                filterable: templateServiceFunctions.createFilter('domain', userDataSource)
            },
            {
                field: 'userName',
                title: msg.get('user'),
                template: templateServiceFunctions.userTypeTemplate,
                filterable: templateServiceFunctions.createFilter('userName', userDataSource)
            },
            {
                field: 'alias',
                title: msg.get('alias'),
                filterable: templateServiceFunctions.createFilter('alias', userDataSource)
            }
        ],
        scrollable: {
            virtual: true
        },
        filterable: {
            mode: 'row'
        },
        height: '80vh',
        autoBind: false
    };

    $scope.addUsers = function () {
        $scope.modal = customUibModal.open({
            animation: false,
            template: groupsModalTemplate,
            windowClass: 'centered-modal',
            size: 'lg',
            scope: $scope
        });

        // Refresh Data Source when the modal is opened...
        $scope.modal.opened.then(function () {
            userDataSource.read();
        });
    };

    $scope.saveAddingUsers = function () {
        var payload = [];
        $scope.modal.close();

        angular.forEach($scope.addMembersOptions.dataSource.data(), function (item) {
            if (item.selected) {
                payload.push(item);
            }
        });

        var modal = customUibModal.open({
            animation: false,
            template: messageModalV2Template,
            controller: 'messageModalController',
            resolve: {
                messageData: {
                    messageTitle: msg.get('addUserToDNTTitle'),
                    messageBody: msg.get('addUserToDNTBody', payload),
                    confirmLabel: msg.get('add'),
                    specificationLabel: specificationLabelMessage,
                    twoStepConfirm: true,
                    twoStepConfirmLabel: msg.get('deletionAcknowledgement'),
                    confirmTrackEvent: 'dnt-addUsers-confirm'
                }
            }
        });

        modal.result.then(function () {
            doNotTrackService
                .addToList(payload)
                .then(function () {
                    dntDataSource.read();
                    $scope.addingUsers = false;
                    $scope.$emit('showNotification', {
                        message: msg.get('changesSaved'),
                        color: 'success'
                    });
                })
                .catch(function (data) {
                    if (data.error) {
                        $scope.$emit('showNotification', {
                            message: data.error,
                            color: 'error'
                        });
                    }
                });
        });
    };

    $scope.isSavingDisabled = function () {
        return (
            userDataSource
                .data()
                .map(function (item) {
                    return item.selected;
                })
                .indexOf(true) < 0
        );
    };

    $scope.getUserTypeIcon = function (type) {
        return iconResolverServiceFunctions.getUserTypeIconCss(type);
    };

    $scope.addGlobalUser = function () {
        $scope.modal = customUibModal.open({
            animation: false,
            template: dntGlobalUserModalTemplate,
            windowClass: 'centered-modal',
            size: 'smedium',
            scope: $scope
        });

        $scope.globalUser = {
            value: ''
        };
    };

    $scope.saveGlobalUser = function () {
        if (!$scope.globalUser || !$scope.globalUser.value) {
            return;
        }

        if ($scope.globalUser.value.toUpperCase() === 'SYSTEM') {
            $scope.modal.close();
            $scope.$emit('showNotification', {
                message: msg.get('cannotAddSystemToDNT'),
                color: 'error'
            });
            return;
        }

        doNotTrackService
            .getLogonDomains($scope.globalUser.value)
            .then(function (data) {
                $scope.modal.close();
                $scope.showGlobalConfirmationModal($scope.globalUser.value, data);
            })
            .catch(function (data) {
                if (data.error) {
                    $scope.$emit('showNotification', {
                        message: data.error,
                        color: 'error'
                    });
                }
            });
    };

    var { delayedDelete } = getAccountSettings();
    var specificationLabelMessage = delayedDelete
        ? msg.get('storageDeleteWithWaitAcknowledgement', true, true)
        : undefined;

    $scope.showGlobalConfirmationModal = function (user, logonDomains) {
        var modal = customUibModal.open({
            animation: false,
            template: messageModalV2Template,
            controller: 'messageModalController',
            resolve: {
                messageData: {
                    messageTitle: msg.get('addUserToDNTTitle'),
                    messageBody: msg.get('addGlobalUserToDNTBody', user, logonDomains),
                    specificationLabel: specificationLabelMessage,
                    confirmLabel: msg.get('add'),
                    twoStepConfirm: true,
                    twoStepConfirmLabel: msg.get('deletionAcknowledgement'),
                    confirmTrackEvent: 'dnt-addUsers-confirm'
                }
            }
        });

        modal.result.then(function () {
            var payload = [
                {
                    userName: user,
                    domain: null
                }
            ];
            doNotTrackService
                .addToList(payload)
                .then(function () {
                    dntDataSource.read();
                    $scope.$emit('showNotification', {
                        message: msg.get('changesSaved'),
                        color: 'success'
                    });
                })
                .catch(function (data) {
                    if (data.error) {
                        $scope.$emit('showNotification', {
                            message: data.error,
                            color: 'error'
                        });
                    }
                });
        });
    };

    $timeout(function () {
        if (pageSizeService.setDropdownHandler) {
            pageSizeService.setDropdownHandler('dnt-master', '.dnt-grid');
        }
    });
}

angular.module('app').service('doNotTrackService', DoNotTrackService);

DoNotTrackService.$inject = ['$http', 'envConfig', 'authorizationService'];

function DoNotTrackService($http, envConfig, authorizationService) {
    const isSoftDeletesEnabled = authorizationService.hasFeature(BundleFlag.SoftDeletes);

    this.getUsers = function () {
        if (isSoftDeletesEnabled) {
            return $http
                .get(envConfig.adminApiUrl() + '/admin/v1/clients?includeRestorable=False')
                .then(function (result) {
                    return {
                        data: result.data.map((e) => {
                            return {
                                userName: e.name,
                                domain: e.domain,
                                alias: e.alias
                            };
                        })
                    };
                });
        } else {
            return $http.get(envConfig.apiUrl() + '/api/settings/donottrack/users');
        }
    };

    this.getDNTList = function (params) {
        if (isSoftDeletesEnabled) {
            return $http.get(envConfig.adminApiUrl() + '/admin/v1/clients/donottrack', params).then(function (result) {
                return {
                    data: result.data.records.map((e) => {
                        return {
                            userName: e.username,
                            domain: e.logondomain
                        };
                    }),
                    total: result.data.total
                };
            });
        } else {
            return $http.get(envConfig.apiUrl() + '/api/settings/donottrack');
        }
    };

    this.addToList = function (data) {
        if (isSoftDeletesEnabled) {
            const dntEntries = data.map((e) => {
                return {
                    username: e.userName,
                    logondomain: e.domain
                };
            });

            return $http.post(envConfig.adminApiUrl() + '/admin/v1/clients/donottrack', { users: dntEntries });
        } else {
            return $http.post(envConfig.apiUrl() + '/api/settings/donottrack', data);
        }
    };

    this.removeFromList = function (data) {
        if (isSoftDeletesEnabled) {
            const requestData = {
                users: data.map((e) => {
                    return {
                        username: e.userName,
                        logondomain: e.domain
                    };
                })
            };

            return $http.delete(envConfig.adminApiUrl() + '/admin/v1/clients/donottrack', {
                data: requestData,
                headers: { 'content-type': 'application/json' }
            });
        } else {
            return $http.post(envConfig.apiUrl() + '/api/settings/donottrack-delete', data);
        }
    };

    this.getLogonDomains = function (user) {
        if (isSoftDeletesEnabled) {
            return this.getUsers().then((result) =>
                result.data.filter((u) => u.userName === user).map((u) => u.domain)
            );
        } else {
            return $http.get(envConfig.apiUrl() + '/api/settings/donottrack/domains?user=' + user);
        }
    };
}

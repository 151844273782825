'use strict';
import exportTemplate from 'views/widgets/export.html?raw';
import { getReportFilters } from '../../../../_reactivtrak/src/common/components/ReportFilters/hooks/reportFiltersStore';
import { BundleFlag } from '../../../../_reactivtrak/src/common/enums/BundleFlag';
import { getPrivacySettings } from '../../../../_reactivtrak/src/common/hooks/privacySettingsStore';
import { generateParameters } from '../../../../_reactivtrak/src/common/components/ReportFilters/utils/generateParameters';
import { getAccountSettings } from '../../../../_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';

const ExportButton = {
    restrict: 'E',
    scope: {
        modeOverride: '='
    },
    template: exportTemplate,
    controller: ExportCtrl
};

angular.module('app').component('exportButtonComponent', ExportButton);

ExportCtrl.$inject = [
    '$scope',
    '$window',
    '$timeout',
    'messagesService',
    'widgetsApiService',
    'filterState',
    '$state',
    'exportProgressService',
    'notificationService',
    'exportInfoService',
    'authorizationService',
    'googleAppClientId'
];

function ExportCtrl(
    $scope,
    $window,
    $timeout,
    msg,
    widgetsApiService,
    filterState,
    $state,
    exportProgressService,
    notificationService,
    exportInfoService,
    authorizationService,
    googleAppClientId
) {
    const daysPerSecond = 120; // constant used to determine how many days equals a second add to the formula
    const usersPerSecond = 150; // constant used to determine how many users equals a second add to the formula
    const maxSeconds = 1; // the largest allowed number of seconds between percent add
    const minSeconds = 0.1; // the smallest allowed number of seconds between percent add
    const productivityEnabledRoutes = [
        'app.reports.topapplications',
        'app.reports.topwebsites',
        'app.screenshots.history'
    ];

    const getCacheKey = function () {
        const cacheKey = exportInfoService.getCacheKey();
        return cacheKey
            ? {
                  cacheKey: cacheKey
              }
            : undefined;
    };

    const getSecondaryParameters = function () {
        return exportInfoService.getSecondaryParameters();
    };

    const getSortParameters = function () {
        // TODO: Implement this for all reports below:
        return exportInfoService.getSortParameters();
    };

    // Added to fix an issue with the storage export expecting a specific range value.  Will be removed once the storage page export is fully realized.
    function tempFixStorageRange(args, range) {
        const rangeIndex = args.indexOf('range=');
        let endIndex = args.indexOf('&', rangeIndex);

        if (endIndex === -1) {
            endIndex = args.indexOf('"', rangeIndex);
        }

        let newArgs = args.substring(0, rangeIndex + 6) + range;

        if (endIndex !== -1) {
            newArgs += args.substring(endIndex, args.length);
        }

        return newArgs;
    }

    const { activityAllowed = false } = getPrivacySettings();
    $scope.activityAllowed = activityAllowed;

    $scope.export = function (event, range, mode, params, toGoogle) {
        const { dates } = getReportFilters();
        const { fromDate, toDate } = dates.getDates();
        const from = moment(fromDate);
        const to = moment(toDate);
        const numberOfDays = moment.duration(to.diff(from)).asDays() + 1; // number of days in timespan of report
        const { usedLicenses } = getAccountSettings();
        const numberOfUsers = usedLicenses; // number of users in the account
        const secondsBetweenPercentAdd =
            Math.min(Math.max(numberOfDays / daysPerSecond + numberOfUsers / usersPerSecond, minSeconds), maxSeconds) *
            1000;

        function addExportPercent() {
            if (exportProgressService.realProgress()) {
                return;
            }

            const progress = Math.min(exportProgressService.exportProgress() + 1, 75);

            if (progress < 75) {
                exportProgressService.exportProgress(progress);
                exportProgressService.timeoutPromises($timeout(addExportPercent, secondsBetweenPercentAdd));
            }
        }

        function addFakeExportPercent() {
            exportProgressService.exportId(exportProgressService.exportProgress());
            const progress = Math.min(exportProgressService.exportProgress() + 1, 100);

            if (progress < 100) {
                exportProgressService.exportProgress(progress);
                exportProgressService.timeoutPromises($timeout(addFakeExportPercent, secondsBetweenPercentAdd));
            }
        }

        exportProgressService.showProgressBar(true);
        event.preventDefault();
        exportInfoService.setIsExporting(true);

        params = params || {};

        let args = generateParameters(getReportFilters(), {
            modeOverride: $scope.modeOverride,
            showProductivity: productivityEnabledRoutes.includes($state.current.name)
        });
        const page = exportInfoService.getMainDataSource().page();
        const pageSize = exportInfoService.getMainDataSource().pageSize();

        params.exportRange = range;
        params.page = page;
        params.exportMode = mode || 'users';
        params.pageSize = pageSize;
        params.groupBy = $scope.getGroupBy() || 'users';

        if ($scope.reportName() === 'storage') {
            args = tempFixStorageRange(args, range);
        }

        if (range === 'current' || $scope.reportName() === 'storage') {
            angular.extend(params, getCacheKey());
        }
        angular.extend(params, getSecondaryParameters());
        angular.extend(params, getSortParameters());

        if (widgetsApiService.isFEAPIExport($scope.reportName())) {
            widgetsApiService
                .getReport($scope.reportName(), args, {
                    params: params
                })
                .success(function (result) {
                    if (!!result && result.error === null) {
                        exportProgressService.setExportProgress(result.id, $scope.reportName(), $scope.exportStatus());
                    } else {
                        exportProgressService.clearExportProgress();
                        notificationService.showNotification(
                            result?.error ? result.error : msg.get('exportError'),
                            'danger'
                        );
                    }
                });
            $timeout(addExportPercent, secondsBetweenPercentAdd);
        } else {
            if ($scope.reportName() !== 'screenshots') {
                exportProgressService.cancelInProgress(undefined);
                exportProgressService.setExportProgress('1', $scope.reportName(), $scope.exportStatus());
                exportProgressService.timeoutPromises($timeout(addFakeExportPercent, secondsBetweenPercentAdd));
            }

            const generateBodyParameters = () => {
                const {
                    users,
                    dates: { getDates },
                    dateRange,
                    productivity
                } = getReportFilters();
                const { fromDate, toDate } = getDates();
                const user = users.length > 0 ? users[0] : { userId: '', userType: '', filterMode: '' };
                const result = {
                    FromDate: encodeURIComponent(moment(fromDate).format('YYYY-MM-DD')),
                    ToDate: encodeURIComponent(moment(toDate).format('YYYY-MM-DD')),
                    UserId: user.userId,
                    userType: user.userType,
                    UserMode: user.filterMode,
                    Range: dateRange
                };

                if (productivityEnabledRoutes.includes($state.current.name)) {
                    result.Productivity = productivity;
                }

                return result;
            };

            if (toGoogle) {
                const bodyData = generateBodyParameters();
                bodyData.Token = params.accessToken;
                bodyData.page = params.page;
                bodyData.pageSize = params.pageSize;
                bodyData.exportRange = params.exportRange;
                bodyData.exportMode = params.exportMode;
                bodyData.slider = params.slider;
                bodyData.sort = params.sort?.[0];
                bodyData.filter = params.filter;
                bodyData.groupBy = params.groupBy;
                widgetsApiService
                    .getReportV2Google($scope.reportName(), args, bodyData)
                    .success(function (result) {
                        exportProgressService.setExportProgress(
                            result.id,
                            $scope.reportName(),
                            $scope.exportStatus(),
                            result
                        );
                    })
                    .catch(function () {
                        notificationService.showNotification(msg.get('exportError'), 'danger');
                        exportProgressService.resetExportProgress();
                    });
            } else {
                widgetsApiService
                    .getReportV2($scope.reportName(), args, {
                        params: params
                    })
                    .then(function (result) {
                        exportProgressService.setExportProgress(
                            result.data.id ?? '1',
                            $scope.reportName(),
                            $scope.exportStatus(),
                            result
                        );
                        exportProgressService.cancelInProgress();
                    })
                    .catch(function () {
                        notificationService.showNotification(msg.get('exportError'), 'danger');
                        exportProgressService.resetExportProgress();
                    });
            }
        }
    };

    $scope.isExporting = function () {
        return exportInfoService.getIsExporting();
    };

    const googleLoginWithScope = function (scope) {
        const promise = new Promise(function (resolve, reject) {
            const client = window.google.accounts.oauth2.initTokenClient({
                client_id: googleAppClientId,
                scope: scope,
                callback: function (response) {
                    if (response.error) {
                        reject(response.error);
                    } else {
                        resolve(response);
                    }
                }
            });
            client.requestAccessToken();
        });

        return promise;
    };

    $scope.exportToGoogle = function (event, range, mode) {
        event.preventDefault();

        if ($scope.calculateHere && $scope.columns * $scope.total + 1 >= 2000000) {
            notificationService.showNotification(
                msg.get('alertMessage_exportTooLarge', Math.round(2000000 / $scope.columns) - 1),
                'danger',
                0
            );
            return;
        }

        const scope = 'https://www.googleapis.com/auth/drive';
        googleLoginWithScope(scope)
            .then(function (data) {
                const params = {
                    accessToken: data.access_token
                };
                exportProgressService.completeCallbackType('google');
                $scope.export(event, range, mode, params, true);
            })
            .catch(function (err) {
                console.error('login with scope failed', err);
                const exportType = event.target.parentNode.parentNode.parentNode.childNodes[1].innerText;

                notificationService.showNotification(
                    'Unable to export "' +
                        exportType +
                        '" report to Google. Google exports is not available in Incognito mode.',
                    'danger'
                );
            });
    };

    $scope.getGroupBy = function () {
        return filterState.getGroupBy();
    };

    const isPaidFunctionalityEnabled = authorizationService.hasFeature('isPaidFunctionalityEnabled');
    const isExportDataEnabled = authorizationService.hasFeature('isExportDataEnabled');

    $scope.exportClick = function () {
        if ($scope.reportName() === 'screenshots' && !isPaidFunctionalityEnabled) {
            const upgradeMessage = authorizationService.hasRouteByName('app.account.upgrade')
                ? '<a href="#/app/account/upgrade"><strong> Click here to upgrade.</strong></a>'
                : '';
            notificationService.showNotification(
                'This feature is available to paid subscribers only.' + upgradeMessage,
                'info',
                0,
                true
            );
        }
    };

    const allowedReports = ['activitylog', 'alarmlog', 'auditlog', 'video'];

    $scope.showExportMenu = function () {
        const reportName = $scope.reportName();
        const canExport = allowedReports.includes(reportName) && isExportDataEnabled;
        const canExportScreenshots = isPaidFunctionalityEnabled && isExportDataEnabled;
        return reportName === 'screenshots' ? canExportScreenshots : canExport;
    };

    $scope.exportText = function () {
        const msgName = $state.current.data.exportText || 'export';
        return msg.get(msgName);
    };

    $scope.exportStatus = function () {
        const msgName = ($state.current.data.reportName || 'export') + '_status';
        return msg.get(msgName);
    };

    $scope.reportName = function () {
        const msgName = $state.current.data.reportName || 'export';
        return msg.get(msgName);
    };

    $scope.menuDirection = 'left';
    $scope.submenuDirection = 'left';

    $scope.$on('setGoogleExportParameters', function (event, data) {
        $scope.calculateHere = data.calculateHere;
        $scope.columns = data.columns;
        $scope.total = data.total;
    });

    const largeExportReports = [
        'app.reports.topusers',
        'app.reports.topapplications',
        'app.reports.topwebsites',
        'app.reports.activitylog',
        'app.reports.alarmlog',
        'app.account.audit'
    ];

    const noLimitExportReports = ['app.videos.history', 'app.screenshots.history'];
    const isLargeExportEnabled = authorizationService.hasFeature(BundleFlag.LargeExport);
    const resultsCount = isLargeExportEnabled && largeExportReports.includes($state.current.name) ? '50,000' : '10,000';
    $scope.exportsLimitedTooltip = noLimitExportReports.includes($state.current.name)
        ? null
        : msg.get('limitedTooltip', 'Exports', resultsCount);

    $scope.$watch(
        function getExportButtonRightOffset() {
            var exportButton = $('.export-button');
            var offset = exportButton.offset();
            return offset ? $window.innerWidth - (exportButton.offset().left + exportButton.innerWidth()) : 0;
        },
        function (newValue) {
            $scope.menuDirection = newValue > 320 ? 'left' : 'right';
            $scope.subMenuDirection = newValue > 179 ? 'right' : 'left';
        }
    );

    $scope.$watch(
        function () {
            return exportProgressService.showProgressBar();
        },
        function (showProgressBar) {
            exportInfoService.setIsExporting(showProgressBar);
        }
    );
}

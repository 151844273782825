import { getAccountSettings } from '_reactivtrak/src/common/stores/accountSettingsStore/accountSettingsStore';
import { userServiceFunctions } from '_app/serviceFunctions/userServiceFunctions';
import { analyticEventServiceFunctions } from '_app/serviceFunctions/analyticEventServiceFunctions';
import version from '../../../../version.json';
import { addDataDogContext, startDataDogRumSessionRecording } from '_reactivtrak/src/common/config/dataDog';
import lodash from 'lodash';
import { getAccountPlanStore } from '../../../../_reactivtrak/src/common/stores/accountPlanStore';
import { profileStore } from '../../../../_reactivtrak/src/common/services/Profile/useProfileState';
import { Role } from '../../../../_reactivtrak/src/common/enums';

angular.module('app').service('analyticsService', AnalyticsService);

AnalyticsService.$inject = [
    'url',
    '$timeout',
    '$injector',
    '$rootScope',
    '$window',
    'envService',
    'authorizationService',
    'templateServiceFunctions'
];

function AnalyticsService(
    url,
    $timeout,
    $injector,
    $rootScope,
    $window,
    envService,
    authorizationService,
    templateServiceFunctions
) {
    const service = this;
    let intercomBooted = false;

    // Find first GA tracker name and append to function name
    function createTrackerBasedName(name) {
        if (typeof $window.ga.getAll !== 'function') {
            return name;
        }

        // Retrieve all trackers
        const trackers = $window.ga.getAll();
        // Get name of first tracker
        const trackerName = (trackers && trackers[0] && trackers[0].get('name')) || '';
        // Append to function name and return
        return trackerName + '.' + name;
    }

    function isDefinedOnWindow(field) {
        if ($window[field]) {
            return true;
        }

        console.info('ActivTrak Info: An analytics library was not found on the window object for ' + field + '.');

        return false;
    }

    function trackIntercomPromoEvent(page, validation = null) {
        return function () {
            if (validation && typeof validation === 'function') {
                if (!validation()) {
                    return;
                }
            }
            if ($window.Intercom) {
                $window.Intercom('trackEvent', 'view-promo-page', { promo: page });
            }
        };
    }
    const { isDataConsoleEnabled } = getAccountSettings();

    this.promoPagesEvents = [
        {
            state: 'app.coachingpromo',
            runEvent: trackIntercomPromoEvent('coaching')
        },
        {
            state: 'app.reports.insightsPromo',
            runEvent: trackIntercomPromoEvent('insights')
        },
        {
            state: 'app.teampulsepromo',
            runEvent: trackIntercomPromoEvent('team pulse')
        },
        {
            state: 'app.activConnect',
            runEvent: trackIntercomPromoEvent('integrations', function () {
                return !isDataConsoleEnabled;
            })
        },
        {
            state: 'app.screenshots.redaction',
            runEvent: trackIntercomPromoEvent('redaction')
        }
    ];

    $rootScope.$on('$stateChangeSuccess', function (e, toState) {
        if (
            service.promoPagesEvents.some(function (item) {
                return item.state === toState.name;
            })
        ) {
            service.promoPagesEvents
                .find(function (item) {
                    return item.state === toState.name;
                })
                .runEvent();
        }

        try {
            if (isDefinedOnWindow('ga')) {
                const url = '/app' + toState.url.split('?')[0];
                const title = toState.data.pageTitle;

                $window.ga(createTrackerBasedName('set'), {
                    page: url,
                    title: title
                });
                $window.ga(createTrackerBasedName('send'), 'pageview', {
                    page: url,
                    title: title
                });
            }
        } catch (error) {
            angular.noop();
        }
    });

    function generateTraits() {
        const { firstName, lastName, creator, phone, unixCreationTime, account, totalLicenses, companyName } =
            getAccountSettings();
        const { username } = profileStore.getState().profile;
        const profileService = $injector.get('profileService');
        const { planTypeLabel, planCode, planRenewDate } = getAccountPlanStore();
        const traits = {
            name: profileService.profileInfo.firstName + ' ' + profileService.profileInfo.lastName,
            firstName: profileService.profileInfo.firstName,
            lastName: profileService.profileInfo.lastName,
            email: username,
            phone: profileService.profileInfo.phone,
            profileCreated: profileService.profileInfo.creationDate,
            creatorFirstName: firstName,
            creatorLastName: lastName,
            creatorEmail: creator,
            creatorPhone: phone,
            accountCreatedAt: unixCreationTime,
            accountId: 'Acct' + account,
            plan: totalLicenses?.toString(), // plan is an old field that referenced license count instead of plan type in Intercom
            userType: authorizationService.getUserType(),
            role: profileService.profileInfo.title,
            department: profileService.profileInfo.industry,
            planRenewDate: planRenewDate,
            planType: planTypeLabel,
            planCode: planCode,
            // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
            user_fields: {
                accountid: 'Acct' + account
            },
            // jscs:enable requireCamelCaseOrUpperCaseIdentifiers
            company: {
                id: 'Acct' + account,
                name: companyName,
                createdAt: unixCreationTime,
                plan: totalLicenses?.toString()
            }
        };

        return traits;
    }

    function updateIntercom(traits) {
        const intercomTraits = traits || generateTraits();
        if (isDefinedOnWindow('Intercom') && intercomTraits.firstName && intercomTraits.lastName) {
            $window.Intercom('update', intercomTraits);
        }
    }

    this.bootIntercom = function (traits) {
        const isImpersonating = userServiceFunctions.getIsImposter();

        if (
            isDefinedOnWindow('Intercom') &&
            !authorizationService.hasRole([
                Role.SuperAdmin,
                Role.SupportBasic,
                Role.CommandCenter,
                Role.CommandCenterEnterprise
            ]) &&
            !isImpersonating &&
            !import.meta.env.DEV // not running development server
        ) {
            const { intercommHash } = getAccountSettings();
            const { username } = profileStore.getState().profile;
            $window.Intercom('boot', {
                app_id: import.meta.env.VITE_INTERCOM_APP_ID,
                name: traits.name, // Full name
                email: traits.email, // Email address,
                user_id: username,
                created_at: traits.accountCreated, // Signup date as a Unix timestampm
                user_hash: intercommHash
            });

            updateIntercom(traits);
            intercomBooted = true;
        }
    };

    this.identify = function () {
        const { unixCreationTime, account, totalLicenses, usedLicenses, userId } = getAccountSettings();
        const { username } = profileStore.getState().profile;
        // Don't identify New Administrator
        if (username === 'New Administrator') {
            return;
        }

        let email = username;
        let userToken;
        let supportDelegateClaim;

        if (authorizationService.hasRole([Role.SuperAdmin])) {
            userToken = userServiceFunctions.getUserToken();
            supportDelegateClaim = lodash.find(
                userToken && userToken.decoded && userToken.decoded.Claims,
                function (claim) {
                    return claim.Type === 'http://activtrak.com/superadmindelegate';
                }
            );
            email =
                supportDelegateClaim && supportDelegateClaim.Value
                    ? supportDelegateClaim.Value + '(' + username + ')'
                    : username;
        } else if (authorizationService.hasRole([Role.SupportBasic])) {
            userToken = userServiceFunctions.getUserToken();
            supportDelegateClaim = lodash.find(
                userToken && userToken.decoded && userToken.decoded.Claims,
                function (claim) {
                    return claim.Type === 'http://activtrak.com/supportdelegate';
                }
            );
            email += supportDelegateClaim && supportDelegateClaim.Value ? '(' + supportDelegateClaim.Value + ')' : '';
        } else if (
            authorizationService.hasRole([Role.SupportBasic, Role.CommandCenter, Role.CommandCenterEnterprise])
        ) {
            userToken = userServiceFunctions.getUserToken();
            supportDelegateClaim = lodash.find(
                userToken && userToken.decoded && userToken.decoded.Claims,
                function (claim) {
                    return claim.Type === 'http://activtrak.com/supportdelegate';
                }
            );
            email +=
                '(Command Center Parent' +
                (supportDelegateClaim && supportDelegateClaim.Value ? ' - ' + supportDelegateClaim.Value + ')' : ')');
        }

        if (userId !== '') {
            if (isDefinedOnWindow('ga')) {
                $window.ga(createTrackerBasedName('set'), 'userId', userId);
            }

            if (isDefinedOnWindow('heap')) {
                $window.heap.identify(userId);
                const profileService = $injector.get('profileService');
                const { planTypePeriodLabel, planCode } = getAccountPlanStore();
                let creationDuration;
                if (unixCreationTime && unixCreationTime > 0) {
                    const creationDateObject = moment.unix(unixCreationTime);
                    creationDuration = templateServiceFunctions.getCreationDuration(creationDateObject);
                }

                let accountAge = 'Undefined';
                if (creationDuration && creationDuration.seconds() > 0) {
                    accountAge = (creationDuration.days() + creationDuration.hours() / 24).toFixed(2);
                }

                const userType = authorizationService.getUserType();
                const userRoles = getAccountSettings()?.roles?.join(', ') || 'Undefined';

                const properties = {
                    'Account Number': account,
                    Name: profileService.profileInfo.firstName + ' ' + profileService.profileInfo.lastName,
                    'Plan Type': planTypePeriodLabel,
                    'Plan Code': planCode,
                    'Total Licenses': totalLicenses,
                    'Used Licenses': usedLicenses,
                    'Account Age (Days)': accountAge,
                    'User Type': userType,
                    'User Roles': userRoles,
                    'App Version': (version && version.version) || 'x.x.x'
                };

                $window.heap.clearEventProperties();
                $window.heap.addEventProperties(properties);
                // Leave lowercase for Heap to recognize
                properties.email = email;
                $window.heap.addUserProperties(properties);
            }
        }

        // Intercom
        const traits = generateTraits();
        this.bootIntercom(traits);
        this.showIntercom(traits);

        // DataDog
        addDataDogContext(account, email);
        startDataDogRumSessionRecording(authorizationService.hasFeature('isRumSessionReplayEnabled'), envService.get());
    };

    // Account Creation Event Analytics
    this.accountCreated = function (isGoogleAuthorised) {
        const { account, userId } = getAccountSettings();
        if (isDefinedOnWindow('ga')) {
            $window.ga(createTrackerBasedName('set'), 'userId', userId);
            $window.ga(createTrackerBasedName('send'), 'event', 'Account', 'Created', account);
            $window.dataLayer.push({ event: 'account-signup' });
        }

        analyticEventServiceFunctions.newEvent('Created', {
            category: 'Account',
            label: account,
            page: url.GetLocationWithUtm(),
            googleAuthorized: isGoogleAuthorised
        });
    };

    this.accountLogin = function () {
        const { account } = getAccountSettings();
        if (isDefinedOnWindow('ga')) {
            $window.ga(createTrackerBasedName('send'), 'event', 'Account', 'Login', account);
        }

        analyticEventServiceFunctions.newEvent('Login', {
            category: 'Account',
            label: account,
            page: url.GetLocationWithUtm()
        });
    };

    this.accountLogout = function () {
        const { account } = getAccountSettings();
        if (isDefinedOnWindow('Intercom')) {
            //TODO: Intercom seems to be breaking here
            $window.Intercom('shutdown');
        }

        if (isDefinedOnWindow('ga')) {
            $window.ga(createTrackerBasedName('send'), 'event', 'Account', 'Logout', account);
        }

        analyticEventServiceFunctions.newEvent('Logout', {
            category: 'Account',
            label: account,
            page: url.GetLocationWithUtm()
        });
    };

    this.startedCheckoutProcess = function () {
        const { account, totalLicenses, usedLicenses } = getAccountSettings();
        const { planTypePeriodLabel } = getAccountPlanStore();
        analyticEventServiceFunctions.newEvent('StartedCheckout', {
            category: 'Account',
            label: account,
            Plan: planTypePeriodLabel,
            'Total Licenses': totalLicenses,
            'Used Licenses': usedLicenses
        });
    };

    // Account Upgrade Analytics
    this.upgrade = function () {
        const { account } = getAccountSettings();
        if (isDefinedOnWindow('ga')) {
            $window.ga(createTrackerBasedName('send'), 'event', 'Account', 'Upgraded', account);
        }

        const { planTypePeriodLabel, totalLicenses } = getAccountPlanStore();
        analyticEventServiceFunctions.newEvent('Upgraded', {
            category: 'Account',
            label: account,
            page: url.GetLocationWithUtm(),
            plan: planTypePeriodLabel,
            licensesCount: totalLicenses
        });
    };

    // Page event analytics
    this.page = function (/* data */) {
        if (isDefinedOnWindow('Intercom')) {
            // Intercom
            $window.Intercom('update');
        }
    };

    // Account Verification Analytics
    this.accountVerified = function (accountId) {
        if (isDefinedOnWindow('ga')) {
            $window.ga(createTrackerBasedName('send'), 'event', 'Account', 'Verified', accountId);
        }

        analyticEventServiceFunctions.newEvent('Verified', {
            category: 'Account',
            label: accountId,
            page: url.GetLocationWithUtm()
        });

        if (isDefinedOnWindow('Intercom')) {
            $window.Intercom('trackEvent', 'email-verified');
        }
    };

    this.hideIntercom = function () {
        angular.element(document).find('body').removeClass('at-show-intercom');
    };

    this.updateIntercom = function (traits) {
        updateIntercom(traits);
    };

    this.showIntercom = function bootWait(traits) {
        if (intercomBooted) {
            angular.element(document).find('body').addClass('at-show-intercom');
            updateIntercom(traits);
        } else {
            $timeout(bootWait, 10, true, traits);
        }
    };
}

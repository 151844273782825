import { userServiceFunctions } from '_app/serviceFunctions/userServiceFunctions';
import { authModule } from 'activtrak-ui-utilities';

var { authenticatedUserService } = authModule;

angular.module('app').service('notificationService', NotificationService);

NotificationService.$inject = [
    '$rootScope',
    '$timeout',
    '$document',
    'notificationApiService',
    'messagesService',
    'atHelperFunctions',
    'browserServiceFunctions',
    '$injector',
    'authorizationService'
];

function NotificationService(
    $rootScope,
    $timeout,
    $document,
    notificationApiService,
    msg,
    atHelperFunctions,
    browserServiceFunctions,
    $injector,
    authorizationService
) {
    var service = this;

    this.allowPoll = false;
    this.timeoutDelay = 20000;
    var currentNotifications = [];

    function findWithAttr(array, attr, value) {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    function _showNotification(notification, onClosed) {
        var notificationBar = angular.element($document[0].body).pgNotification({
            style: 'bar',
            message: notification.message,
            position: 'top',
            timeout: notification.timeout,
            type: notification.color
        });

        notificationBar.show();
        notificationBar.options.onClosed = function () {
            var notification = this;
            if (notification && notification.message) {
                var existingNotification = currentNotifications.find(function (n) {
                    return n.message === notification.message;
                });
                if (existingNotification) {
                    _removeNotification(existingNotification);
                }
            }

            if (onClosed && typeof onClosed === 'function') {
                onClosed();
            }
        };

        $rootScope.$on('$stateChangeStart', function () {
            if (notification.timeout === 0) {
                notificationBar.notification.remove();
                notificationBar.options.onClosed();
            }
        });
    }

    function _processNotification(notification, onClosed) {
        var existingNotification = currentNotifications.find(function (n) {
            return n.message === notification.message;
        });

        if (!existingNotification) {
            currentNotifications.push(notification);
            _showNotification(notification, onClosed);
            if (notification.timeout > 0) {
                $timeout(
                    function () {
                        _removeNotification(notification);
                    },
                    notification.timeout,
                    false
                );
            }
        }
    }

    function _removeNotification(notification) {
        var index = findWithAttr(currentNotifications, 'message', notification.message);

        if (index >= 0) {
            currentNotifications.splice(index, 1);
        }
    }

    // Handles the stackable notification messages
    this.showNotification = function (message, color, timeout, skipEscape, onClosed) {
        if (typeof timeout === 'undefined') {
            timeout = 3000;
        }

        if (typeof skipEscape === 'undefined' || skipEscape === false) {
            message = browserServiceFunctions.htmlEscape(message);
        }

        _processNotification(
            {
                message: message,
                color: color,
                timeout: timeout
            },
            onClosed
        );
    };

    var lastLogin = null;

    function poll() {
        notificationApiService
            .getState()
            .then(function (result) {
                result = result.data;
                if (
                    !authorizationService.hasRole([
                        authorizationService.roles.supportBasic,
                        authorizationService.roles.superAdmin
                    ])
                ) {
                    if (!atHelperFunctions.isEmpty(result.reason)) {
                        var loginService = $injector.get('loginService');
                        var notImpersonated = !userServiceFunctions.getIsImposter();
                        if (notImpersonated) {
                            var notSupport = !userServiceFunctions.getCCImposter();
                            if (notSupport) {
                                loginService.logout({
                                    maintenanceModeReason: result.reason
                                });
                            }
                        }
                    }
                    lastLogin = {
                        date: result.lastLogin.lastLoginDate,
                        ip: result.lastLogin.lastLoginIp
                    };

                    authenticatedUserService.checkLastLogin(lastLogin);
                }

                $rootScope.$broadcast('DeleteInProgress', result.progress);
                $rootScope.$broadcast('PreAggregationInProgress', result.preAggregationInProgress);
            })
            .catch(function (error) {
                //TODO: need to rethink this - but for now, suspend polling on 401
                //401 triggers a reload - doesn't allow for proper cleanup
                if (error.status === 401) {
                    service.allowPoll = false;
                    $timeout.cancel(service.timeout);
                }
            });

        if (service.allowPoll) {
            setPollTimeout();
        }
    }

    function setPollTimeout() {
        service.timeout = $timeout(
            function () {
                poll();
            },
            service.timeoutDelay,
            false
        );
    }

    function showNewLoginNotification() {
        var lastLoginDate = atHelperFunctions.formatDate(lastLogin.date, {
            span: false
        });
        service.showNotification(
            `A new login has been detected at ${lastLoginDate} with the IP address of ${lastLogin.ip}.`,
            'info',
            0,
            false,
            function () {
                authenticatedUserService.setShowNewLoginNotification(false);
            }
        );
    }

    var showNewLoginNotificationSubject = authenticatedUserService.getShowNewLoginNotification();
    showNewLoginNotificationSubject.subscribe(function (value) {
        if (value) {
            showNewLoginNotification();
        }
    });

    this.init = function () {
        service.allowPoll = true;
        poll();
    };

    this.cancelPolling = function () {
        service.allowPoll = false;
        $timeout.cancel(service.timeout);
    };
}

angular.module('app').service('notificationApiService', NotificationApiService);

NotificationApiService.$inject = ['$injector', 'envConfig'];

function NotificationApiService($injector, envConfig) {
    var $http = $injector.get('$http');

    this.getState = function () {
        return $http.get(envConfig.websocketHttpsUrl() + '/api/settings/storage/in-progress', {
            ignoreLoadingBar: true
        });
    };
}

import { styled } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import {
  gray0,
  gray4,
  primaryAqua,
  primaryColor,
  primaryFontSize
} from '../../constants/styleGuide';

export const GridWrapperContainer = styled(Box)`
  /* AgGrid CSS overrides */
  --ag-foreground-color: ${primaryColor};
  --ag-secondary-foreground-color: ${primaryColor};
  --ag-material-primary-color: ${primaryAqua};
  --ag-material-accent-color: ${primaryAqua};
  --ag-checkbox-unchecked-color: ${primaryColor};
  --ag-header-cell-hover-background-color: ${gray0};
  --ag-border-color: ${gray4};

  --ag-font-size: ${primaryFontSize};
  --ag-border-radius: 4px;
  --ag-borders: 1px;

  .ag-root-wrapper {
    border-radius: var(--ag-border-radius);
    border: var(--ag-borders) solid var(--ag-border-color);
  }

  .ag-checkbox-input-wrapper {
    color: var(--ag-material-accent-color);
  }

  .ag-checkbox-input-wrapper.ag-indeterminate::after {
    color: var(--ag-material-accent-color);
  }

  .ag-header-cell-comp-wrapper {
    font-weight: 500;
    line-height: 1.25;

    & p,
    span {
      font-weight: 500;
      line-height: 1.25;
    }
  }

  .ag-header-cell-text {
    font-size: ${primaryFontSize};
  }

  /*
  AG-Grid css only uses a pointer if the column is sortable.
  This makes sure it always shows - i.e. column moving without sorting
  */
  .ag-header-cell-label {
    cursor: pointer;
  }

  /*
  'resize' divider style should be controlled by '.ag-header-cell-resize::after',
  but something is preventing ::after from rendering
  */
  .ag-header-cell-resize {
    border-left: 1px solid var(--ag-border-color);
  }

  .ag-overlay {
    pointer-events: auto;
    margin-top: 57px;
    background-color: ${gray0};
  }

  .ag-overlay-wrapper {
    display: block;
    padding-top: 0 !important;
  }
`;

export const LoaderContainer = styled('div')`
  width: 100%;
  overflow: hidden;
  height: auto;
  padding-top: 56px;
  background-color: ${gray0};
`;

export const SkeletonSC = styled(Skeleton)`
  height: 32px !important;
`;

export const SkeletonLoaderRowsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${gray4}`,
  height: '52px'
});

export const SkeletonLoaderCheckboxContainer = styled('div')({
  width: '23px',
  margin: '5px 20px'
});

export const SkeletonLoaderContainer = styled('div')({
  flex: 1,
  margin: '5px 20px'
});

import React, { useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { DownloadProvider } from '../../../common/services';
import { getScheduleAdherenceColumns } from '../stores/scheduleAdherenceColumns.store';
import { getReportView } from '../../../common/components/ReportFilters/stores/reportViewStore';
import { ScheduleAdherenceReportHeader } from '../components/ScheduleAdherenceReportHeader';
import ScheduledTimelineFilter from '../components/ScheduledTimelineFilter';
import { ReactivTrakComponentWrapper } from '../../../ReactivTrakComponentWrapper';
import { ScheduleAdherenceColumnSelector } from '../components/ScheduleAdherenceColumnSelector';
import { ReportViewToggle } from '../../../common/components/ReportFilters/components/ReportViewToggle';
import { ScheduleAdherenceExport } from '../components/ScheduleAdherenceExport';
import { ScheduleAdherenceGrid } from '../components/ScheduleAdherenceGrid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export const ScheduleAdherenceReportView = () => {
  const gridRef = useRef<AgGridReact>(null);

  const handleColumnUpdate = useCallback(() => {
    const { view: currentView } = getReportView();
    const currentColumnState = getScheduleAdherenceColumns();

    gridRef.current.api?.applyColumnState({
      state: currentColumnState[currentView],
      //needed when columns are re-ordered
      applyOrder: true
    });
  }, []);

  return (
    <>
      <ScheduleAdherenceReportHeader />
      <Stack
        direction="row"
        alignItems="center"
        sx={{ mt: 4, mb: 2 }}
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <ReportViewToggle onChange={handleColumnUpdate} />
          <ScheduledTimelineFilter />
        </Box>
        <Box display="flex" alignItems="center">
          <Box sx={{ mr: { xs: 0, md: 1 } }}>
            <ScheduleAdherenceColumnSelector onSubmit={handleColumnUpdate} />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' }, mr: 0 }}>
            <ScheduleAdherenceExport />
          </Box>
        </Box>
      </Stack>
      <ScheduleAdherenceGrid
        gridRef={gridRef}
        onColumnUpdate={handleColumnUpdate}
      />
    </>
  );
};

export const ScheduleAdherenceReportViewComponent = () => (
  <ReactivTrakComponentWrapper>
    <DownloadProvider>
      <ScheduleAdherenceReportView />
    </DownloadProvider>
  </ReactivTrakComponentWrapper>
);

//https://www.ag-grid.com/react-data-grid/getting-started/
import React, { useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridOptions } from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.min.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-material.min.css'; // Optional Theme applied to the Data Grid
import { GridWrapperContainer } from './atGrid.styles';
import { AtGridProps } from './atGrid.models';
import { AtGridLoader } from './AtGridLoader';

// DO NOT ADD NEW PROPS WITHOUT CONSULTING THE UI TEAM
const AtGrid = <T,>(props: AtGridProps<T>) => {
  const {
    gridRef,
    selectedColumnFields,
    gridWrapperStyles,
    gridOptionOverrides
  } = props;

  const sx = { ...{ height: '65vh' }, ...gridWrapperStyles };
  const includeCheckboxesInLoader =
    gridOptionOverrides?.selection?.mode === 'multiRow' ||
    gridOptionOverrides?.selection?.mode === 'singleRow';

  const defaultGridOptions: GridOptions = useMemo<GridOptions>(
    () => ({
      suppressCellFocus: true, // NOTE: This just removes ability to focus each cell. Cell editing can still happen, if needed.
      animateRows: false,
      suppressRowHoverHighlight: true,
      loadingOverlayComponent: AtGridLoader,
      loadingOverlayComponentParams: {
        height: sx.height,
        columnCount: selectedColumnFields?.length,
        includeCheckboxes: includeCheckboxesInLoader
      }
    }),
    [includeCheckboxesInLoader, selectedColumnFields?.length, sx.height]
  );

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      cellStyle: { alignContent: 'center' },
      headerComponentParams: {
        template: `<div class="ag-cell-label-container" role="presentation">
                  <div data-ref="eLabel" class="ag-header-cell-label" role="presentation">
                    <span data-ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                    <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                    <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                    <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                    <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                  </div>
                </div>`
      }
    };
  }, []);

  return (
    <GridWrapperContainer className="ag-theme-material" sx={sx}>
      <AgGridReact
        ref={gridRef}
        columnDefs={selectedColumnFields}
        defaultColDef={defaultColDef}
        gridOptions={{ ...defaultGridOptions, ...gridOptionOverrides }}
      />
    </GridWrapperContainer>
  );
};

export default AtGrid;

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import { getAllEntities } from '../../../stores/entityStore/entityStore';
import { useEntityStore } from '../../../stores/entityStore/entityStore.hooks';
import {
  createGroupComboList,
  renderIcon,
  renderIconFromUserFilter,
  validateUserFilter
} from '../../../stores/entityStore/entityStore.utils';
import { USER_COMPUTER_FILTER_TABS } from '../constants/userComputerFilterTabs';
import {
  IReportFilters,
  IUserComputerFilterProps
} from '../models/IReportFilters';
import {
  EntitySource,
  EntityType,
  IEntity
} from '../../../stores/entityStore/entityStore.models';
import { GroupIconSource } from '../../../assets/Icons';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { TabPanel } from '../../Tabs/TabPanel';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemButton from '@mui/material/ListItemButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { TextOverflow } from '../../../styles/app.component.styles';
import {
  ListSkeletonLoaderContainer,
  ListSkeletonSC,
  UsersComputersTooltipSC
} from '../styles';
import { resetReportFilterToDefault } from '../hooks/reportFiltersStore';
import { ReportFilterName } from '../constants/reportFilterName';

const UserComputerLoader = () => {
  return (
    <Box sx={{ margin: '0 16px' }}>
      <ListSkeletonLoaderContainer>
        <ListSkeletonSC />
      </ListSkeletonLoaderContainer>
      <ListSkeletonLoaderContainer>
        <ListSkeletonSC />
      </ListSkeletonLoaderContainer>
      <ListSkeletonLoaderContainer>
        <ListSkeletonSC />
      </ListSkeletonLoaderContainer>
    </Box>
  );
};

const UserComputerListItem = ({
  entity,
  index,
  handleClick
}: {
  entity: IEntity;
  index: number;
  handleClick: () => void;
}) => {
  return (
    <ListItemButton
      id={`a492aee6-1463-4c4e-83d4-c4e3ec3e88dc-${entity.type.toLowerCase()}-${
        entity.source?.toLowerCase() || 'none'
      }-${entity.mix?.toLowerCase() || 'none'}-${index}`}
      sx={{ display: 'flex', alignItems: 'center' }}
      onClick={handleClick}
    >
      <GroupIconSource iconSource={renderIcon(entity)} />
      <TextOverflow sx={{ pl: 1 }}>{entity.name}</TextOverflow>
    </ListItemButton>
  );
};

const UserComputerNoResultsMsg = ({ tabName }: { tabName: string }) => {
  return (
    <Typography
      sx={{ margin: '0 16px' }}
    >{`No groups or ${tabName} found`}</Typography>
  );
};

export const UserComputerFilter = ({
  onSubmitFilter,
  users
}: IUserComputerFilterProps) => {
  const {
    users: usersList,
    computers: computersList,
    groups: groupsList,
    isLoading
  } = useEntityStore((s) => s);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [tab, setTab] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');

  const debounceSetFilter = useRef(
    debounce((value) => getAllEntities(value), 400)
  ).current;

  useEffect(() => {
    const validateAndResetFilters = async () => {
      const isValid = await validateUserFilter(users);

      if (!isValid) {
        resetReportFilterToDefault(ReportFilterName.Users);
        resetReportFilterToDefault(ReportFilterName.GroupId);
      }
    };

    validateAndResetFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(
    (entity: IEntity, tabName: 'users' | 'computers') => {
      const mappedType =
        entity.source === EntitySource.AllUsers ? entity.source : entity.type;
      const filters: Partial<IReportFilters> = {
        users: [
          {
            userId: entity.id,
            userType: mappedType,
            name: entity.name,
            filterMode: tabName
          }
        ]
      };
      if (mappedType === EntityType.Group) {
        filters.users[0].groupType = entity.mix;
        filters.groupId = [entity.id];
      }
      onSubmitFilter(filters);
      setIsOpen(false);
    },
    [onSubmitFilter]
  );

  const handleTabChange = (
    _event: React.ChangeEvent<object>,
    newTab: number
  ) => {
    setTab(newTab);
  };

  const handleClose = () => {
    handleSearchClear();
    setIsOpen(false);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentSearchText = event.target.value;
    setSearchText(currentSearchText);
    debounceSetFilter(currentSearchText);
  };

  const handleSearchClear = () => {
    setSearchText('');
    getAllEntities();
  };

  const usersGroupList = createGroupComboList(usersList, groupsList, 'user');
  const computersGroupList = createGroupComboList(
    computersList,
    groupsList,
    'computer'
  );

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <UsersComputersTooltipSC
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleClose}
          open={isOpen}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement="bottom-start"
          title={
            <>
              <Tabs value={tab} onChange={handleTabChange}>
                {USER_COMPUTER_FILTER_TABS.map((tab) => (
                  <Tab
                    key={tab.key}
                    id={`7201c609-68e5-4a4a-bba4-0629b998aa9e-${tab.name.toLowerCase()}`}
                    label={tab.name}
                  />
                ))}
              </Tabs>
              <TextField
                id={`31d512eb-a3e0-4077-9f99-1ab8d743803e-${USER_COMPUTER_FILTER_TABS.find(
                  (t) => t?.key === tab
                )?.name.toLowerCase()}`}
                value={searchText}
                onChange={handleSearchChange}
                placeholder="Search..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleSearchClear}
                        sx={{ padding: '4px' }}
                      >
                        <CloseIcon
                          style={{
                            fontSize: '20px'
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                sx={{
                  padding: '0 16px',
                  width: '100%',
                  mb: 2
                }}
              />
              <TabPanel value={tab} index={0}>
                <Box style={{ maxHeight: '250px', overflow: 'auto' }}>
                  {isLoading && <UserComputerLoader />}
                  {!isLoading && usersGroupList.length === 0 && (
                    <UserComputerNoResultsMsg tabName="users" />
                  )}
                  {!isLoading && usersGroupList?.length > 0 && (
                    <List>
                      {usersGroupList.map((entity, i) => (
                        <UserComputerListItem
                          key={`id-${entity.id}-${entity.type}-users`}
                          entity={entity}
                          index={i}
                          handleClick={() => handleClick(entity, 'users')}
                        />
                      ))}
                    </List>
                  )}
                </Box>
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Box style={{ maxHeight: '250px', overflow: 'auto' }}>
                  {isLoading && <UserComputerLoader />}
                  {!isLoading && usersGroupList.length === 0 && (
                    <UserComputerNoResultsMsg tabName="computers" />
                  )}
                  {!isLoading && computersGroupList?.length > 0 && (
                    <List>
                      {computersGroupList.map((entity, i) => (
                        <UserComputerListItem
                          key={`id-${entity.id}-${entity.type}-computers`}
                          entity={entity}
                          index={i}
                          handleClick={() => handleClick(entity, 'computers')}
                        />
                      ))}
                    </List>
                  )}
                </Box>
              </TabPanel>
            </>
          }
        >
          <Button
            variant="outlined"
            onClick={() => setIsOpen(!isOpen)}
            sx={{ maxWidth: '235px' }}
            id="97f80ba9-8ac1-4e13-bf43-8ac423149609"
            startIcon={
              <GroupIconSource
                iconSource={renderIconFromUserFilter(users[0])}
              />
            }
            endIcon={isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
          >
            <TextOverflow>{users[0].name}</TextOverflow>
          </Button>
        </UsersComputersTooltipSC>
      </div>
    </ClickAwayListener>
  );
};

import _ from 'lodash';
import { inMemoryStore } from '../../../../_ui-utils/src';
import { applicationRoles } from '../constants/applicationRoles';
import { IAuthorization } from '../models/IAuthorization';
import { checkArrayIncludes } from '../utils/validation/validation';
import { userServiceFunctions } from '../../../../_app/serviceFunctions/userServiceFunctions';
import { Role, RoleName } from '../enums/Roles';
import { RoleAccessKeys } from '../enums/RoleAccessKeys';
import { AuthLevel } from '../enums/AuthLevel';
import { DashboardListNg } from '../enums/DashboardType';
import { createAuthorizations } from '../services/Authorization/AuthorizationFactory';
import { isRouteRestricted as _isRouteRestricted } from '../services/Authorization/RouteRestrictionsService';
import { IAuthorizations } from '../models/IAuthorizations';
import {
  getRouteAccess,
  getRouteAccessLevel
} from '../stores/userPermissionsStore/userPermissionStore';
import { hasFlag } from '../stores/userPermissionsStore/userPermissionStore.utils';
import { getRouteByName, getRouteByUrl, getRoutes } from '../config/routing';
import { FeatureFlag } from '../enums/FeatureFlag';
import { BundleFlag } from '../enums/BundleFlag';
import { getAccountSettings } from '../stores/accountSettingsStore/accountSettingsStore';

const getAuthorizationService = (): IAuthorization => {
  let _authorizations: IAuthorizations = { roles: [] };

  const setAuthorizations = () => {
    const accountSettings = getAccountSettings();
    _authorizations = createAuthorizations(
      accountSettings,
      userServiceFunctions.getUserTokenClaims()
    );
  };

  //This is just used in Analytics
  const getUserType = (): RoleName => {
    const accountSettings = getAccountSettings();
    if (!accountSettings || !_authorizations || !_authorizations.roles) {
      console.error(
        'ActivTrak Error: Cannot determine user type due to account settings or authorizations missing.'
      );
      return RoleName.Unknown;
    }

    // If creator of the account, return creator
    if (
      accountSettings.creator &&
      accountSettings.username === accountSettings.creator
    ) {
      return RoleName.Creator;
    } else {
      if (hasRole(Role.SupportAdvanced)) {
        return RoleName.SupportAdvanced;
      } else if (hasRole(Role.SupportIntermediate)) {
        return RoleName.SupportIntermediate;
      } else if (hasRole(Role.SupportBasic)) {
        return RoleName.SupportBasic;
      } else if (hasRole(Role.SuperAdmin)) {
        return RoleName.SuperAdmin;
      } else if (hasRole(Role.MultiAccount)) {
        return RoleName.MultiAccount;
      } else {
        const roleName = Object.keys(Role).find(
          (key) =>
            Role[key as keyof typeof Role] ===
            _authorizations.roles[0].toLowerCase()
        );
        return RoleName[roleName];
      }
    }
  };

  const getParentAccount = (): string => {
    const accountSettings = getAccountSettings();
    return accountSettings?.parentAccountId;
  };

  const isMspChildAccount = (): boolean => {
    const accountSettings = getAccountSettings();
    return Boolean(
      accountSettings?.parentAccountId &&
        accountSettings?.totalLicenses === 2147483647
    );
  };

  //AUTHORIZATIONS
  const getAuthorizations = (): IAuthorizations => {
    return _authorizations;
  };

  //TODO: remove this when areas that still use this can just use Roles.ts
  const getRole = (key: string | number): object => {
    return Object.values(applicationRoles).find(function (role) {
      return role.id == key || role.name === key || role.key === key;
    });
  };

  /*
    TODO: this version of 'hasRole' should not be multi-purpose. Keep it pure.
    - hasRole = single role to check
    - hasAnyRole = array of roles to check using 'hasRole'
    */
  const hasRole = (rolesToCheck): boolean => {
    const userRoles = _authorizations?.roles;

    // Bad input, log error
    if (!rolesToCheck) {
      console.error('ActivTrak Error: Undefined roles to check.', rolesToCheck);
      return false;
    }

    // User not logged in, return
    if (!userRoles) {
      return false;
    }

    if (typeof rolesToCheck === 'string') {
      rolesToCheck = [rolesToCheck];
    }

    return _.some(userRoles, (role) => {
      return _.includes(rolesToCheck, role.toLowerCase());
    });
  };

  const hasAnyRole = (roles): boolean => {
    return _.find(roles, (role) => hasRole(role)) !== undefined;
  };

  const hasFeature = (flag: FeatureFlag | BundleFlag): boolean => {
    return hasFlag(flag);
  };

  /*
    TODO: this version of 'hasRoleAccess' should not be multi-purpose. Keep it pure.
    - hasRoleAccess = single role to check
    - hasAnyRoleAccess = array of roleAccesses to check using 'hasRoleAccess'
    */
  const hasRoleAccess = (roleAccessKey): boolean => {
    const userRoleAccesses = _authorizations?.roles;
    if (!roleAccessKey || !userRoleAccesses) {
      return false;
    }

    const search = ' ';
    const replaceWith = '';

    // TODO: Research why we need to create an array of roleAccess
    roleAccessKey =
      typeof roleAccessKey === 'string'
        ? [roleAccessKey.replaceAll(search, replaceWith).toLowerCase()]
        : _.map(roleAccessKey, (access) => {
            return access.toLowerCase().replaceAll(search, replaceWith);
          });

    return _.some(userRoleAccesses, (key) => {
      return _.includes(roleAccessKey, key.toLowerCase());
    });
  };

  const clearAuthorizations = (): void => {
    _authorizations = undefined;
    userServiceFunctions.clearUserToken();
  };

  const getAuthorizationLevelByUrl = (url: string): AuthLevel => {
    if (!url || typeof url !== 'string') {
      console.error(
        'ActivTrak Error: Cannot get authorization level due to invalid inputs.',
        url
      );
      return;
    }

    const route = getRouteByUrl(url);
    const routeName = route?.name;

    return getAuthorizationLevel(routeName);
  };

  const getAuthorizationLevel = (
    routeName: string,
    flag?: BundleFlag | FeatureFlag
  ): AuthLevel => {
    if (
      !routeName ||
      typeof routeName !== 'string' ||
      (flag && typeof flag !== 'string')
    ) {
      console.error(
        'ActivTrak Error: Cannot get authorization level due to invalid inputs.',
        routeName,
        flag
      );
      return;
    }

    if (!(_authorizations?.roles && Array.isArray(_authorizations?.roles))) {
      return;
    }

    const hasFeature = flag ? hasFlag(flag) : true;

    const authLevel = getRouteAccessLevel(
      routeName,
      _authorizations.roles.map((r) => r.toLowerCase() as Role)
    );

    // TODO: Research why and how "preview" is being used as a fallback for "none"
    // TODO: Research why we need the backwards compatibility of converting "none" to null
    if (authLevel === AuthLevel.None) {
      return hasFeature ? null : AuthLevel.Preview;
    }

    return authLevel;
  };

  // TODO: Refactor this into single functions for canEdit, canView, canPreview (if needed)
  const hasAuthorizationLevel = (
    levels: AuthLevel[],
    routeName: string,
    flag?: BundleFlag | FeatureFlag
  ): boolean => {
    if (!levels) {
      console.error(
        'ActivTrak Error: Cannot determine authorization level due to invalid inputs.',
        levels
      );
      return;
    }

    if (typeof levels === 'string') {
      levels = [levels];
    }

    const authorizationLevel = getAuthorizationLevel(routeName, flag);
    let hasLevel = false;

    authorizationLevel &&
      levels.forEach((level) => {
        switch (authorizationLevel) {
          case 'edit':
            hasLevel =
              hasLevel ||
              !!checkArrayIncludes(['edit', 'view', 'preview'], level);
            break;
          case 'view':
            hasLevel =
              hasLevel || !!checkArrayIncludes(['view', 'preview'], level);
            break;
          case 'preview':
            hasLevel = hasLevel || !!checkArrayIncludes(['preview'], level);
            break;
        }
      });

    return hasLevel;
  };

  // Verify user should be able to access route
  // TODO: Migrate to UserPermissionStore once route restrictions are moved to the new system
  const hasRoute = (url: string): boolean => {
    // If roles are missing, user does not have route
    if (typeof url !== 'string') {
      return false;
    }

    if (url === '/support') {
      return hasRole(['supportportal']) || userServiceFunctions.getIsImposter();
    }

    const route = getRouteByUrl(url);

    if (route?.access?.allowAnonymous) {
      return true;
    }

    const roles = _authorizations?.roles ?? [];
    const routeName = route?.name;
    const routeLevel = getRouteAccessLevel(routeName, roles as Role[]);

    // Get route restrictions based on screen size and build
    const routeRestricted = _isRouteRestricted(
      url,
      Boolean(getParentAccount())
    );

    // User has route if they have any levels and the route is not restricted
    // Override for signup page.
    return routeLevel !== AuthLevel.None && !routeRestricted;
  };

  const hasRouteByUrl = (url: string): boolean => {
    if (!url || typeof url !== 'string') {
      console.error(
        'ActivTrak Error: Cannot determine route access by URL due to invalid inputs.',
        url
      );
      return false;
    }

    // TODO: Bypass for CC pages, remove once CC is refactored
    if (url.includes('/cc')) return true;

    return hasRoute(url);
  };

  const hasRouteByName = (routeName): boolean => {
    if (!routeName || typeof routeName !== 'string') {
      console.error(
        'ActivTrak Error: Cannot determine route access by name due to invalid inputs.',
        routeName
      );
      return false;
    }

    // TODO: Bypass for CC pages, remove once CC is refactored
    if (routeName.startsWith('cc')) return true;

    const url = getRouteByName(routeName)?.stateDefinition?.url;
    if (!url) {
      console.error(
        `ActivTrak Error: Cannot find route URL from given route name.\nRoute Name: ${routeName}`
      );
      return false;
    }

    return hasRoute(url);
  };

  const redirectToAuthorizedHome = (
    appState,
    toStateName?: string
  ): boolean => {
    const unrestrictedLanding = 'app.account.profile';
    const supportLanding = 'supportportal';
    const alarmsLanding = 'app.alarms.settings';
    const activationLanding = 'app.agentactivation';
    const currentName = toStateName ?? appState.current.name;
    const toStateRouteAccess = getRouteAccess(currentName);
    const redirectTo = toStateRouteAccess?.redirectTo;
    const accountSettings = getAccountSettings();

    let stateName = appState.current.name;

    if (redirectTo && hasRouteByName(redirectTo)) {
      stateName = redirectTo;
    } else if (hasRole([Role.SuperAdmin])) {
      stateName = unrestrictedLanding;
    } else if (hasRole([Role.SupportPortal]) && stateName !== 'supportportal') {
      stateName = supportLanding; // Land on Support Portal for Support Portal support users
    } else if (
      hasRole([
        Role.SupportBasic,
        Role.SupportIntermediate,
        Role.SupportAdvanced
      ])
    ) {
      stateName = alarmsLanding; // Land on alarm configuration for non-Support Portal support users (original behavior)
    } else if (
      accountSettings.landingPage &&
      hasRouteByName(accountSettings.landingPage)
    ) {
      stateName = accountSettings.landingPage; // Land on configured landingPage if setting exists and route allowed
    } else {
      // Determine landing page based on landing page order and first accessible page
      // TODO: Order determined by ENUM order, it should be determined by navigation order
      // We should be using the navigation store to pull in a list of pages and set the landing page to the first on the list
      // The navigation store already checks for access and has the correct order
      const landingAccess = Object.values(RoleAccessKeys).find((key) =>
        hasRoleAccess(key)
      );
      const landingState = Object.values(getRoutes()).find(
        (route) => route.access?.roleAccessKey === landingAccess
      )?.name;
      stateName = landingState ?? unrestrictedLanding;

      // Temp authorization fix for MSP accounts being redirected to Insights promo pages but do not have access
      // Access is controlled via the navigation system that is not part of the authroization systems in use here.
      // TODO: Consolidate authorization systems and remove this temporary workaround.
      if (
        stateName.includes('insights') &&
        userServiceFunctions.getIsMSPAccount()
      ) {
        console.warn(
          'ActivTrak Warning: Redirecting to unrestricted landing due to restricted landing page selected.'
        );
        stateName = unrestrictedLanding;
      }
    }

    // Redirect to agent activation if you have access and no agents reporting
    if (
      hasFeature(FeatureFlag.ShowActivation) &&
      hasRouteByName(activationLanding)
    ) {
      stateName = activationLanding;
    }

    // Verify access to found page or redirect to profile page (unrestricted)
    if (
      !hasRouteByName(stateName) ||
      getRouteByName(stateName)?.stateDefinition?.abstract === true
    ) {
      // If user is on a dashboard page, redirect to the first available dashboard

      if (stateName.includes('dashboard')) {
        const availableDashboard = DashboardListNg.find((dashboardRoute) =>
          hasRouteByName(dashboardRoute)
        );

        stateName = availableDashboard ?? unrestrictedLanding;
      } else {
        stateName = unrestrictedLanding;
      }
    }

    const shouldRedirect = stateName !== appState.current.name;

    // Only redirect if user is not already on the page
    // TODO: Fix: navigation should only be triggered from the UI layer.
    // Consider returning stateName and navigating from an outer UI layer.
    if (shouldRedirect) appState.go(stateName);

    return shouldRedirect;
  };

  // TODO: Review and refactor role check functions
  /**
   * Returns true if the user has any support role (including SuperAdmin)
   *
   * EXCLUDES impersonation
   */
  const isSupportOrSuperAdmin = (): boolean => {
    return hasAnyRole([
      Role.SupportBasic,
      Role.SupportIntermediate,
      Role.SupportAdvanced,
      Role.SuperAdmin
    ]);
  };

  /**
   * Returns true if the user has any support role (including SuperAdmin) or if the user is impersonating
   *
   * INCLUDES impersonation
   */
  const isAnySupportUser = (): boolean => {
    return (
      userServiceFunctions.getIsImposter() ||
      hasAnyRole([
        Role.SupportBasic,
        Role.SupportIntermediate,
        Role.SupportAdvanced,
        Role.SuperAdmin
      ])
    );
  };

  return {
    setAuthorizations,
    clearAuthorizations,
    hasFeature,
    getRole,
    hasRole,
    hasAnyRole,
    hasRoleAccess,
    hasRouteByUrl,
    getUserType,
    getParentAccount,
    isMspChildAccount,
    getAuthorizationLevel,
    getAuthorizationLevelByUrl,
    hasAuthorizationLevel,
    hasRouteByName,
    getAuthorizations,
    redirectToAuthorizedHome,
    isSupportOrSuperAdmin,
    isAnySupportUser
  };
};
const authorizationService: IAuthorization =
  inMemoryStore.get('authorizationService') || getAuthorizationService();
inMemoryStore.set('authorizationService', authorizationService);

export default authorizationService;

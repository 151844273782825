import { createStore } from 'zustand';
import { IApiDomainStore } from './apiDomainStore.models';
import { activTrakConfig, fetchUrls } from './apiDomainStore.utils';
import { API_DOMAIN_STORE_DEFAULT } from './apiDomainStore.constants';

export const apiDomainStore = createStore<IApiDomainStore>(
  () => API_DOMAIN_STORE_DEFAULT
);

/**
 * Fetches the account's region based API Domains.
 */
export const fetchApiDomains = async (): Promise<IApiDomainStore> => {
  if (!activTrakConfig.disableRegionRedirect) {
    try {
      const apiDomains = await fetchUrls();
      apiDomainStore.setState((prevState) => ({ ...prevState, ...apiDomains }));
    } catch (error) {
      if (error?.status !== 401) {
        console.error('ActivTrak Error: Error fetching account urls', error);
      }

      throw error;
    }
  }

  return apiDomainStore.getState();
};

/**
 * Returns the set account's region based API Domains.
 */
export const getApiDomainStore = (): IApiDomainStore =>
  apiDomainStore.getState();

/**
 * Resets the account's region based API Domains to the default state.
 */
export const resetApiDomainStore = (): void =>
  apiDomainStore.setState(API_DOMAIN_STORE_DEFAULT);

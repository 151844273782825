import { getApiDomainStore } from '../../_reactivtrak/src/common/stores/apiDomainStore/apiDomainStore';
import { activTrakConfig } from '../../_reactivtrak/src/common/stores/apiDomainStore/apiDomainStore.utils';

angular.module('config', []).service('envConfig', envConfig);

/** @deprecated
 * Legacy envConfig used by AngularJS files
 * All other files should use the ApiDomainStore instead
 */
function envConfig() {
    this.adminApiUrl = function () {
        return getApiDomainStore().adminApi;
    };

    this.apiUrl = function () {
        return getApiDomainStore().frontendApi;
    };

    this.reportingServiceUrl = function () {
        return getApiDomainStore().reportingService;
    };

    this.identityServiceUrl = function () {
        return getApiDomainStore().identityService;
    };

    this.accountsApiUrl = function () {
        return getApiDomainStore().accountsApi;
    };

    this.websocketUrl = function () {
        return getApiDomainStore().websocket;
    };

    this.websocketHttpsUrl = function () {
        return getApiDomainStore().websocketHttps;
    };
}

angular.module('config').service('loginServiceUrl', loginServiceUrl);

/** @deprecated
 * Legacy envConfig used by AngularJS login process
 * All other files should use the ApiDomainStore instead
 */
function loginServiceUrl() {
    this.apiUrl = function () {
        return getApiDomainStore().frontendApi;
    };

    this.authorizationServiceUrl = function () {
        return getApiDomainStore().authorizationService;
    };

    this.ssoAuthorizationUrl = function () {
        return getApiDomainStore().ssoAuthorization;
    };
}

angular.module('config').service('envService', envService);

const environment_name = import.meta.env.VITE_NAME;
/** @deprecated
 * Legacy envConfig used by AngularJS files
 * All other files should use the ApiDomainStore instead
 */
function envService() {
    this.isProduction = function () {
        return environment_name === 'prod';
    };

    this.get = function () {
        return environment_name;
    };

    this.getMarketoConfig = function () {
        return activTrakConfig.marketo;
    };

    this.ssoAuthorizationUrl = function () {
        return getApiDomainStore().ssoAuthorization;
    };

    this.lookerUrl = function () {
        return activTrakConfig.lookerUrl;
    };

    this.signUpAppUrl = function () {
        return activTrakConfig.signUpAppUrl;
    };
}

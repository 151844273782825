import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useNotifications } from '../../common/services/Notifications';
import { SupportPortalService, useSupportPortalState } from '../hooks';
import { DetailsPanel, LoadingModal } from './';
import { displayMessage, consoleMessage } from '../constants';
import { structureAccountDetails } from '../utils';
import { isValidFullDetailsForModal } from '../utils/checkIfValidData.utils';
import { IFullyMappedAccountDetails } from '../models/IMappedDetails';
import { IModalHeader, ISupportControlValues } from '../models/IRandomTypes';
import { IAccountIdPayloadDto } from '../models/IPayloadDto';
import { IAccountCredentials } from '../models/IAccountData';

interface IDetailsModalProps {
  open: boolean;
  onClose: () => void;
  accountCredentials: IAccountCredentials;
}

export const DetailsModal = (props: IDetailsModalProps) => {
  const { open, onClose, accountCredentials } = props;
  const { email, id, tokenData } = accountCredentials;

  const [isValidData, setIsValidData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [accountDetails, setAccountDetails] =
    useState<IFullyMappedAccountDetails>(null);
  const [modalHeader, setModalHeader] = useState<IModalHeader>(null);
  const [supportControlValues, setSupportControlValues] =
    useState<ISupportControlValues>(null);

  const notificationService = useNotifications();
  const service = SupportPortalService();
  const { fetchAllDetailsForModal } = useSupportPortalState({
    service
  });

  useEffect(() => {
    if (open) {
      handleFetchDetailsForModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleFetchDetailsForModal = async () => {
    setIsLoading(true);

    const userToken: string = tokenData.token;
    const payload: IAccountIdPayloadDto = { accountId: id };

    try {
      const [accountSettings, accountSnapshotData, storageData] =
        await fetchAllDetailsForModal(userToken, payload);

      if (
        !isValidFullDetailsForModal(
          accountSettings,
          accountSnapshotData,
          storageData
        )
      ) {
        setIsValidData(false);
        onClose();

        notificationService.error(displayMessage.modalError);
        console.error(consoleMessage.accountFetchValidationError.modalError);

        return;
      }

      const structuredAccountDetails: IFullyMappedAccountDetails =
        structureAccountDetails(
          accountSettings,
          accountSnapshotData,
          storageData
        );

      const newModalHeader = {
        accountId: accountSettings.account,
        companyName: accountSettings.companyName || 'Company N/A',
        userCount: accountSettings.usedLicenses,
        totalCount: accountSettings.totalLicenses
      };

      setModalHeader(newModalHeader);
      setSupportControlValues({
        dataCollection: accountSettings?.dataCollection,
        verified: accountSettings?.isVerified
      });

      setAccountDetails(structuredAccountDetails);
      setIsValidData(true);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      onClose();

      notificationService.error(displayMessage.modalError);
      console.error(error);
    }
  };

  const canRenderAccountDetails: boolean = !isLoading && isValidData;

  return (
    <Dialog maxWidth="xl" open={open} onClose={onClose}>
      {canRenderAccountDetails && (
        <>
          <DialogTitle>
            {' '}
            <Box>
              <Typography variant="h5" align="left">
                {modalHeader.accountId}: {modalHeader.companyName}
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Typography fontWeight={500}>{email}</Typography>
                <Typography fontWeight={500}>
                  {modalHeader.userCount}/{modalHeader.totalCount}
                </Typography>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent dividers sx={{ paddingBottom: 0 }}>
            <DetailsPanel
              accountDetails={accountDetails}
              supportControlValues={supportControlValues}
              accountCredentials={accountCredentials}
              setDetailsModalClose={onClose}
            />
          </DialogContent>
          <DialogActions sx={{ paddingTop: '15px' }}>
            <Button onClick={onClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </>
      )}
      {isLoading && <LoadingModal />}
    </Dialog>
  );
};

import { ColDef, IDatasource } from 'ag-grid-community';
import {
  getScheduleAdherenceColumns,
  setScheduleAdherenceColumns
} from '../stores/scheduleAdherenceColumns.store';
import { ReportViewTypes } from '../../../common/components/ReportFilters/constants/reportViewToggle.constants';
import {
  handleAllCheckbox,
  mapColDefToColumnState,
  mapColumnStateToGridColumnFilter
} from '../../../common/components/ReportFilters/utils/gridColumnsFilter.utils';
import {
  IGridColumnFilter,
  IReportColumnLabel
} from '../../../common/components/ReportFilters/models/IGridColumnFilter.models';
import { createBaseReportFilterParams } from '../../../common/components/ReportFilters/utils/reportFilter.utils';
import {
  getScheduledTimeline,
  setScheduledTimeline
} from '../stores/scheduledTimeline.store';
import { convertObjectToQs } from '../../../common/utils/parse/parse';
import { fetchReportingData } from '../../../common/helpers';
import { IScheduleAdherenceUsersDto } from '../models/ScheduleAdherence.models';
import { setExportParamsStore } from '../../../common/stores/exportsStore/exportParamsStore';
import { IExportReportParams } from '../../../common/stores/exportsStore/exportStore.models';
import { ReportType } from '../../../common/enums/ReportType';

//use the sort order from the ColumnState
export const mapSAColumnsToColumnState = (
  newColumns: ColDef[],
  view: ReportViewTypes
): void => {
  //get current saved state directly from store
  const currentColumnState = getScheduleAdherenceColumns()[view];

    const newColumnsStates = currentColumnState.map((currentCol) => {
      if (!currentCol) return;

      const currentDefaultColId = currentCol.colId;

      const newColumnState = newColumns.find(
        (newCol) => newCol.colId === currentDefaultColId
      );
      if (!newColumnState) return;

      return mapColDefToColumnState(newColumnState);
    });

  //update state directly to store
  setScheduleAdherenceColumns({ [view]: newColumnsStates });
};

//use the sort order from the Column Selector labels
export const mapSAColumnFiltersToColumnLabels = (
  view: ReportViewTypes,
  labels: IReportColumnLabel[]
): IGridColumnFilter[] => {
  const sortedDefaultColumns = labels?.sort((a, b) =>
    a.label.localeCompare(b.label)
  );
  const currentColumnState = getScheduleAdherenceColumns()[view];

  const cols = sortedDefaultColumns?.map((col) => {
    return mapColumnStateToGridColumnFilter(col, currentColumnState);
  });

  return handleAllCheckbox(cols);
};

export const getDataSource = (gridRef, notificationService): IDatasource => {
  const pageSize = 150;

  const dataSource: IDatasource = {
    rowCount: undefined,
    getRows: async (params) => {
      gridRef.current.api.hideOverlay();
      const page = Math.ceil(params.endRow / pageSize);

      setTimeout(async () => {
        const { to, from, userId, computerId, groupId, userMode } =
          createBaseReportFilterParams();

        // TODO: Remove this check when computer based filtering is supported
        if (computerId || userMode === 'computers') {
          notificationService.warning(
            'Computer based filtering is not currently supported for Schedule Adherence Report Beta. Please update your filter to a user or user group.'
          );
          gridRef.current.api.setGridOption('loading', false);
          params.failCallback();
          gridRef.current.api.showNoRowsOverlay();
          return;
        }

        const shiftTimes = getScheduledTimeline();
        const reportFiltersStr = convertObjectToQs({
          to,
          from,
          userId,
          computerId,
          groupId,
          pageSize,
          page,
          shiftStart: shiftTimes.shiftStart,
          shiftEnd: shiftTimes.shiftEnd
        });

        gridRef.current.api.setGridOption('loading', true);

        try {
          const response = await fetchReportingData<IScheduleAdherenceUsersDto>(
            {
              path: '/reports/v1/scheduleadherence?' + reportFiltersStr
            }
          );
          const data = response?.data;
          const total = response?.total;

          const exportParams = {
            hasDataForTable: total > 0
          };

          setExportParamsStore(exportParams);

          if (data.length === 0) {
            gridRef.current.api.setGridOption('loading', false);
            gridRef.current.api.showNoRowsOverlay();
          }
          params.successCallback(data, total);
        } catch (error) {
          params.failCallback();
          notificationService.error('Unable to load Schedule Adherence data');
          console.error(
            'ActivTrak Error: Error fetching Schedule Adherence data: ',
            error
          );
        } finally {
          gridRef.current.api.setGridOption('loading', false);
        }
      }, 0);
    }
  };
  return dataSource;
};

export const createExportPayload = (): IExportReportParams => {
  const { from, groupId, to, userType, userId, computerId } =
    createBaseReportFilterParams();

  const shiftTimes = getScheduledTimeline();

  return {
    type: ReportType.ScheduleAdherence,
    startDate: from,
    endDate: to,
    userType,
    userId: groupId ?? userId ?? computerId,
    shiftStart: shiftTimes?.shiftStart,
    shiftEnd: shiftTimes?.shiftEnd
  };
};

export const handleStartDate = (value: string): void => {
  setScheduledTimeline({
    shiftStart: value
  });
};

export const handleEndDate = (value: string): void => {
  setScheduledTimeline({
    shiftEnd: value
  });
};
